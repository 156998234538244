import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { MfaType } from './mfa-interfaces';
import {
  Footer,
  NextButton,
  SelectOption,
  HeadTitle,
  SecondaryTitle,
  SelectOptions,
  SkipButton,
  Text,
  Title,
  Content,
} from './mfa-modal-styles';
import Icon from 'modules/common/components/ui/icon';

import { ReactComponent as Qr } from 'assets/streamline-light/phones-mobile-devices/qr-code/qr-code-scan.svg';
import { ReactComponent as ArrowRight } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-right.svg';
import { ReactComponent as Sms } from 'assets/streamline-light/messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-typing.svg';
import MfaContext from './mfa-context';
import { useIsMobileSize } from 'modules/common/hooks/useIsMobileSize';

export const MfaSelect = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();

  const [selectedType, setSelectedType] = useState<MfaType>();

  const { setType } = useContext(MfaContext);

  const handleSelectType = useCallback(
    (e: MfaType) => {
      setSelectedType(e);
    },
    [setSelectedType]
  );

  const handleNext = useCallback(
    (e) => {
      if (selectedType) {
        setType?.(selectedType);
      }
    },
    [selectedType, setType]
  );

  return (
    <Content>
      <Title content={t('auth.mfa.select.title')} />
      <Text content={t('auth.mfa.select.text')} />
      <SelectOptions>
        <SelectOption
          isSelected={selectedType === 'app'}
          onClick={() => handleSelectType('app')}
        >
          <HeadTitle>
            <Icon icon={Qr} width={24} height={24} color={themeContext.blue} />
            <SecondaryTitle content={t('auth.mfa.select.app.title')} />
            <Icon
              icon={ArrowRight}
              width={isMobileSize ? 16 : 24}
              height={isMobileSize ? 16 : 24}
              color={themeContext.blue}
            />
          </HeadTitle>
          <Text content={t('auth.mfa.select.app.text')} />
        </SelectOption>

        <SelectOption
          isSelected={selectedType === 'sms'}
          onClick={() => handleSelectType('sms')}
        >
          <HeadTitle>
            <Icon icon={Sms} width={24} height={24} color={themeContext.blue} />
            <SecondaryTitle content={t('auth.mfa.select.sms.title')} />
            <Icon
              icon={ArrowRight}
              width={isMobileSize ? 16 : 24}
              height={isMobileSize ? 16 : 24}
              color={themeContext.blue}
            />
          </HeadTitle>
          <Text content={t('auth.mfa.select.sms.text')} />
        </SelectOption>
      </SelectOptions>

      {selectedType && (
        <Footer>
          <NextButton label={t(`auth.mfa.next`)} onClick={handleNext} />
        </Footer>
      )}
    </Content>
  );
};
