import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  AuthChallengeFieldsFragment,
  CreatePropertyInput,
  LoginResponse,
  UserSignInInput,
  UserType,
  useSignInMutation,
} from '../../../../../generated';
import { client } from '../../../../../services/graphql/graphql';
import {
  InputOutsideTopLabel,
  InputPassOutsideTopLabel,
} from '../../../../common/components/form';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { useCreateTemporaryProperty } from '../../../../forms/form-property-valuation-wizard/hooks/useCreateTemporaryProperty';
import { IValuationForm } from '../../../../forms/form-property-valuation-wizard/interface';
import {
  resetValuationWizard,
  toggleRegisterForm,
} from '../../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { resetSPC } from '../../../../forms/form-search-profile/redux/searchProfileSlice';
import { parseValuationInput } from '../../../../forms/utils/parse-valuation-input';
import { ILoginForm, IRegisterForm } from '../../../interfaces';
import {
  login,
  setAnonymousUser,
  setResendVerificationAccountEmail,
  setUserInput,
  setUsername,
  setUserState,
  toggleIsLoginModalOpen,
  toggleIsMfaAfterLoginModalOpen,
  toggleIsRegisterModalOpen,
  toggleIsResendVerificationAccountModalOpen,
} from '../../../redux/authSlice';
import { useLngHistoryPush } from '../../../../localization/lng-history-push';
import { siteMap } from '../../../../../routes/site-map';
import { MAX_MOBILE_SIZE } from 'modules/common/constants';
import MfaAfterLoginContext from '../mfa-modal/mfa-after-login-context';
import { MfaAfterLoginPage } from '../mfa-modal/mfa-interfaces';

const Title = styled.h2`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #00305e;
  margin-bottom: 24px;
`;

const Lighter = styled.span`
  font-weight: 300;
  opacity: 0.95;
  -webkit-font-smoothing: antialiased;
`;

const FormWrapper = styled.div`
  flex: 1;

  .error-message {
    width: 100%;
    margin-bottom: 16px;
    margin-top: 16px;
    color: #c5363b;
  }

  label {
    color: #00305e;
    font-size: 12px;
    line-height: 125%;
  }
`;

const InputBoxWithMargin = styled.div`
  margin-bottom: 24px;
`;

const ButtonsBox = styled.div`
  margin-top: 11px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TextButton = styled.a<{ disabled: boolean }>`
  font-family: Roboto, sans-serif;
  color: #4e73f4;
  letter-spacing: 0.2px;
  font-size: 12px;
  font-weight: 400;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  ${(props) =>
    props.disabled &&
    `
    &, &:hover {
      opacity: 0.8;
      cursor: not-allowed;
    }
  `}
`;

const CTAButton = styled.button`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  min-width: 202px;
  max-width: 100%;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  color: white;
  padding: 10px 14px;
  outline: none;
  background: #4e73f4;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  &:disabled,
  &:disabled:hover {
    opacity: 0.5;
    cursor: progress;
  }

  @media screen and (max-width: ${MAX_MOBILE_SIZE}px) {
    width: calc(100% - 32px);
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const SecondaryBtn = styled.button`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #ffffff;

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  background: transparent;
  height: 35px;
  width: 100%;
  margin-top: auto;
  cursor: pointer;
`;

const TextError = styled.p`
  font: 400 14px/140% 'Roboto', sans-serif;
  color: #c5363b;
  margin-top: 16px;
`;

interface IProps {
  onForgotPassword: () => void;
  setIsAuthFormInProgress: (newValue: boolean) => void;
  isAuthFormInProgress: boolean;
  close: () => void;
}

const LoginForm = ({
  onForgotPassword,
  setIsAuthFormInProgress,
  isAuthFormInProgress,
  close,
}: IProps) => {
  const methods = useForm<ILoginForm>({
    mode: 'onTouched',
  });
  const {
    location: { pathname },
  } = useHistory();
  const lngHPush = useLngHistoryPush();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { slug } = useParams<{ slug?: string }>();
  const [inputPassword, setInputPassword] = useState('');

  const userSignInInputRef = useRef<UserSignInInput>();

  const [validationError, setValidationError] = useState('');

  const userInput = useAppSelector((state) => state.valuationWizard.userInput);
  const selectedAddress = useAppSelector(
    (state) => state.valuationWizard.selectedAddress
  );
  const regionalBrokerId = useAppSelector(
    (state) => state.valuationWizard.regionalBrokerId
  );
  const [signIn, { data: signInData, error, isLoading }] = useSignInMutation();
  const { createTemporaryProperty } = useCreateTemporaryProperty();

  const onSubmit: SubmitHandler<ILoginForm> = useCallback(
    async (formData) => {
      const { userAuthenticationKey, password } = formData;

      setValidationError('');
      setInputPassword(password);

      try {
        setIsAuthFormInProgress(true);
        const input = {
          userAuthenticationKey,
          password,
          requestAccessToken: true,
          regionalBrokerId,
          slug,
        };
        await signIn({
          input,
        });

        userSignInInputRef.current = { ...input };
      } catch (error_) {
        console.error(error_);
      } finally {
        setIsAuthFormInProgress(false);
      }
    },
    [regionalBrokerId, setIsAuthFormInProgress, signIn, slug]
  );

  useEffect(() => {
    if (signInData) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const loginResponse = signInData.signIn as any;

      if (Boolean(loginResponse) && !loginResponse.challengeName) {
        const tLoginResponse = loginResponse as LoginResponse;
        if (tLoginResponse?.jwt?.token) {
          client.setHeader(
            'Authorization',
            `Bearer ${tLoginResponse?.jwt?.token ?? ''}`
          );
          batch(() => {
            dispatch(login(tLoginResponse));
            dispatch(setUserState(tLoginResponse.user.userState));
            dispatch(setAnonymousUser(false));
            dispatch(resetValuationWizard());
            dispatch(resetSPC());
          });
          const user = tLoginResponse?.user;
          const isOverviewPage = pathname.split('/')?.[2]?.length === 0;
          if (isOverviewPage) {
            if (user?.userType?.includes(UserType.Seller)) {
              lngHPush(siteMap.SellerLandingPage.path);
            } else if (user?.userType?.includes(UserType.Owner)) {
              lngHPush(siteMap.OwnerLandingPage.path);
            } else if (user?.userType?.includes(UserType.Buyer)) {
              lngHPush(siteMap.BuyerLandingPage.path);
            } else if (user?.userType?.includes(UserType.Financier)) {
              lngHPush(siteMap.FinancingLandingPage.path);
            } else {
              lngHPush(siteMap.SellerLandingPage.path);
            }
          }
        } else if (tLoginResponse.user && !tLoginResponse.jwt) {
          batch(() => {
            dispatch(toggleRegisterForm(true));
            dispatch(toggleIsRegisterModalOpen(true));
            dispatch(setUserState(tLoginResponse.user.userState));
            dispatch(
              setUserInput({
                name: tLoginResponse.user.name,
                surname: tLoginResponse.user.surname,
                phonePrefix: '',
                phone: tLoginResponse.user.phone,
                email: tLoginResponse.user.email,
                password: inputPassword,
                confirmPassword: inputPassword,
                termsAndConditions: tLoginResponse.user.termsAndConditions,
              })
            );
            dispatch(setUsername(tLoginResponse.user.username));
          });
        }
      } else {
        if (!userSignInInputRef.current) return;

        const { challengeName, challengeParameters, session } =
          loginResponse as AuthChallengeFieldsFragment;

        switch (challengeName) {
          case 'SOFTWARE_TOKEN_MFA':
            dispatch(
              toggleIsMfaAfterLoginModalOpen({
                isMfaAfterLoginModalOpen: true,
                mfaChallengeMeta: {
                  srpId: challengeParameters.USER_ID_FOR_SRP,
                  session,
                  userSignInInput: userSignInInputRef.current,
                },
                mfaChallengeModal: 'app',
              })
            );
            break;
          case 'SMS_MFA':
            dispatch(
              toggleIsMfaAfterLoginModalOpen({
                isMfaAfterLoginModalOpen: true,
                mfaChallengeMeta: {
                  srpId: challengeParameters.USER_ID_FOR_SRP,
                  session,
                  userSignInInput: userSignInInputRef.current,
                },
                mfaChallengeModal: 'sms',
              })
            );
            break;
          default:
            break;
        }
      }
      dispatch(toggleIsLoginModalOpen(false));
      close();
    }
  }, [dispatch, signInData, inputPassword]);

  // TODO: move this logic to another component
  useEffect(() => {
    if (signInData && userInput && selectedAddress) {
      const loginResponse = signInData.signIn as LoginResponse;
      try {
        const valuationInput = parseValuationInput(
          userInput as IValuationForm,
          selectedAddress
        ) as CreatePropertyInput;

        createTemporaryProperty({
          valuationInput,
          userId: loginResponse.user._id,
        }).catch((error_) => console.error(error_));
      } catch (temporaryError) {
        console.error(temporaryError);
      }
    }
  }, [createTemporaryProperty, selectedAddress, signInData, userInput]);

  useEffect(() => {
    if (error?.message?.includes('error.user.account.not.confirmed')) {
      const values = methods.getValues();
      dispatch(toggleIsResendVerificationAccountModalOpen(true));
      dispatch(setResendVerificationAccountEmail(values.userAuthenticationKey));
    }
  }, [error?.message]);

  return (
    <>
      <Title>{t('login-form.title')}</Title>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormWrapper>
            <InputBoxWithMargin>
              <InputOutsideTopLabel
                id="register.form.input.phone"
                name="userAuthenticationKey"
                type="text"
                label="email-phone.label"
                placeholder={'email-phone.placeholder'}
                isDisabled={isAuthFormInProgress}
                rules={{
                  required: 'register.input.error.required',
                }}
                isRedesign
              />
            </InputBoxWithMargin>

            <InputPassOutsideTopLabel
              id="register.form.input.password"
              name="password"
              type="password"
              label="register.form.input.label.password"
              placeholder="register.form.input.placeholder.password"
              rules={{ required: 'register.input.error.required' }}
              isDisabled={isAuthFormInProgress}
              passwordMasterValue={methods.watch('password')}
              isRedesign
            />
            {validationError && <TextError>{t(validationError)}</TextError>}
            {!validationError && error?.message && (
              <TextError>{t(error?.message?.split(':')[0])}</TextError>
            )}
            <ButtonsBox>
              <TextButton
                disabled={isAuthFormInProgress}
                onClick={isAuthFormInProgress ? () => {} : onForgotPassword}
              >
                {t('login-form.forgot-password')}
              </TextButton>
              <CTAButton disabled={isAuthFormInProgress} type={'submit'}>
                {t('button.login-now')}
              </CTAButton>
            </ButtonsBox>
          </FormWrapper>
        </form>
        {/* <SecondaryBtn>{t('login-form.create-account')}</SecondaryBtn> */}
      </FormProvider>
    </>
  );
};

export default LoginForm;
