export function copyToClipboard(val: string) {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = val;
  document.body.append(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  selBox.remove();
}

export function formatSecondsToMMSS(totalSeconds: number): string {
  const minutes: number = Math.floor(totalSeconds / 60);
  const seconds: number = totalSeconds % 60;

  const formattedMinutes: string = String(minutes).padStart(2, '0');
  const formattedSeconds: string = String(seconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}
