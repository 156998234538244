import { FC, useCallback, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';

import { formModalStyles } from '../../../constants';
import { TArrowPosition } from '../../../hooks/useArrowImage';
import { IValuationForm } from '../../../form-property-valuation-wizard-v2/interface';
import { IFinancingForm } from '../../../form-financing/interface';
import { Modal } from '../../../../common/components/modal';
import { Container } from './container';

import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { ModalOnClose } from '../modal-on-close/modal-on-close';
import { toggleIsWidgetOnCloseModalOpen } from '../../../../auth/redux/authSlice';
import { UserState } from 'generated';

interface IProps {
  onCloseModalTitle: string;
  onCloseModalText: string;

  isOpen: boolean;
  isAuthFlow: boolean;
  isConfirmEmailSent: boolean;
  isRegisterFormOpen: boolean;
  isLoginFormOpen: boolean;
  isArrowUp?: boolean;
  activeStep: number;

  formMethods: UseFormReturn<IFinancingForm | IValuationForm>;
  arrowPosition: TArrowPosition;
  onBackClick: () => void;
  onCloseClick: () => void;
}

const ModalContainer: FC<IProps> = ({
  onCloseModalTitle,
  onCloseModalText,
  activeStep,
  isOpen,
  isRegisterFormOpen,
  isConfirmEmailSent,
  isLoginFormOpen,
  isAuthFlow,
  isArrowUp,
  formMethods,
  arrowPosition,
  onBackClick,
  onCloseClick,
  children,
}) => {
  const dispatch = useAppDispatch();
  const { reset, ...methods } = formMethods;
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const userState = useAppSelector((state) => state.auth.userState);
  const isLastStep = activeStep === 2 && userState === UserState.VerifyEmail;

  const onOpenModalOnClose = () => {
    if (!isConfirmEmailSent) {
      dispatch(toggleIsWidgetOnCloseModalOpen(true));
    } else {
      onCloseClick();
    }
  };

  const onFullScreenChange = useCallback(() => {
    if (!document.fullscreenElement && isIframe && isOpen) {
      onCloseClick();
    }
  }, [isIframe, onCloseClick, isOpen]);

  useEffect(() => {
    window.addEventListener('fullscreenchange', onFullScreenChange);

    return () => {
      window.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, [onFullScreenChange]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onOpenModalOnClose}
      wrapperStyles={formModalStyles}
    >
      <ModalOnClose
        title={onCloseModalTitle}
        text={onCloseModalText}
        onSubmit={onCloseClick}
      />
      <FormProvider reset={reset} {...methods}>
        <Container
          activeStep={activeStep}
          isRegisterFormOpen={isRegisterFormOpen}
          isLastStep={isLastStep}
          isLoginFormOpen={isLoginFormOpen}
          isAuthFlow={isAuthFlow}
          isArrowUp={isArrowUp}
          arrowPosition={arrowPosition}
          onClose={onOpenModalOnClose}
          onBackClick={onBackClick}
        >
          {children}
        </Container>
      </FormProvider>
    </Modal>
  );
};

export { ModalContainer };
