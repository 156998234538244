import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CrimeManThief1 } from 'assets/streamline-light/avatars/natural-men-police-army-crime/crime-man-thief-1.svg';
import { ReactComponent as DesignDrawingBoardEducation } from 'assets/streamline-light/design/design-process/design-drawing-board-education.svg';
import { ReactComponent as BabyHoldHands } from 'assets/streamline-light/family-babies-kids/babies/baby-hold-hands.svg';
import { ReactComponent as FamilyHome } from 'assets/streamline-light/family-babies-kids/family/family-home.svg';
import { ReactComponent as CakeBirthday } from 'assets/streamline-light/food-drinks/cakes-candies/cake-birthday.svg';
import { ReactComponent as CoupleAlbumBook } from 'assets/streamline-light/romance/couples/couple-album-book.svg';
import { ReactComponent as BaggagePlane } from 'assets/streamline-light/travel/baggage/baggage-plane.svg';
import { ReactComponent as UserMultipleHalfFemaleGroup } from 'assets/streamline-light/users/geomertic-close-up-multiple-users/user-multiple-half-female-group.svg';
import { ReactComponent as SingleNeutralBriefcase } from 'assets/streamline-light/users/geometric-full-body-single-user-neutral/single-neutral-briefcase.svg';
import { ReactComponent as WorkflowCoachingChart1 } from 'assets/streamline-light/work-office-companies/coaching/workflow-coaching-chart-1.svg';

import { SocioEconomicsEntity } from '../../../../generated';
import Select from '../form/select/select';
import { useIsMobileSize } from '../../hooks/useIsMobileSize';
import { SocialEconomicsDataSourceCard } from '../../../property/components/card/social-economics-data-source-card';
import { CardWithTitle } from '../../../property/components/card/social-economics/card-with-title';
import { BarChart } from '../../../property/components/chart/bar-chart';
import { LineChart } from '../../../property/components/chart/line-chart';
import {
  ColumnWrapper,
  ChartWrapper,
  ChartContainer,
  Column,
  SelectorLeft,
  SelectorRight,
  SelectTitle,
  Selector,
} from './social-economics-styles';
import { AdvancedChartsInterface, SimpleChartsInterface } from './interface';
import {
  AdvancedChartDataType,
  initialAdvancedChartDataState,
  initialSimpleChartDataState,
  SimpleChartDataTypes,
} from './constants';
import { parseAdvancedChartData, parseSimpleChartData } from './utils';

interface IProps {
  socioEconomicsData?: SocioEconomicsEntity[] | null;
  isFullWidth?: boolean;
  isOnBuyerPortal?: boolean;
}

const SocialEconomics: FC<IProps> = ({
  socioEconomicsData,
  isFullWidth,
  isOnBuyerPortal,
}) => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const [simpleChartData, setSimpleChartData] = useState<SimpleChartsInterface>(
    initialSimpleChartDataState
  );
  const [advancedChartData, setAdvancedChartData] =
    useState<AdvancedChartsInterface>(initialAdvancedChartDataState);

  const [ageGroupsSubtypeFilter, setAgeGroupsSubtypeFilter] = useState<
    string | number
  >('');

  const [ageGroupsFilter, setAgeGroupsFilter] = useState<string | number>('');

  const [educationLevelsFilter, setEducationLevelsFilter] = useState<
    string | number
  >('');

  const [educationLevelsSubtypeFilter, setEducationLevelsSubtypeFilter] =
    useState<string | number>('');

  const [householdStructureFilter, setHouseholdStructureFilter] = useState<
    string | number
  >('');

  const [householdStructureSubtypeFilter, setHouseholdStructureSubtypeFilter] =
    useState<string | number>('');

  const [professionalDegreesFilter, setProfessionalDegreesFilter] = useState<
    string | number
  >('');

  const [
    professionalDegreesSubtypeFilter,
    setProfessionalDegreesSubtypeFilter,
  ] = useState<string | number>('');

  useEffect(() => {
    if (socioEconomicsData) {
      const newAdvancedChartData: AdvancedChartsInterface =
        initialAdvancedChartDataState;
      const newSimpleChartData: SimpleChartsInterface =
        initialSimpleChartDataState;

      socioEconomicsData.forEach((dataSet) => {
        if (SimpleChartDataTypes.has(dataSet.type)) {
          newSimpleChartData[dataSet.type] = {
            data: { ...parseSimpleChartData(dataSet) },
          };
        }

        if (AdvancedChartDataType.has(dataSet.type)) {
          const yearKey = dataSet.type;
          const subtypeKey = `${yearKey}_subtype`;

          newAdvancedChartData[yearKey] = {
            ...parseAdvancedChartData(dataSet, 'year'),
          };
          newAdvancedChartData[subtypeKey] = {
            ...parseAdvancedChartData(dataSet, 'subtype'),
          };
        }
      });

      setSimpleChartData(newSimpleChartData);
      setAdvancedChartData(newAdvancedChartData);
    }
  }, [socioEconomicsData]);

  useEffect(() => {
    if (advancedChartData?.age_groups_subtype?.keys?.length > 0) {
      setAgeGroupsSubtypeFilter(advancedChartData.age_groups_subtype.keys[0]);
    }
    if (advancedChartData?.age_groups?.keys?.length > 0) {
      setAgeGroupsFilter(
        advancedChartData.age_groups.keys[
          advancedChartData?.age_groups.keys.length - 1
        ]
      );
    }
    if (advancedChartData?.education_levels?.keys?.length > 0) {
      setEducationLevelsFilter(
        advancedChartData.education_levels.keys[
          advancedChartData.education_levels.keys.length - 1
        ]
      );
    }
    if (advancedChartData?.education_levels_subtype?.keys?.length > 0) {
      setEducationLevelsSubtypeFilter(
        advancedChartData.education_levels_subtype.keys[0]
      );
    }
    if (advancedChartData?.household_structure?.keys?.length > 0) {
      setHouseholdStructureFilter(
        advancedChartData.household_structure.keys[
          advancedChartData.household_structure.keys.length - 1
        ]
      );
    }
    if (advancedChartData?.household_structure_subtype?.keys?.length > 0) {
      setHouseholdStructureSubtypeFilter(
        advancedChartData.household_structure_subtype.keys[0]
      );
    }
    if (advancedChartData?.professional_degrees?.keys?.length > 0) {
      setProfessionalDegreesFilter(
        advancedChartData.professional_degrees.keys[
          advancedChartData.professional_degrees.keys.length - 1
        ]
      );
    }
    if (advancedChartData?.professional_degrees_subtype?.keys?.length > 0) {
      setProfessionalDegreesSubtypeFilter(
        advancedChartData.professional_degrees_subtype.keys[0]
      );
    }
  }, [advancedChartData, socioEconomicsData]);

  const isShowPopulationSize =
    simpleChartData.population_size.data.data.length > 0;
  const isShowPopulationSizeTrend =
    simpleChartData.population_size_trend.data.data.length > 0;
  const isShowAgeGroups = advancedChartData?.age_groups?.keys?.length > 0;
  const isShowAgeGroupsSubtype =
    advancedChartData?.age_groups_subtype?.keys?.length > 0;
  const isShowEducationLevels =
    advancedChartData?.education_levels?.keys?.length;
  const isShowEducationLevelsSubtype =
    advancedChartData?.education_levels_subtype?.keys?.length > 0;
  const isShowHouseholdStructure =
    advancedChartData?.household_structure?.keys?.length > 0;
  const isShowHouseholdStructureSubtype =
    advancedChartData?.household_structure_subtype?.keys?.length > 0;
  const isShowBirthRate = simpleChartData.birth_rate.data.data.length > 0;
  const isShowCrimeRate = simpleChartData.crime_rate.data.data.length > 0;
  const isShowNetMigrationRate =
    simpleChartData.net_migration_rate.data.data.length > 0;
  const isShowEmployedPopulation =
    simpleChartData.employed_population.data.data.length > 0;
  const isShowProfessionalDegrees =
    advancedChartData?.professional_degrees?.keys?.length > 0;
  const isShowProfessionalDegreesSubtype =
    advancedChartData?.professional_degrees_subtype.keys.length > 0;

  return (
    <>
      <SocialEconomicsDataSourceCard
        title={t('social-economics.data-source.title')}
        content={t('social-economics.data-source.content')}
      />
      <ColumnWrapper isFullWidth={isFullWidth}>
        {isShowPopulationSize && (
          <Column isFullWidth={isFullWidth}>
            <CardWithTitle
              icon={UserMultipleHalfFemaleGroup}
              title={t('einwohnerzahl')}
            >
              <ChartContainer style={{ maxHeight: 'none' }}>
                <LineChart
                  id={t('einwohnerzahl')}
                  labels={simpleChartData.population_size.data.labels}
                  data={simpleChartData.population_size.data.data}
                  aspectRatio={isMobileSize ? undefined : 4}
                />
              </ChartContainer>
            </CardWithTitle>
          </Column>
        )}
        {/* {isShowPopulationSizeTrend && ( */}
        {/*  <Column  isFullWidth={isFullWidth}> */}
        {/*    <CardWithTitle */}
        {/*      icon={WorkflowCoachingChart1} */}
        {/*      title={t('Prognose der Einwohnerzahl')} */}
        {/*    > */}
        {/*      <ChartContainer> */}
        {/*        <LineChart */}
        {/*          id={t('Prognose der Einwohnerzahl')} */}
        {/*          labelsWithShortYear */}
        {/*          labels={simpleChartData.population_size_trend.data.labels} */}
        {/*          data={simpleChartData.population_size_trend.data.data} */}
        {/*        /> */}
        {/*      </ChartContainer> */}
        {/*    </CardWithTitle> */}
        {/*  </Column> */}
        {/* )} */}
      </ColumnWrapper>

      {(isShowAgeGroups || isShowAgeGroupsSubtype) && (
        <CardWithTitle icon={CakeBirthday} title={t('alter')}>
          <ColumnWrapper isFullWidth={isFullWidth}>
            {isShowAgeGroups && (
              <Column isFullWidth={isFullWidth}>
                <ChartWrapper className={'bar-chart-wrapper'}>
                  <Selector>
                    <SelectorLeft>
                      <SelectTitle content={t('alter')} />
                    </SelectorLeft>
                    <SelectorRight>
                      <Select
                        innerLabel={t('alter')}
                        options={advancedChartData?.age_groups.keys.map(
                          (key) => ({
                            label: key,
                            value: key,
                          })
                        )}
                        selectedOption={ageGroupsFilter}
                        onSelect={(value) => {
                          setAgeGroupsFilter(value);
                        }}
                      />
                    </SelectorRight>
                  </Selector>
                  <ChartContainer>
                    {isMobileSize && (
                      <BarChart
                        id={t('alter')}
                        height={250}
                        labels={
                          advancedChartData?.age_groups.data[ageGroupsFilter]
                            ?.labels || []
                        }
                        data={
                          advancedChartData?.age_groups.data[ageGroupsFilter]
                            ?.data || []
                        }
                      />
                    )}
                    {!isMobileSize && (
                      <BarChart
                        id={t('alter')}
                        height={150}
                        labels={
                          advancedChartData?.age_groups.data[ageGroupsFilter]
                            ?.labels || []
                        }
                        data={
                          advancedChartData?.age_groups.data[ageGroupsFilter]
                            ?.data || []
                        }
                      />
                    )}
                  </ChartContainer>
                </ChartWrapper>
              </Column>
            )}

            {isShowAgeGroupsSubtype && (
              <Column isFullWidth={isFullWidth}>
                <ChartWrapper>
                  <Selector>
                    <SelectorLeft>
                      <SelectTitle content={t('altersgruppengröße')} />
                    </SelectorLeft>
                    <SelectorRight>
                      <Select
                        innerLabel={t('altersgruppe')}
                        options={advancedChartData?.age_groups_subtype.keys.map(
                          (key) => ({ label: key, value: key })
                        )}
                        selectedOption={ageGroupsSubtypeFilter}
                        onSelect={(value) => {
                          setAgeGroupsSubtypeFilter(value);
                        }}
                      />
                    </SelectorRight>
                  </Selector>
                  <ChartContainer>
                    <LineChart
                      labels={
                        advancedChartData?.age_groups_subtype.data[
                          ageGroupsSubtypeFilter
                        ]?.labels || []
                      }
                      data={
                        advancedChartData?.age_groups_subtype.data[
                          ageGroupsSubtypeFilter
                        ]?.data || []
                      }
                    />
                  </ChartContainer>
                </ChartWrapper>
              </Column>
            )}
          </ColumnWrapper>
        </CardWithTitle>
      )}

      {(isShowEducationLevels || isShowEducationLevelsSubtype) && (
        <CardWithTitle
          icon={DesignDrawingBoardEducation}
          title={t('bildungsniveau')}
        >
          <ColumnWrapper isFullWidth={isFullWidth}>
            {isShowEducationLevels && (
              <Column isFullWidth={isFullWidth}>
                <ChartWrapper className={'bar-chart-wrapper'}>
                  <Selector>
                    <SelectorLeft>
                      <SelectTitle content={t('bildungsniveau')} />
                    </SelectorLeft>
                    <SelectorRight>
                      <Select
                        innerLabel={t('jahr')}
                        options={advancedChartData?.education_levels.keys.map(
                          (key) => ({ label: key, value: key })
                        )}
                        selectedOption={educationLevelsFilter}
                        onSelect={(value) => {
                          setEducationLevelsFilter(value);
                        }}
                      />
                    </SelectorRight>
                  </Selector>
                  <ChartContainer>
                    {isMobileSize && (
                      <BarChart
                        id={t('bildungsniveau')}
                        height={250}
                        labels={
                          advancedChartData?.education_levels?.data[
                            educationLevelsFilter
                          ]?.labels || []
                        }
                        data={
                          advancedChartData?.education_levels?.data[
                            educationLevelsFilter
                          ]?.data || []
                        }
                      />
                    )}
                    {!isMobileSize && (
                      <BarChart
                        id={t('bildungsniveau')}
                        height={150}
                        labels={
                          advancedChartData?.education_levels?.data[
                            educationLevelsFilter
                          ]?.labels || []
                        }
                        data={
                          advancedChartData?.education_levels?.data[
                            educationLevelsFilter
                          ]?.data || []
                        }
                      />
                    )}
                  </ChartContainer>
                </ChartWrapper>
              </Column>
            )}

            {isShowEducationLevelsSubtype && (
              <Column isFullWidth={isFullWidth}>
                <ChartWrapper>
                  <Selector>
                    <SelectorLeft>
                      <SelectTitle content={t('verteilung')} />
                    </SelectorLeft>
                    <SelectorRight>
                      <Select
                        innerLabel={t('bildungsniveau')}
                        options={advancedChartData?.education_levels_subtype?.keys?.map(
                          (key) => ({ label: key, value: key })
                        )}
                        selectedOption={educationLevelsSubtypeFilter}
                        onSelect={(value) => {
                          setEducationLevelsSubtypeFilter(value);
                        }}
                      />
                    </SelectorRight>
                  </Selector>
                  <ChartContainer>
                    <LineChart
                      labels={
                        advancedChartData?.education_levels_subtype?.data[
                          educationLevelsSubtypeFilter
                        ]?.labels || []
                      }
                      data={
                        advancedChartData?.education_levels_subtype?.data[
                          educationLevelsSubtypeFilter
                        ]?.data || []
                      }
                    />
                  </ChartContainer>
                </ChartWrapper>
              </Column>
            )}
          </ColumnWrapper>
        </CardWithTitle>
      )}

      {(isShowHouseholdStructure || isShowHouseholdStructureSubtype) && (
        <CardWithTitle icon={FamilyHome} title={t('haushaltsgröße')}>
          <ColumnWrapper isFullWidth={isFullWidth}>
            {isShowHouseholdStructure && (
              <Column isFullWidth={isFullWidth}>
                <ChartWrapper className={'bar-chart-wrapper'}>
                  <Selector>
                    <SelectorLeft>
                      <SelectTitle content={t('haushaltsgrößenklassen')} />
                    </SelectorLeft>
                    <SelectorRight>
                      <Select
                        innerLabel={t('jahr')}
                        options={advancedChartData?.household_structure.keys.map(
                          (key) => ({ label: key, value: key })
                        )}
                        selectedOption={householdStructureFilter}
                        onSelect={(value) => {
                          setHouseholdStructureFilter(value);
                        }}
                      />
                    </SelectorRight>
                  </Selector>
                  <ChartContainer>
                    {isMobileSize && (
                      <BarChart
                        id={t('haushaltsgrößenklassen')}
                        height={250}
                        labels={
                          advancedChartData?.household_structure?.data[
                            householdStructureFilter
                          ]?.labels || []
                        }
                        data={
                          advancedChartData?.household_structure?.data[
                            householdStructureFilter
                          ]?.data || []
                        }
                      />
                    )}
                    {!isMobileSize && (
                      <BarChart
                        id={t('haushaltsgrößenklassen')}
                        height={150}
                        labels={
                          advancedChartData?.household_structure?.data[
                            householdStructureFilter
                          ]?.labels || []
                        }
                        data={
                          advancedChartData?.household_structure?.data[
                            householdStructureFilter
                          ]?.data || []
                        }
                      />
                    )}
                  </ChartContainer>
                </ChartWrapper>
              </Column>
            )}

            {isShowHouseholdStructureSubtype && (
              <Column isFullWidth={isFullWidth}>
                <ChartWrapper>
                  <Selector>
                    <SelectorLeft>
                      <SelectTitle content={t('haushaltskategorie')} />
                    </SelectorLeft>
                    <SelectorRight>
                      <Select
                        innerLabel={t('kategorie')}
                        options={advancedChartData?.household_structure_subtype.keys.map(
                          (key) => ({ label: key, value: key })
                        )}
                        selectedOption={householdStructureSubtypeFilter}
                        onSelect={(value) => {
                          setHouseholdStructureSubtypeFilter(value);
                        }}
                      />
                    </SelectorRight>
                  </Selector>
                  <ChartContainer>
                    <LineChart
                      labels={
                        advancedChartData?.household_structure_subtype?.data[
                          householdStructureSubtypeFilter
                        ]?.labels || []
                      }
                      data={
                        advancedChartData?.household_structure_subtype?.data[
                          householdStructureSubtypeFilter
                        ]?.data || []
                      }
                    />
                  </ChartContainer>
                </ChartWrapper>
              </Column>
            )}
          </ColumnWrapper>
        </CardWithTitle>
      )}

      <ColumnWrapper isFullWidth={isFullWidth}>
        {isShowBirthRate && (
          <Column isFullWidth={isFullWidth}>
            <CardWithTitle icon={BabyHoldHands} title={t('geburtenrate')}>
              <ChartContainer
                style={{
                  ...(!(isShowCrimeRate && !isOnBuyerPortal) && {
                    maxHeight: 'none',
                  }),
                }}
              >
                <LineChart
                  id={t('geburtenrate')}
                  labels={simpleChartData.birth_rate.data.labels}
                  data={simpleChartData.birth_rate.data.data}
                  aspectRatio={
                    isMobileSize || (isShowCrimeRate && !isOnBuyerPortal)
                      ? undefined
                      : 4
                  }
                />
              </ChartContainer>
            </CardWithTitle>
          </Column>
        )}
        {isShowCrimeRate && !isOnBuyerPortal && (
          <Column isFullWidth={isFullWidth}>
            <CardWithTitle icon={CrimeManThief1} title={t('kriminalitätsrate')}>
              <ChartContainer>
                <LineChart
                  id={t('kriminalitätsrate')}
                  labels={simpleChartData.crime_rate.data.labels}
                  data={simpleChartData.crime_rate.data.data}
                />
              </ChartContainer>
            </CardWithTitle>
          </Column>
        )}
      </ColumnWrapper>

      <ColumnWrapper isFullWidth={isFullWidth}>
        {isShowNetMigrationRate && (
          <Column isFullWidth={isFullWidth}>
            <CardWithTitle icon={BaggagePlane} title={t('wanderungssaldo')}>
              <ChartContainer>
                <LineChart
                  id={t('wanderungssaldo')}
                  labels={simpleChartData.net_migration_rate.data.labels}
                  data={simpleChartData.net_migration_rate.data.data}
                />
              </ChartContainer>
            </CardWithTitle>
          </Column>
        )}
        {isShowEmployedPopulation && (
          <Column isFullWidth={isFullWidth}>
            <CardWithTitle
              icon={SingleNeutralBriefcase}
              title={t('anzahl erwerbstätige')}
            >
              <ChartContainer>
                <LineChart
                  id={t('anzahl erwerbstätige')}
                  labels={simpleChartData.employed_population.data.labels}
                  data={simpleChartData.employed_population.data.data}
                />
              </ChartContainer>
            </CardWithTitle>
          </Column>
        )}
      </ColumnWrapper>

      {(isShowProfessionalDegrees || isShowProfessionalDegreesSubtype) && (
        <CardWithTitle icon={CoupleAlbumBook} title={t('berufsabschlüsse')}>
          <ColumnWrapper isFullWidth={isFullWidth}>
            {isShowProfessionalDegrees && (
              <Column isFullWidth={isFullWidth}>
                <ChartWrapper className={'bar-chart-wrapper'}>
                  <Selector>
                    <SelectorLeft>
                      <SelectTitle content={t('verteilung')} />
                    </SelectorLeft>
                    <SelectorRight>
                      <Select
                        innerLabel={t('jahr')}
                        options={advancedChartData?.professional_degrees.keys.map(
                          (key) => ({ label: key, value: key })
                        )}
                        selectedOption={professionalDegreesFilter}
                        onSelect={(value) => {
                          setProfessionalDegreesFilter(value);
                        }}
                      />
                    </SelectorRight>
                  </Selector>
                  <ChartContainer>
                    {isMobileSize && (
                      <BarChart
                        id={t('verteilung')}
                        height={250}
                        labels={
                          advancedChartData?.professional_degrees?.data[
                            professionalDegreesFilter
                          ]?.labels || []
                        }
                        data={
                          advancedChartData?.professional_degrees?.data[
                            professionalDegreesFilter
                          ]?.data || []
                        }
                      />
                    )}
                    {!isMobileSize && (
                      <BarChart
                        id={t('verteilung')}
                        height={150}
                        labels={
                          advancedChartData?.professional_degrees?.data[
                            professionalDegreesFilter
                          ]?.labels || []
                        }
                        data={
                          advancedChartData?.professional_degrees?.data[
                            professionalDegreesFilter
                          ]?.data || []
                        }
                      />
                    )}
                  </ChartContainer>
                </ChartWrapper>
              </Column>
            )}

            {isShowProfessionalDegreesSubtype && (
              <Column isFullWidth={isFullWidth}>
                <ChartWrapper>
                  <Selector>
                    <SelectorLeft>
                      <SelectTitle content={t('verteilung')} />
                    </SelectorLeft>
                    <SelectorRight>
                      <Select
                        innerLabel={t('berufsabschlussklasse')}
                        options={advancedChartData?.professional_degrees_subtype.keys.map(
                          (key) => ({ label: key, value: key })
                        )}
                        selectedOption={professionalDegreesSubtypeFilter}
                        onSelect={(value) => {
                          setProfessionalDegreesSubtypeFilter(value);
                        }}
                      />
                    </SelectorRight>
                  </Selector>
                  <ChartContainer>
                    <LineChart
                      labels={
                        advancedChartData?.professional_degrees_subtype?.data[
                          professionalDegreesSubtypeFilter
                        ]?.labels || []
                      }
                      data={
                        advancedChartData?.professional_degrees_subtype?.data[
                          professionalDegreesSubtypeFilter
                        ]?.data || []
                      }
                    />
                  </ChartContainer>
                </ChartWrapper>
              </Column>
            )}
          </ColumnWrapper>
        </CardWithTitle>
      )}
    </>
  );
};

export { SocialEconomics };
