import { FC, RefObject, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { siteMap } from 'routes/site-map';
import { ReactComponent as Contact } from 'assets/streamline-light/messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-typing.svg';
import { ReactComponent as CoinsIcon } from 'assets/streamline-light/money-payments-finance/accounting-billing/accounting-coins.svg';
import { ReactComponent as Banknote } from 'assets/streamline-light/money-payments-finance/money/accounting-bill.svg';
import { Tooltip } from 'modules/common/components/tooltip';
import { open } from '../../../../../../forms/form-purchase-intend/redux/purchaseIntendSlice';

import {
  ExposeFeedbackButton,
  FinancingButton,
  PurchaseOfferButton,
  Row,
} from '../head-info-styles';
import {
  setPropertyMeta,
  toggleTimifyModal,
} from '../../../../../../timify/redux/timifySlice';
import {
  setActiveTab,
  setIsAppointmentSelectOpen,
} from '../../../redux/exposeSlice';
import { useAppSelector } from '../../../../../../common/hooks';
import PurchaseIntentDropdown from '../purchase-intent-dropdown';
import {
  FinancingType,
  PropertyPurchaseIntent,
} from '../../../../../../../generated';
import { useOpenFinancing } from '../../../../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';
import { setSuggestionProperty } from '../../../../../../forms/form-financing/redux/financingSlice';
import { useLngHistoryPush } from '../../../../../../localization/lng-history-push';
import { setMarketingParameters } from '../../../../../../auth/redux/authSlice';

const TooltipHeader = styled.h4`
  font-family: 'Source Serif Pro', serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;
interface IProps {
  id: number;
  price: number;
  purchaseIntents: PropertyPurchaseIntent[];
  refetch: () => void;
  contactFormRef?: RefObject<HTMLDivElement>;
}

const Buttons: FC<IProps> = ({
  id,
  price,
  purchaseIntents,
  refetch,
  contactFormRef,
}) => {
  const dispatch = useDispatch();
  const { openFinancing } = useOpenFinancing({});
  const theme = useTheme();
  const [purchaseIntentDropdownIsOpen, openPurchaseIntentDropdown] =
    useState(false);
  const { t } = useTranslation();
  const lngHPush = useLngHistoryPush();
  const activeTab = useAppSelector((state) => state.exposeDetails.activeTab);

  const isSelectOpen = useAppSelector(
    (state) => state.exposeDetails.isAppointmentSelectOpen
  );
  const isOpenAccordion = useAppSelector(
    (state) => state.exposeDetails.isOpenHeadAccordion
  );

  const userId = useAppSelector((state) => state.auth.user?._id);

  const propertyId = useAppSelector(
    (state) => state.exposeDetails.externalPropertyId
  );

  const financingId = useAppSelector(
    (state) => state.exposeDetails.financingId
  );

  const setIsSelectOpen = useCallback(() => {
    dispatch(setIsAppointmentSelectOpen(!isSelectOpen));
  }, [dispatch, isSelectOpen]);

  const closeDropdown = useCallback(() => {
    dispatch(setIsAppointmentSelectOpen(false));
  }, [dispatch]);

  const scrollToContactForm = useCallback(() => {
    const to = activeTab === 0 ? 0 : 500;

    if (activeTab !== 0) {
      dispatch(setActiveTab(0));
    }

    setTimeout(() => {
      if (contactFormRef?.current) {
        contactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }, to);
  }, [contactFormRef, activeTab]);

  const openPurchaseIntent = () => {
    if (purchaseIntents.length > 0 && !purchaseIntentDropdownIsOpen) {
      openPurchaseIntentDropdown(true);
    } else {
      dispatch(open());
    }
  };

  const handleOpenTimifyModal = () => {
    dispatch(toggleTimifyModal(true));
    dispatch(
      setPropertyMeta({
        vonpollPropertyId: propertyId,
        vonpollUserId: userId || '',
      })
    );
  };

  const closePurchaseIntentDropdown = () => {
    openPurchaseIntentDropdown(false);
  };

  useEffect(() => {
    if (purchaseIntents.length === 0) {
      openPurchaseIntentDropdown(false);
    }
  }, [purchaseIntents]);

  useEffect(() => {
    closePurchaseIntentDropdown();
  }, [isOpenAccordion]);

  const onClickFinancing = () => {
    if (financingId) {
      lngHPush(
        `${siteMap.FinancingPortalPage.pathWithoutParams}/${financingId}`
      );
      return;
    }

    openFinancing(FinancingType.Financing);
    dispatch(
      setSuggestionProperty({ purchasePrice: price, propertyId: Number(id) })
    );
    const utmParams = `utm_source=kundenportal&utm_medium=finanzieren&utm_campaign=immoanfrage-einzel&property_id=${id}`;
    const href = window?.location?.href;
    const markParams = href.includes('?')
      ? `${href}&${utmParams}`
      : `${href}?${utmParams}`;
    dispatch(setMarketingParameters(markParams));
  };

  return (
    <Row
      isWrap
      alignItems={'center'}
      margin="12px 0 0 0"
      justifyContent={'space-between'}
    >
      {/* <SelectWithArrow */}
      {/*  isShortcut={!isOpenAccordion} */}
      {/*  isOpen={isSelectOpen} */}
      {/*  setIsOpen={setIsSelectOpen} */}
      {/*  closeDropdown={closeDropdown} */}
      {/*  selectButtonStyles={ */}
      {/*    isOpenAccordion ? { padding: '12px 14px' } : { padding: '8px' } */}
      {/*  } */}
      {/*  onClick={handleOpenTimifyModal} */}
      {/*  innerLabel={t('expose-page.head-info.button.make-an-appointment')} */}
      {/*  selectStyles={{ */}
      {/*    marginRight: '8px', */}
      {/*    marginBottom: isOpenAccordion ? '12px' : '0', */}
      {/*  }} */}
      {/*  iconStyles={isOpenAccordion ? { margin: '0 6px 0 0' } : { margin: '0' }} */}
      {/*  arrowIconStyles={ */}
      {/*    isOpenAccordion ? { margin: '0 0 0 6px' } : { display: 'none' } */}
      {/*  } */}
      {/* /> */}

      <FinancingButton
        label={t(
          financingId
            ? 'search-profile.online-exposes.button.open-financing'
            : 'expose-page.head-info.button.financing'
        )}
        onClick={onClickFinancing}
        color={theme.ctaBlue}
        borderColor={theme.ctaBlue}
        // icon={isOpenAccordion ? undefined : Banknote}
        // iconStyle={
        //   isOpenAccordion
        //     ? undefined
        //     : { margin: '0', width: '16px', height: '16px' }
        // }
        // isShortcut={!isOpenAccordion}
        style={{
          padding: 12,
          marginBottom: 12,
          marginRight: 0,
        }}
      />
      <ExposeFeedbackButton
        label={t('expose-overlay.contact-form.button')}
        onClick={scrollToContactForm}
        color={theme.blue}
        borderColor={theme.blue}
        // icon={isOpenAccordion ? undefined : Contact}
        // iconStyle={
        //   isOpenAccordion
        //     ? undefined
        //     : { margin: '0', width: '16px', height: '16px' }
        // }
        // isShortcut={!isOpenAccordion}
        style={{
          padding: 12,
          marginBottom: 12,
          marginRight: 0,
        }}
      />
      {purchaseIntentDropdownIsOpen ? (
        <PurchaseIntentDropdown
          isOpenAccordion={isOpenAccordion}
          refetch={refetch}
          purchaseIntents={purchaseIntents}
          openPurchaseIntent={openPurchaseIntent}
          closePurchaseIntentDropdown={closePurchaseIntentDropdown}
        />
      ) : (
        <PurchaseOfferButton
          // isShortcut={!isOpenAccordion}
          style={{
            padding: 12,
            marginBottom: 12,
            marginRight: 0,
          }}
          label={t('expose-page.head-info.button.interest-in-buying')}
          // icon={isOpenAccordion ? undefined : CoinsIcon}
          onClick={openPurchaseIntent}
          // iconStyle={isOpenAccordion ? undefined : { margin: '0' }}
          // iconWidth={20}
          // iconHeight={20}
        />
      )}
    </Row>
  );
};

export { Buttons };
