import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  SecondaryTitle,
  Text,
  Row,
  BackupCodes,
  CopyButton,
} from './mfa-modal-styles';
import { copyToClipboard } from './utils';
import MfaContext from './mfa-context';
import { useIsMobileSize } from 'modules/common/hooks/useIsMobileSize';
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text as PdfText,
  Rect,
} from '@react-pdf/renderer';

interface IProps {
  onHandleCodes: (newValue: boolean) => void;
}

export const MfaSuccess = ({ onHandleCodes }: IProps) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();

  const { backupCodes } = useContext(MfaContext);

  const handleCopy = useCallback(() => {
    if (backupCodes) {
      copyToClipboard(backupCodes.join('\r\n'));
      onHandleCodes(true);
    }
  }, [backupCodes, onHandleCodes]);

  const handlePdf = useCallback(() => {
    if (backupCodes) {
      onHandleCodes(true);
    }
  }, [backupCodes, onHandleCodes]);

  if (!backupCodes) return <Text content="Error" />;

  return (
    <>
      <Text content={t('auth.mfa.success.text1')} />
      <Row isCentered>
        <SecondaryTitle content={t('auth.mfa.success.backup-codes')} />
      </Row>
      <BackupCodes>
        {backupCodes.map((code, index) => (
          <li key={index}>
            {code
              .match(/.{1,4}/g)
              ?.join(' - ')
              .toUpperCase()}
          </li>
        ))}
      </BackupCodes>

      {!isMobileSize && <Text content={t('auth.mfa.success.text2')} />}
      <Row
        isCentered
        style={{
          ...(isMobileSize && {
            flexDirection: 'column',
            gap: 16,
          }),
          ...(!isMobileSize && {
            gap: 24,
          }),
        }}
      >
        <CopyButton
          label={t('auth.mfa.success.copy-code')}
          color={themeContext.blue}
          borderColor={themeContext.blue}
          onClick={handleCopy}
        />
        <PDFDownloadLink
          style={{ textDecoration: 'none' }}
          document={
            <Document>
              <Page>
                {backupCodes.map((code, index) => (
                  <View>
                    <PdfText key={index}>
                      {code
                        .match(/.{1,4}/g)
                        ?.join(' - ')
                        .toUpperCase()}
                    </PdfText>
                  </View>
                ))}
              </Page>
            </Document>
          }
          fileName="vonpoll-backup-codes.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <PdfText>Loading document...</PdfText>
            ) : (
              <CopyButton
                label={t('auth.mfa.success.pdf')}
                color={themeContext.blue}
                borderColor={themeContext.blue}
                onClick={handlePdf}
              />
            )
          }
        </PDFDownloadLink>
      </Row>
      {isMobileSize && <Text content={t('auth.mfa.success.text2')} />}
    </>
  );
};
