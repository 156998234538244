import { CSSProperties, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as S from './input-outside-top-label-styled';
import {
  InputOutsideTopLabel,
  IInputOutsideTopLabel,
} from './input-outside-top-label';

export interface IInputWithOutsideTopLabel extends IInputOutsideTopLabel {
  hasSelect?: boolean;
  selectWidth?: string;
  selectValue?: string | number;
  selectOptions?: Array<string | number>;
  selectName?: string;
  isDisabled?: boolean;
  isRedesign?: boolean;
  hasDisclaimer?: boolean;
  labelClassName?: string;
  inputClassName?: string;
  selectClassName?: string;
  dropdownStyle?: CSSProperties;
}

const InputOutsideTopLabelWithSelect = ({
  id,
  selectWidth,
  selectValue,
  selectOptions,
  selectName,
  className,
  name,
  label,
  type,
  defaultValue,
  placeholder,
  rules,
  isDisabled,
  isRedesign,
  hasDisclaimer,
  labelClassName,
  inputClassName,
  selectClassName,
  dropdownStyle,
}: IInputWithOutsideTopLabel): JSX.Element => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectValue);
  const { register } = useFormContext();

  const onSelectToggle = useCallback(
    () => setIsSelectOpen(!isSelectOpen),
    [isSelectOpen]
  );

  const onSelectClose = useCallback(() => setIsSelectOpen(false), []);

  const onSelectOptionClick = useCallback(
    (option) => () => {
      setSelectedOption(option);
      setValue(selectName ?? '', option);

      onSelectClose();
    },
    [onSelectClose, setValue, selectName]
  );

  return (
    <InputOutsideTopLabel
      id={id}
      className={className}
      placeholder={placeholder}
      hasSelect
      isRedesign={isRedesign}
      selectWidth={selectWidth}
      labelClassName={labelClassName}
      inputClassName={inputClassName}
      selectNode={
        <S.SelectWrapper onOutsideClick={onSelectClose}>
          <S.Select
            className={selectClassName}
            isRedesign={isRedesign}
            isActive={isSelectOpen}
            width={selectWidth}
            onClick={onSelectToggle}
            isDisabled={isDisabled}
          >
            {typeof selectedOption === 'string'
              ? t(selectedOption)
              : selectedOption}{' '}
            <S.SelectIcon />
            <S.HiddenInput
              type="hidden"
              {...register(`${selectName}` as const, rules)}
              defaultValue={selectedOption}
            />
          </S.Select>
          {isSelectOpen && (
            <S.SelectDropdown style={dropdownStyle}>
              {selectOptions?.map((option) => (
                <S.SelectOption
                  key={option}
                  isSelected={selectedOption === option}
                  onClick={onSelectOptionClick(option)}
                  showSeparator={type === 'number'}
                >
                  {t(`${option}`)}
                </S.SelectOption>
              ))}
            </S.SelectDropdown>
          )}
        </S.SelectWrapper>
      }
      defaultValue={defaultValue}
      name={name}
      type={type}
      label={label}
      rules={rules}
      isDisabled={isDisabled}
      hasDisclaimer={hasDisclaimer}
      inputMode={type === 'number' ? 'numeric' : 'text'}
    />
  );
};

export { InputOutsideTopLabelWithSelect };
