import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  Text,
  Row,
  Footer,
  NextButton,
  ContentAfterLogin,
  LeftTitle,
  CodeWrapper,
} from './mfa-modal-styles';
import { useDispatch } from 'react-redux';
import { toggleIsMfaAfterLoginModalOpen } from 'modules/auth/redux/authSlice';
import MfaAfterLoginContext from './mfa-after-login-context';
import { FormProvider, useForm } from 'react-hook-form';
import { InputOutsideTopLabel } from 'modules/common/components/form';
import { useSendTwoFaRecoverySupportEmailMutation } from 'generated';

export const MfaAfterLoginSupport = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const { onClose } = useContext(MfaAfterLoginContext);

  const methods = useForm<{
    email: string;
  }>();

  const email = methods.watch('email');

  const [error, setError] = useState<boolean>();
  const [success, setSuccess] = useState<boolean>();

  const [sendRecoveryEmail, sendRecoveryEmailData] =
    useSendTwoFaRecoverySupportEmailMutation();

  const handleNext = useCallback(() => {
    sendRecoveryEmail({ email });
  }, [email]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    setError(false);
  }, [email]);

  useEffect(() => {
    if (sendRecoveryEmailData.isLoading) return;

    if (
      sendRecoveryEmailData.isSuccess &&
      !sendRecoveryEmailData.data.sendTwoFaRecoverySupportEmail
    ) {
      setError(true);
      return;
    }

    if (
      sendRecoveryEmailData.isSuccess &&
      sendRecoveryEmailData.data.sendTwoFaRecoverySupportEmail
    ) {
      setError(false);
      setSuccess(true);
    } else {
      setError(true);
    }
  }, [sendRecoveryEmailData]);

  return (
    <ContentAfterLogin style={{ color: themeContext.blue }}>
      <LeftTitle content={t(`auth.mfa.after-login.support.title`)} />

      {!success ? (
        <>
          <Text content={t('auth.mfa.after-login.support.text')} />
          <Row>
            <CodeWrapper hasError={error}>
              <FormProvider {...methods}>
                <InputOutsideTopLabel
                  type="text"
                  name="email"
                  label=""
                  noLabel
                  placeholder={t('auth.mfa.after-login.support.email')}
                  rules={{}}
                  inputClassName="code-input"
                  isRedesign
                />
              </FormProvider>
            </CodeWrapper>
          </Row>
        </>
      ) : (
        <Text content={t('auth.mfa.after-login.support.success')} />
      )}

      {error && !success && (
        <>
          <Text
            color="#C5363B"
            content={t('auth.mfa.after-login.support.error')}
          />
        </>
      )}

      <Footer style={{ justifyContent: 'flex-end' }}>
        {!success ? (
          <NextButton
            label={t(`auth.mfa.after-login.support.send`)}
            onClick={handleNext}
            isDisabled={!email}
          />
        ) : (
          <NextButton label={t(`auth.mfa.close`)} onClick={handleClose} />
        )}
      </Footer>
    </ContentAfterLogin>
  );
};
