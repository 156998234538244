import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { siteMap } from '../../../../../../routes/site-map';
import { useAppSelector } from '../../../../../common/hooks';
import {
  useExposeDetailsHeadInfo,
  useExposeDetailsPriceHubble,
} from '../../hooks/useExposeDetailsQueries';
import { setIsShadowMapExpanded } from '../../redux/exposeSlice';

const ExposeShadowmapMobile: FC = () => {
  const { location } = useExposeDetailsPriceHubble();
  const dispatch = useDispatch();

  const {
    headInfo: { fullAddress },
  } = useExposeDetailsHeadInfo();

  const isShadowMapExpanded = useAppSelector(
    (state) => state.exposeDetails.isShadowmapExpanded
  );

  useEffect(() => {
    if (location && fullAddress && isShadowMapExpanded) {
      dispatch(setIsShadowMapExpanded(false));
      window.open(
        `${siteMap.ShadowmapPage.pathWithoutParams}?lat=${
          location?.coordinates?.latitude || 0
        }&lon=${location?.coordinates?.longitude || 0}&a=${encodeURIComponent(
          fullAddress || ''
        )}`,
        '_blank'
      );
    }
  }, [location, fullAddress, isShadowMapExpanded, dispatch]);

  return null;
};

export default ExposeShadowmapMobile;
