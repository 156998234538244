import { CodeInputField } from './mfa-modal-styles';

interface IProps {
  index: number;
  value: string;
  onChange: (index: number, newValue: string) => void;
  onBackspace: (index: number) => void;
  inputRef: React.Ref<HTMLInputElement>;
}

const MfaInputField = ({
  index,
  value,
  onChange,
  onBackspace,
  inputRef,
}: IProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    const sanitizedInput = input.slice(0, 1).replace(/\D/g, '');

    if (onChange) {
      onChange(index, sanitizedInput);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Detect backspace key when the input is empty
    if (e.key === 'Backspace' && !value) {
      onBackspace(index);
    }

    // Prevent entering e, E, -, and + directly from the keyboard
    if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  };

  return (
    <CodeInputField
      ref={inputRef}
      type="number"
      min="0"
      max="9"
      step="1"
      placeholder="X"
      value={value}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default MfaInputField;
