import { memo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as LocationMarker } from 'assets/streamline-light/maps-navigation/pins/pin.svg';
import { ReactComponent as CommonFileText } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { ReactComponent as Basket } from 'assets/streamline-light/shopping-ecommerce/baskets/shopping-basket-check.svg';
import { ReactComponent as Finance } from 'assets/streamline-light/money-payments-finance/money/accounting-bill.svg';
import { ReactComponent as Heart } from '../../../../assets/streamline-light/social-medias-rewards-rating/love-it/love-it.svg';
import { useLikePropertyMutation } from '../../../../services/graphql/enhanced';
import { setMarketingParameters } from '../../../auth/redux/authSlice';
import { ToolTipBasicContent } from '../../../common/components/tooltip/TooltipBasicContent';

import Icon from '../../../common/components/ui/icon';
import { useToLocalWithCurrency } from '../../../common/utils/convertCHF';
import { useOpenFinancing } from '../../../forms/form-financing/hooks/callback-hooks/useOpenFinancing';
import { setSuggestionProperty } from '../../../forms/form-financing/redux/financingSlice';

import {
  CardContainer,
  ImageWrapper,
  CardImage,
  CardContent,
  CardTitle,
  Row,
  Tag,
  PriceContainer,
  Price,
  Location,
  SquareMeterPrice,
  LocationIcon,
  NewLabel,
  CardWrapper,
  RequestExposeButton,
  PropertyDetailsBox,
  PropertyDetailsBoxKey,
  PropertyDetailsBoxValue,
  ExposeButtonsBox,
  ActionButton,
  FavoriteButton,
  ActionButtonTooltipBox,
} from '../../../landing/components/buyer-lp/suggested-properties/components/suggested-properties-styles';

import {
  CountryCodeFromVonPollDbEnum,
  Currency,
  FinancingType,
  useReadPropertyPurchaseIntentsQuery,
} from '../../../../generated';
import { Tooltip } from '../../../common/components/tooltip';
import { useDispatch } from 'react-redux';
import { setSelectedTab } from 'modules/property/redux/dynamicMapUtilsSlice';
import { siteMap } from 'routes/site-map';
import { useGetCountry } from 'modules/localization/get-country';
import { useParams } from 'react-router-dom';
import FormPurchaseIntend from 'modules/forms/form-purchase-intend/components/containers/form-purchase-intend';
import { useAppSelector } from 'modules/common/hooks';
import { useLngHistoryPush } from '../../../localization/lng-history-push';

export enum CardTypes {
  SUGGESTED_PROPERTY = 'SuggestedProperty',
  FAVOURITE = 'Favourite',
}

const CardFooter = styled.div<{
  isListView?: boolean;
}>`
  width: 100%;
`;

interface IProps {
  id: string;
  internalId: string;
  title: string;
  price: number;
  numberOfRooms: number;
  livingArea: number;
  landArea: number;
  currency: string;
  imageSrc: string;
  url?: string;
  regionalAddition: string;
  location: string;
  postCode?: string | number;
  isListView?: boolean;
  hasRequestExpose?: boolean;
  isDisabled?: boolean;
  type?: CardTypes;
  isLiked?: boolean;
  isRequestedForExpose?: boolean;
  propertyType?: string;
  isNew?: boolean;
  isUnlocked?: boolean;
  countryCode?: CountryCodeFromVonPollDbEnum | null;
  propertyId: number;
  exposeRequestId?: string;
  offeredPrice?: number;
  onClickOpenOverlay?: () => void;
  financingId?: string;
}

const SuggestedProperty = ({
  id,
  internalId,
  title,
  price,
  numberOfRooms,
  livingArea,
  landArea,
  currency,
  imageSrc,
  url,
  regionalAddition,
  location,
  isListView = false,
  hasRequestExpose = false,
  isDisabled = false,
  postCode = '',
  type = CardTypes.SUGGESTED_PROPERTY,
  isLiked = false,
  isRequestedForExpose = false,
  propertyType,
  isNew,
  propertyId,
  isUnlocked = false,
  countryCode,
  exposeRequestId,
  offeredPrice,
  onClickOpenOverlay,
  financingId,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language, getCountry } = useGetCountry();
  const { openFinancing } = useOpenFinancing({});
  const lngHPush = useLngHistoryPush();

  const pricePerSqm = Number.parseFloat(
    (price / (livingArea || landArea)).toFixed(2).toString()
  );
  const { searchProfileId } = useParams<{ searchProfileId: string }>();
  const [isOpen, setIsOpen] = useState<boolean>();
  const isLoggedIn = useAppSelector((state) => !!state.auth.user);

  const [likeProperty] = useLikePropertyMutation();

  const onClickPropertyDetails = useCallback(() => {
    if (url) {
      window.open(url, '_blank');
    }
  }, [url]);

  const regionalAdditionString = regionalAddition
    ? `– ${regionalAddition}`
    : '';

  const isGermany = countryCode === CountryCodeFromVonPollDbEnum.Deu;

  const onClickRequestExpose = useCallback(() => {
    if (isUnlocked && onClickOpenOverlay) {
      dispatch(setSelectedTab(2));
      onClickOpenOverlay();
    } else {
      window.open(
        isGermany
          ? `${siteMap.RequestExposePage.pathWithoutParams}/${id}?searchProfileId=${searchProfileId}&propertyId=${propertyId}`
          : `https://www.von-poll.com/${language}/expose/immobilie/${propertyId}`,
        '_blank'
      );
    }
  }, [
    dispatch,
    id,
    isGermany,
    // isDeAtCh,
    isUnlocked,
    language,
    onClickOpenOverlay,
    propertyId,
    searchProfileId,
  ]);

  const onClickFinancing = () => {
    if (financingId) {
      lngHPush(
        `${siteMap.FinancingPortalPage.pathWithoutParams}/${financingId}`
      );
      return;
    }

    openFinancing(FinancingType.Financing);
    dispatch(setSuggestionProperty({ propertyId, purchasePrice: price }));
    const utmParams = `utm_source=kundenportal&utm_medium=suchprofil&utm_campaign=online-expose-freigabe&property_id=${propertyId}`;
    const href = window?.location?.href;
    const markParams = href.includes('?')
      ? `${href}&${utmParams}`
      : `${href}?${utmParams}`;
    dispatch(setMarketingParameters(markParams));
  };

  const onClickLikeProperty = useCallback(async () => {
    try {
      if (isLoggedIn) {
        await likeProperty({
          propertyId: id,
          propertyPrice: price,
          searchProfileId,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [likeProperty, id, propertyId, price, searchProfileId, isLoggedIn]);

  const { refetch: refetchPropertyPurchaseIntents } =
    useReadPropertyPurchaseIntentsQuery(
      {
        propertyId: internalId || '',
      },
      { skip: !isUnlocked }
    );

  const [squareMetrePrice, squareMetreCurrency] = useToLocalWithCurrency(
    pricePerSqm,
    currency as Currency,
    true
  ) as [string, string];

  const localPriceWithCurrency = useToLocalWithCurrency(
    price,
    currency as Currency
  );

  return (
    <CardWrapper isListView={isListView}>
      <CardContainer
        isListView={isListView}
        onClick={!hasRequestExpose ? onClickPropertyDetails : undefined}
        style={{ cursor: url && !hasRequestExpose ? 'pointer' : 'default' }}
        isDisabled={isDisabled}
      >
        {isNew && <NewLabel>NEU</NewLabel>}
        {isLoggedIn && (
          <FavoriteButton isLiked={isLiked} onClick={onClickLikeProperty}>
            <Icon
              icon={Heart}
              width={16}
              height={16}
              color={isLiked ? themeContext.white : themeContext.blue}
            />
          </FavoriteButton>
        )}
        <ImageWrapper onClick={onClickRequestExpose} isListView={isListView}>
          <CardImage src={imageSrc} isListView={isListView} />
          <Tag
            style={{ whiteSpace: 'nowrap' }}
            onClick={onClickPropertyDetails}
          >
            {`# ${id}`}
          </Tag>
        </ImageWrapper>
        <CardContent isListView={isListView}>
          <Row style={{ display: 'inline-grid' }}>
            <CardTitle onClick={onClickRequestExpose}>{title}</CardTitle>
          </Row>
          <Row justifyContent={'space-between'}>
            <Row>
              <LocationIcon icon={LocationMarker} color={themeContext.blue} />
              <Tooltip
                id={`location-${id}`}
                position="top"
                textAlign="left"
                content={
                  <Location
                    maxWidth={300}
                  >{`${postCode} ${location} ${regionalAdditionString}`}</Location>
                }
              >
                <Location
                  maxWidth={400}
                >{`${postCode} ${location} ${regionalAdditionString}`}</Location>
              </Tooltip>
            </Row>
          </Row>
          <PriceContainer>
            {price === 0 ? (
              <Price style={{ marginRight: 0 }}>
                {t('property.offers.card.price-on-request.label')}
              </Price>
            ) : (
              <>
                <Price>{localPriceWithCurrency}</Price>
                <SquareMeterPrice>
                  ({squareMetrePrice} {squareMetreCurrency}/m<sup>2</sup>)
                </SquareMeterPrice>
              </>
            )}
          </PriceContainer>
          {(propertyType === 'haus' || propertyType === 'wohnung') && (
            <PropertyDetailsBox>
              <PropertyDetailsBoxKey>
                {t('suggested-property.details.rooms-number')}
              </PropertyDetailsBoxKey>
              <PropertyDetailsBoxValue>{numberOfRooms}</PropertyDetailsBoxValue>
            </PropertyDetailsBox>
          )}
          {propertyType !== 'grundstueck' && (
            <PropertyDetailsBox>
              <PropertyDetailsBoxKey>
                {t('suggested-property.details.living-area')}
              </PropertyDetailsBoxKey>
              <PropertyDetailsBoxValue>
                {livingArea} m<sup>2</sup>
              </PropertyDetailsBoxValue>
            </PropertyDetailsBox>
          )}
          {propertyType !== 'wohnung' && (
            <PropertyDetailsBox>
              <PropertyDetailsBoxKey>
                {t('suggested-property.details.area')}
              </PropertyDetailsBoxKey>
              <PropertyDetailsBoxValue>
                {landArea} m<sup>2</sup>
              </PropertyDetailsBoxValue>
            </PropertyDetailsBox>
          )}

          {isLoggedIn && (
            <CardFooter isListView={isListView}>
              {isRequestedForExpose && (
                <ExposeButtonsBox>
                  <RequestExposeButton
                    label={t('offer-price.purchase-interest')}
                    icon={Basket}
                    iconStyle={{
                      width: 16,
                      height: 16,
                      marginRight: 8,
                    }}
                    onClick={() => setIsOpen(true)}
                    style={{ width: '100%' }}
                  />
                  {isUnlocked ? (
                    <ActionButton
                      onClick={onClickRequestExpose}
                      label={t(
                        'search-profile.suggested-properties.expose-details'
                      )}
                      icon={CommonFileText}
                      color={themeContext.blue}
                      borderColor={themeContext.blue}
                      iconStyle={{
                        width: 16,
                        height: 16,
                        marginRight: 8,
                      }}
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <ActionButtonTooltipBox>
                      <Tooltip
                        id={`request-expose-${propertyId}`}
                        content={
                          <ActionButton
                            onClick={onClickRequestExpose}
                            label={t(
                              'search-profile.suggested-properties.expose-details'
                            )}
                            isDisabled
                            icon={CommonFileText}
                            color={themeContext.blue}
                            borderColor={themeContext.blue}
                            iconStyle={{
                              width: 16,
                              height: 16,
                              marginRight: 8,
                            }}
                            style={{ width: '100%' }}
                          />
                        }
                      >
                        <ToolTipBasicContent
                          content={t(
                            'search-profile.suggested-properties.expose-requested.tooltip'
                          )}
                        />
                      </Tooltip>
                    </ActionButtonTooltipBox>
                  )}
                </ExposeButtonsBox>
              )}
              {!isRequestedForExpose && (
                <>
                  <RequestExposeButton
                    label={t(
                      'search-profile.suggested-properties.request-expose.button'
                    )}
                    icon={CommonFileText}
                    iconStyle={{
                      padding: '3px',
                      margin: '0 8px 0 0',
                    }}
                    onClick={onClickRequestExpose}
                    style={{ width: '100%' }}
                  />
                </>
              )}
            </CardFooter>
          )}

          {isLoggedIn && (
            <ActionButton
              label={t(
                `search-profile.suggested-properties.finance.${
                  financingId ? 'open' : 'start'
                }`
              )}
              icon={Finance}
              iconStyle={{
                padding: '3px',
                margin: '0 8px 0 0',
              }}
              color={themeContext.ctaBlue}
              borderColor={themeContext.ctaBlue}
              onClick={onClickFinancing}
              style={{ width: '100%', marginTop: 16 }}
            />
          )}
        </CardContent>
      </CardContainer>

      <FormPurchaseIntend
        refetch={refetchPropertyPurchaseIntents}
        isOpen={!!isOpen}
        propertyPrice={price}
        propertyId={exposeRequestId}
        onClose={() => setIsOpen(false)}
      />
    </CardWrapper>
  );
};

const SuggestedPropertyCard = memo(SuggestedProperty);

export { SuggestedPropertyCard };
