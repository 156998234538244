import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  HeadTitle,
  SecondaryTitle,
  Text,
  Title,
  Content,
  Row,
  Footer,
  SkipButton,
  NextButton,
  PhoneWrapper,
} from './mfa-modal-styles';
import Icon from 'modules/common/components/ui/icon';

import { ReactComponent as Sms } from 'assets/streamline-light/messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-typing.svg';
import { MfaCodeInput } from './mfa-code-input';
import MfaContext from './mfa-context';
import { MfaSuccess } from './mfa-success';
import { useDispatch } from 'react-redux';
import { toggleIsMfaModalOpen } from 'modules/auth/redux/authSlice';
import { InputOutsideTopLabelWithSelect } from 'modules/common/components/form';
import { FormProvider, useForm } from 'react-hook-form';
import { phonePrefixes } from 'modules/auth/constants';
import { useGetCountry } from 'modules/localization/get-country';
import {
  useSmsRequestTotpMutation,
  useSmsValidateTotpMutation,
} from 'generated';
import { useAppSelector } from 'modules/common/hooks';

export const MfaSms = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { phonePrefix } = useGetCountry();
  const methods = useForm<{
    phone: string;
    phonePrefix: string;
  }>();

  const {
    setType,
    isSuccess,
    setIsSuccess,
    code,
    isFinalStep,
    setIsFinalStep,
    setSmsExpires,
    setBackupCodes,
  } = useContext(MfaContext);

  const [error, setError] = useState<boolean>();
  const [backupCodesHandled, setBackupCodesHandled] = useState<boolean>();

  const [isPhoneSent, setIsPhoneSent] = useState<boolean>();

  const handleSwitchToApp = useCallback(() => {
    setType?.('app');
  }, [setType]);

  const accessToken = useAppSelector((state) => state.auth.accessToken);

  const [smsRequestTotp, smsRequestTotpData] = useSmsRequestTotpMutation();
  const [smsValidateTotp, smsValidateTotpData] = useSmsValidateTotpMutation();

  const phone = methods.watch('phone');
  const currPhonePrefix = methods.watch('phonePrefix');
  const resendTo = useRef<string>();

  const handleNext = () => {
    if (!isPhoneSent && currPhonePrefix && phone) {
      const smsTo = `+${currPhonePrefix
        .toString()
        .replaceAll(/\D/g, '')}${phone}`;

      smsRequestTotp({
        phone: smsTo,
      });
      setIsPhoneSent(true);
      setSmsExpires?.(60);

      resendTo.current = smsTo;
    }

    if (isPhoneSent && !isSuccess && code && accessToken) {
      smsValidateTotp({
        input: { userToken: code, withBackupCodes: true },
        accessToken,
      });
    }

    if (isSuccess) {
      setIsFinalStep?.(true);
    }
  };

  useEffect(() => {
    if (smsValidateTotpData.isSuccess && smsValidateTotpData.data) {
      const { isValid, backupCodes } = smsValidateTotpData.data.smsValidateTotp;
      if (!(isValid && backupCodes)) {
        setError(true);
        return;
      }

      setError(false);
      setIsSuccess?.(true);
      setBackupCodes?.(backupCodes);
    }
  }, [smsValidateTotpData]);

  const handleClose = useCallback(() => {
    dispatch(toggleIsMfaModalOpen(false));
  }, [dispatch]);

  const selectOptions = useMemo(() => {
    return phonePrefixes.includes(phonePrefix)
      ? phonePrefixes
      : [phonePrefix, ...phonePrefixes];
  }, [phonePrefixes, phonePrefix]);

  useEffect(() => {
    setError(false);
  }, [code]);

  return (
    <Content>
      {!isFinalStep && (
        <>
          <HeadTitle style={{ justifySelf: 'center', gap: 24 }}>
            <Icon icon={Sms} width={32} height={32} color={themeContext.blue} />
            <Title content={t('auth.mfa.sms.title')} />
          </HeadTitle>
          {!isSuccess ? (
            <>
              <Row isCentered>
                <SecondaryTitle content={t('auth.mfa.sms.phone-number')} />
              </Row>

              <Row isCentered>
                <PhoneWrapper>
                  <FormProvider {...methods}>
                    <InputOutsideTopLabelWithSelect
                      type="number"
                      name="phone"
                      label=""
                      noLabel
                      placeholder=""
                      rules={{
                        valueAsNumber: true,
                      }}
                      selectWidth="87px"
                      hasSelect
                      selectOptions={selectOptions}
                      selectValue={phonePrefix}
                      selectName="phonePrefix"
                      selectClassName="phone-select"
                      inputClassName="phone-input"
                      isRedesign
                      isDisabled={isPhoneSent}
                    />
                  </FormProvider>
                </PhoneWrapper>
              </Row>

              <Text content={t('auth.mfa.sms.text')} allowHtml isCompact />

              {isPhoneSent && (
                <Row style={{ flexDirection: 'column', gap: 16 }}>
                  <MfaCodeInput
                    hasError={error}
                    setHasError={setError}
                    resendTo={resendTo.current}
                  />
                </Row>
              )}
            </>
          ) : (
            <MfaSuccess onHandleCodes={setBackupCodesHandled} />
          )}

          <Footer>
            {!isSuccess ? (
              <>
                <SkipButton
                  style={{ padding: '12px 24px' }}
                  onClick={handleSwitchToApp}
                >
                  {t(`auth.mfa.sms.verify-via-app`)}
                </SkipButton>
                <NextButton
                  label={t(`auth.mfa.sms.confirm-code`)}
                  onClick={handleNext}
                  isDisabled={isPhoneSent && (code?.length !== 6 || !code)}
                />
              </>
            ) : (
              <NextButton
                label={t(`auth.mfa.next`)}
                onClick={handleNext}
                isDisabled={!backupCodesHandled}
              />
            )}
          </Footer>
        </>
      )}

      {isFinalStep && (
        <>
          <HeadTitle style={{ justifySelf: 'center', gap: 24 }}>
            <Icon icon={Sms} width={32} height={32} color={themeContext.blue} />
            <Title content={t('auth.mfa.sms.success-title')} />
          </HeadTitle>

          <Text content={t('auth.mfa.final-text')} />

          <Footer style={{ justifyContent: 'center' }}>
            <NextButton label={t(`auth.mfa.close`)} onClick={handleClose} />
          </Footer>
        </>
      )}
    </Content>
  );
};
