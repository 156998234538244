import { PortalType } from '../../routes/site-map';

export const locales = [
  { locale: 'de-de', hrefLang: 'de-DE' },
  { locale: 'de-ch', hrefLang: 'de-CH' },
  { locale: 'de-at', hrefLang: 'de-AT' },
  { locale: 'de-de', hrefLang: 'x-default' },
];

export enum CountryCodes {
  GERMANY = 'de',
  AUSTRIA = 'at',
  SWITZERLAND = 'ch',
  SPAIN = 'es',
  ITALY = 'it',
  DENMARK = 'dk',
  POLAND = 'pl',
  CZECH_REPUBLIC = 'cz',
  LIECHTENSTEIN = 'li',
  LUXEMBOURG = 'lu',
  FRANCE = 'fr',
  BELGIUM = 'be',
  NETHERLANDS = 'nl',
  SWEDEN = 'se',
  AUSTRALIA = 'au',
  UNITED_STATES = 'us',
  CHINA = 'cn',
  JAPAN = 'jp',
  INDIA = 'in',
  UNITED_KINGDOM = 'uk',
  BRAZIL = 'br',
  RUSSIA = 'ru',
  SOUTH_KOREA = 'kr',
  MEXICO = 'mx',
  INDONESIA = 'id',
  SAUDI_ARABIA = 'sa',
  TURKEY = 'tr',
  TAIWAN = 'tw',
  THAILAND = 'th',
}

enum Countries {
  germany = 'germany',
  austria = 'austria',
  switzerland = 'switzerland',
  spain = 'spain',
  italy = 'italy',
  denmark = 'denmark',
  poland = 'poland',
  czechRepublic = 'czechRepublic',
  liechtenstein = 'liechtenstein',
  luxembourg = 'luxembourg',
  france = 'france',
  belgium = 'belgium',
  netherlands = 'netherlands',
  sweden = 'sweden',
  australia = 'australia',
  unitedStates = 'unitedStates',
  china = 'china',
  japan = 'japan',
  india = 'india',
  unitedKingdom = 'unitedKingdom',
  brazil = 'brazil',
  russia = 'russia',
  southKorea = 'southKorea',
  mexico = 'mexico',
  indonesia = 'indonesia',
  saudiArabia = 'saudiArabia',
  turkey = 'turkey',
  taiwan = 'taiwan',
  thailand = 'thailand',
}

type Country = {
  phonePrefix: string;
  languagesCountry: string[];
  languages: string[];
  defaultLanguage: string;
  countryCode: string;
  supportedPortals: PortalType[];
};

export const localizations: Record<Countries, Country> = {
  germany: {
    phonePrefix: '+49',
    languagesCountry: ['de-de'],
    languages: ['de'],
    defaultLanguage: 'de',
    countryCode: 'de',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  austria: {
    phonePrefix: '+43',
    languagesCountry: ['de-at'],
    languages: ['de'],
    defaultLanguage: 'de',
    countryCode: 'at',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  switzerland: {
    phonePrefix: '+41',
    languagesCountry: ['de-ch', 'fr-ch', 'it-ch'],
    languages: ['de', 'fr', 'it'],
    defaultLanguage: 'de',
    countryCode: 'ch',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  spain: {
    phonePrefix: '+34',
    languagesCountry: ['es-es'],
    languages: ['es'],
    defaultLanguage: 'es',
    countryCode: 'es',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  italy: {
    phonePrefix: '+39',
    languagesCountry: ['it-it'],
    languages: ['it'],
    defaultLanguage: 'it',
    countryCode: 'it',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Financing,
      PortalType.Seller,
    ],
  },
  denmark: {
    phonePrefix: '+45',
    languagesCountry: ['dk-dk'],
    languages: ['dk'],
    defaultLanguage: 'dk',
    countryCode: 'dk',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Financing,
      PortalType.Seller,
    ],
  },
  poland: {
    phonePrefix: '+48',
    languagesCountry: ['pl-pl'],
    languages: ['pl'],
    defaultLanguage: 'pl',
    countryCode: 'pl',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Financing,
      PortalType.Seller,
    ],
  },
  czechRepublic: {
    phonePrefix: '+420',
    languagesCountry: ['cz-cz'],
    languages: ['cz'],
    defaultLanguage: 'cz',
    countryCode: 'cz',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Financing,
      PortalType.Seller,
    ],
  },
  liechtenstein: {
    phonePrefix: '+423',
    languagesCountry: ['li-li', 'de-li'],
    languages: ['li', 'de'],
    defaultLanguage: 'li',
    countryCode: 'li',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  luxembourg: {
    phonePrefix: '+352',
    languagesCountry: ['lu-lu', 'de-lu', 'fr-lu'],
    languages: ['lu', 'de', 'fr'],
    defaultLanguage: 'fr',
    countryCode: 'lu',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  france: {
    phonePrefix: '+33',
    languagesCountry: ['fr-fr'],
    languages: ['fr'],
    defaultLanguage: 'fr',
    countryCode: 'fr',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  belgium: {
    phonePrefix: '+32',
    languagesCountry: ['be-be', 'be-de'],
    languages: ['be', 'de'],
    defaultLanguage: 'be',
    countryCode: 'be',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  netherlands: {
    phonePrefix: '+31',
    languagesCountry: ['nl-nl'],
    languages: ['nl'],
    defaultLanguage: 'nl',
    countryCode: 'nl',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  sweden: {
    phonePrefix: '+46',
    languagesCountry: ['se-se'],
    languages: ['se'],
    defaultLanguage: 'se',
    countryCode: 'se',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  australia: {
    phonePrefix: '+61',
    languagesCountry: ['en-au'],
    languages: ['en'],
    defaultLanguage: 'en',
    countryCode: 'au',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  unitedStates: {
    phonePrefix: '+1',
    languagesCountry: ['en-us', 'en-ca'],
    languages: ['en'],
    defaultLanguage: 'en',
    countryCode: 'us',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  china: {
    phonePrefix: '+86',
    languagesCountry: ['cn-cn'],
    languages: ['cn'],
    defaultLanguage: 'cn',
    countryCode: 'cn',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  japan: {
    phonePrefix: '+81',
    languagesCountry: ['jp-jp'],
    languages: ['jp'],
    defaultLanguage: 'jp',
    countryCode: 'jp',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  india: {
    phonePrefix: '+91',
    languagesCountry: ['in-in'],
    languages: ['in'],
    defaultLanguage: 'in',
    countryCode: 'in',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  unitedKingdom: {
    phonePrefix: '+44',
    languagesCountry: ['en-uk'],
    languages: ['en'],
    defaultLanguage: 'en',
    countryCode: 'uk',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  brazil: {
    phonePrefix: '+55',
    languagesCountry: ['br-pt'],
    languages: ['pt'],
    defaultLanguage: 'pt',
    countryCode: 'br',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  russia: {
    phonePrefix: '+7',
    languagesCountry: ['ru-ru'],
    languages: ['ru'],
    defaultLanguage: 'ru',
    countryCode: 'ru',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  southKorea: {
    phonePrefix: '+82',
    languagesCountry: ['kr-kr'],
    languages: ['kr'],
    defaultLanguage: 'kr',
    countryCode: 'kr',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  mexico: {
    phonePrefix: '+52',
    languagesCountry: ['es-mx'],
    languages: ['mx'],
    defaultLanguage: 'mx',
    countryCode: 'mx',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  indonesia: {
    phonePrefix: '+62',
    languagesCountry: ['id-id'],
    languages: ['id'],
    defaultLanguage: 'id',
    countryCode: 'id',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  saudiArabia: {
    phonePrefix: '+966',
    languagesCountry: ['sa-sa'],
    languages: ['sa'],
    defaultLanguage: 'sa',
    countryCode: 'sa',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  turkey: {
    phonePrefix: '+90',
    languagesCountry: ['tr-tr'],
    languages: ['tr'],
    defaultLanguage: 'tr',
    countryCode: 'tr',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  taiwan: {
    phonePrefix: '+886',
    languagesCountry: ['tw-tw'],
    languages: ['tw'],
    defaultLanguage: 'tw',
    countryCode: 'tw',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
  thailand: {
    phonePrefix: '+66',
    languagesCountry: ['th-th'],
    languages: ['th'],
    defaultLanguage: 'th',
    countryCode: 'th',
    supportedPortals: [
      PortalType.Owner,
      PortalType.Buyer,
      PortalType.Seller,
      PortalType.Financing,
    ],
  },
};

export enum PhonePrefixes {
  GERMANY = '+49',
  AUSTRIA = '+43',
  SWITZERLAND = '+41',
  SPAIN = '+34',
  ITALY = '+39',
  DENMARK = '+45',
  POLAND = '+48',
  CZECH_REPUBLIC = '+420',
  LIECHTENSTEIN = '+423',
  LUXEMBOURG = '+352',
  FRANCE = '+33',
  BELGIUM = '+32',
  NETHERLANDS = '+31',
  SWEDEN = '+46',
  AUSTRALIA = '+61',
  UNITED_STATES = '+1',
  CHINA = '+86',
  JAPAN = '+81',
  INDIA = '+91',
  UNITED_KINGDOM = '+44',
  BRAZIL = '+55',
  RUSSIA = '+7',
  SOUTH_KOREA = '+82',
  MEXICO = '+52',
  INDONESIA = '+62',
  SAUDI_ARABIA = '+966',
  TURKEY = '+90',
  TAIWAN = '+886',
  THAILAND = '+66',
}

//  ISO 639-1
// country with languages
export const allSupportedLanguages = Object.values(localizations)
  .reduce((acc: string[], country) => [...country.languagesCountry, ...acc], [])
  .map((item) => item.split('-')[0]);

export const supportedLanguagesCountryOwnerPortal = Object.values(localizations)
  .filter((item) => item.supportedPortals.includes(PortalType.Owner))
  .reduce((acc: string[], item) => [...item.languagesCountry, ...acc], []);

export const supportedLanguagesCountryBuyerPortal = Object.values(localizations)
  .filter((item) => item.supportedPortals.includes(PortalType.Buyer))
  .reduce((acc: string[], item) => [...item.languagesCountry, ...acc], []);

export const supportedLanguagesCountrySellerPortal = Object.values(
  localizations
)
  .filter((item) => item.supportedPortals.includes(PortalType.Seller))
  .reduce((acc: string[], item) => [...item.languagesCountry, ...acc], []);

export const supportedLanguagesCountryFinancingPortal = Object.values(
  localizations
)
  .filter((item) => item.supportedPortals.includes(PortalType.Financing))
  .reduce((acc: string[], item) => [...item.languagesCountry, ...acc], []);

const languagesSet = new Set(
  Object.values(localizations).reduce(
    (acc: string[], { languages: langs }) => [...langs, ...acc],
    []
  )
);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const languages = [...languagesSet];

export const getCurrencyByLocale = (locale: string): string => {
  switch (locale) {
    case 'de-de': // Germany
    case 'de-at': // Austria
      return 'EUR';
    case 'de-ch': // Switzerland
      return 'CHF';
    default:
      return 'EUR'; // Default currency
  }
};
