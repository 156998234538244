import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { HeadTitle, SecondaryTitle, Text } from './mfa-modal-styles';
import Icon from 'modules/common/components/ui/icon';

import { ReactComponent as Check } from 'assets/streamline-light/interface-essential/form-validation/check-double-1.svg';
import { ReactComponent as Copy } from 'assets/streamline-light/design/pathfinder/pathfinder-divide.svg';
import { copyToClipboard } from './utils';

interface IProps {
  code: string;
}

export const MfaCopyCode = ({ code }: IProps) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const [copied, setCopied] = useState<boolean>();

  const handleClick = useCallback(() => {
    copyToClipboard(code);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [code]);

  return (
    <>
      {!copied ? (
        <HeadTitle style={{ justifySelf: 'center', gap: '32px' }}>
          <SecondaryTitle content={code} />
          <Icon
            icon={Copy}
            width={24}
            height={24}
            color={themeContext.blue}
            style={{ cursor: 'pointer' }}
            onClick={handleClick}
          />
        </HeadTitle>
      ) : (
        <HeadTitle
          style={{
            justifySelf: 'center',
            textTransform: 'uppercase',
          }}
        >
          <Text
            content={t('auth.mfa.code-copied')}
            color={themeContext.green}
          />
          <Icon
            icon={Check}
            width={24}
            height={24}
            color={themeContext.green}
          />
        </HeadTitle>
      )}
    </>
  );
};
