import { createContext } from 'react';
import { MfaAfterLoginPage, MfaType } from './mfa-interfaces';

interface IContext {
  page: MfaAfterLoginPage;
  setPage: (newValue: MfaAfterLoginPage) => void;
  type: MfaType;
  setType: (newValue: MfaType) => void;
  code: string;
  setCode: (newValue: string) => void;
  resendSmsCode: () => void;
  smsExpires: number;
  setSmsExpires: (newValue: number) => void;
  onClose: () => void;
}

const MfaAfterLoginContext = createContext<Partial<IContext>>({});

export default MfaAfterLoginContext;
