import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Icon from '../../../../../common/components/ui/icon';
import { useAppSelector } from '../../../../../common/hooks';
import {
  setIsShadowMapExpanded,
  setIsShadowMapSettingsOpen,
} from '../../redux/exposeSlice';
import {
  ShadowmapContainer,
  ShadowmapIframe,
  AddressBarButton,
  ShadowmapExpandButton,
  AddressBar,
  AddressBarItemsBox,
  AddressBarTitle,
  AddressBarText,
} from './expose-shadowmap-styles';
import {
  useExposeDetailsHeadInfo,
  useExposeDetailsPriceHubble,
} from '../../hooks/useExposeDetailsQueries';
import { ReactComponent as Arrow } from 'assets/streamline-light/arrows-diagrams/arrows/arrow-button-left-1.svg';
import { ReactComponent as Pin } from 'assets/streamline-light/maps-navigation/pins/pin.svg';

const ExposeShadowmap: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isShadowmapExpanded = useAppSelector(
    (state) => state.exposeDetails.isShadowmapExpanded
  );
  const { location } = useExposeDetailsPriceHubble();

  const {
    headInfo: { fullAddress },
  } = useExposeDetailsHeadInfo();

  const settingsOpenHandler = useCallback(
    (event) => {
      if (event.data && event.data.type === 'shadowmap:settingsActive') {
        const { visible } = event.data;
        dispatch(setIsShadowMapSettingsOpen(visible));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener('message', settingsOpenHandler);

    return () => {
      window.removeEventListener('message', settingsOpenHandler);
    };
  }, []);

  const onRoute = () => {
    window.open(
      `https://maps.google.com/?q=${location?.coordinates?.latitude || 0},${
        location?.coordinates?.longitude || 0
      }`,
      '_blank'
    );
  };

  const src = `https://vonpoll-exposeoverlay.poweredby.shadowmap.org?zoom=15&azimuth=0&basemap=map&lat=${
    location?.coordinates?.latitude || 0
  }}&lng=${location?.coordinates?.longitude || 0}`;

  return (
    <ShadowmapContainer isShadowmapExpanded={isShadowmapExpanded}>
      <ShadowmapIframe
        src={src}
        title="Shadowmap Von Poll Buyer Seller Expose Overlay Integration"
      />
      <ShadowmapExpandButton
        onClick={() => dispatch(setIsShadowMapExpanded(!isShadowmapExpanded))}
      >
        <Icon
          icon={Arrow}
          style={isShadowmapExpanded ? { transform: 'rotate(180deg)' } : {}}
          width={16}
          height={16}
          color={'#ffffff'}
        />
      </ShadowmapExpandButton>

      <AddressBar>
        <AddressBarItemsBox>
          <Icon icon={Pin} width={24} height={24} color={'#00305E'} />
          <div>
            <AddressBarTitle>{t('your.address')}</AddressBarTitle>
            <AddressBarText>{fullAddress}</AddressBarText>
          </div>
        </AddressBarItemsBox>
        <AddressBarButton onClick={onRoute} label={t('route.button.text')} />
      </AddressBar>
    </ShadowmapContainer>
  );
};

export default ExposeShadowmap;
