import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { ReactComponent as SingleNeutral } from 'assets/streamline-light/users/natural-close-up-single-user-neutral/single-neutral.svg';
import { identity, omit, pickBy } from 'lodash';
import { Ref, useCallback, useContext, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { ThemeContext } from 'styled-components';

import {
  useConfirmPhoneChangeMutation,
  useConfirmPhoneMutation,
  useRemoveUserAccountMutation,
  useResendPhoneCodeAfterChangeMutation,
  UserState,
  useUpdateUserProfileMutation,
} from '../../../../../generated';
import {
  Checkbox,
  InputOutsideTopLabel,
  InputOutsideTopLabelWithSelect,
  InputPassOutsideTopLabel,
} from '../../../../common/components/form';
import { GlobalError } from '../../../../common/components/form/error/global-error';
import {
  FormColumn,
  FormRow,
} from '../../../../common/components/form/form-grid';
import { Modal, ModalTitle } from '../../../../common/components/modal';
import { SecondaryButton } from '../../../../common/components/ui/buttons';
import Icon from '../../../../common/components/ui/icon';
import { StatusType } from '../../../../common/components/verification-input/types';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { useIsMobileSize } from '../../../../common/hooks/useIsMobileSize';
import { FormTitle } from '../../../../forms/components/common/form-title';
import { EDIT_PROFILE_FORM_KEYS, phonePrefixes } from '../../../constants';
import { IEditProfile, InputType } from '../../../interfaces';
import {
  logout,
  setUser,
  setUserState,
  toggleisEditProfileModalOpen,
} from '../../../redux/authSlice';
import { PhoneVerification } from '../../forms/phone-verification';
import {
  IconContainer,
  Header,
  Section,
  SectionTitle,
  SectionLink,
  SectionText,
  Form,
  TooltipHeader,
  TooltipAccept,
  TooltipBack,
  StyledModalFooter,
  SubmitButton,
  CheckboxesContainer,
  SectionSubtitle,
} from './profile-edit-modal-styles';

interface IProfileEditModal {
  isOpen: boolean;
  isRegistrationStep?: boolean;
  onSuccessSubmit?: () => void;
}

const ProfileEditModal = ({
  isOpen,
  isRegistrationStep,
  onSuccessSubmit,
}: IProfileEditModal): JSX.Element => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();
  const methods = useForm<IEditProfile>();
  const themeContext = useContext(ThemeContext);

  const user = useAppSelector((state) => state.auth.user);
  const userState = useAppSelector((state) => state.auth.userState);

  const tooltipRef = useRef<
    (ReactTooltip & { tooltipRef: Ref<HTMLElement> }) | null
  >(null);

  const [verificationStatus] = useState(StatusType.process);

  const [
    updateUserProfile,
    { isLoading: isUserProfileUpdateLoading, error: updateUserProfileError },
  ] = useUpdateUserProfileMutation();
  const [confirmPhoneChange, { error: confirmPhoneChangeError }] =
    useConfirmPhoneChangeMutation();
  const [confirmPhone] = useConfirmPhoneMutation();
  const [resendPhoneCodeAfterChange] = useResendPhoneCodeAfterChangeMutation();
  const [removeUserAccount] = useRemoveUserAccountMutation();

  const onClose = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      batch(() => {
        dispatch(toggleisEditProfileModalOpen(false));
      });
      if (onSuccessSubmit) {
        onSuccessSubmit();
      }
    },
    [dispatch, onSuccessSubmit]
  );

  const onSubmit = async (formData: IEditProfile) => {
    const input = {
      ...pickBy(
        omit(formData, [
          'phonePrefix',
          'phone',
          'password',
          'confirmPassword',
          'isNotifyPriceChange',
          'isNotifyNewProperties',
          'isNotifyNewPropertiesSummary',
          'isNotifyPaymentOfInterestMonthly',
          'isNotifyGeneralNewsletter',
          'isNotifyMarketPrice',
          'isNotifyFinancingInterestRates',
          'isNotifyEvaluationUpdates',
        ]),
        identity
      ),
      prefix: `${formData.phonePrefix}`,
      phone: `+${formData.phonePrefix}${formData.phone}`,
      newPassword: formData.password,
      notifications: {
        isNotifyPriceChange: formData.isNotifyPriceChange,
        isNotifyNewProperties: formData.isNotifyNewProperties,
        isNotifyNewPropertiesSummary: formData.isNotifyNewPropertiesSummary,
        isNotifyPaymentOfInterestMonthly:
          formData.isNotifyPaymentOfInterestMonthly,
        isNotifyGeneralNewsletter: formData.isNotifyGeneralNewsletter,
        isNotifyMarketPrice: formData.isNotifyMarketPrice,
        isNotifyFinancingInterestRates: formData.isNotifyFinancingInterestRates,
        isNotifyEvaluationUpdates: formData.isNotifyEvaluationUpdates,
      },
    };
    try {
      const updateUserProfileResponse = await updateUserProfile({
        input,
      }).unwrap();
      if (
        updateUserProfileResponse?.updateUserProfile.userState ===
        UserState.Verified
      ) {
        dispatch(setUser(updateUserProfileResponse?.updateUserProfile));
        onClose();
      }
      if (
        updateUserProfileResponse?.updateUserProfile.userState ===
        UserState.VerifyPhone
      ) {
        batch(() => {
          dispatch(setUser(updateUserProfileResponse?.updateUserProfile));
          dispatch(
            setUserState(updateUserProfileResponse?.updateUserProfile.userState)
          );
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onVerifyPhone = useCallback(
    async (confirmationCode: string) => {
      if (user?._id) {
        if (!isRegistrationStep) {
          const verifyPhoneResponse = await confirmPhoneChange({
            input: {
              confirmationCode,
              _id: user?._id,
            },
          }).unwrap();

          if (verifyPhoneResponse?.confirmPhoneChange) {
            batch(() => {
              dispatch(setUser(verifyPhoneResponse?.confirmPhoneChange));
              dispatch(
                setUserState(verifyPhoneResponse?.confirmPhoneChange.userState)
              );
              onClose();
            });
          }
        } else {
          const confirmPhoneResult = await confirmPhone({
            input: {
              confirmationCode,
              username: user?.username,
            },
          }).unwrap();

          if (confirmPhoneResult?.confirmPhone) {
            dispatch(setUserState(confirmPhoneResult.confirmPhone));
          }
          dispatch(toggleisEditProfileModalOpen(false));
          if (onSuccessSubmit) {
            onSuccessSubmit();
          }
        }
      }
    },
    [
      confirmPhone,
      confirmPhoneChange,
      dispatch,
      isRegistrationStep,
      onClose,
      onSuccessSubmit,
      user?._id,
      user?.username,
    ]
  );

  const onResendConfirmationCode = useCallback(async () => {
    if (user?.username) {
      await resendPhoneCodeAfterChange({ phone: user?.phone });
    }
  }, [resendPhoneCodeAfterChange, user?.phone, user?.username]);

  const onChangePhone = () => {
    dispatch(setUserState(UserState.Verified));
  };

  const onDeleteAccount = useCallback(async () => {
    if (user?.username) {
      const deleteResponse = await removeUserAccount({
        username: user?.username,
      }).unwrap();
      if (deleteResponse) {
        dispatch(logout());
      }
    }
  }, [dispatch, user?.username, removeUserAccount]);

  // eslint-disable-next-line unicorn/prefer-string-slice, @typescript-eslint/restrict-plus-operands
  const phone = user?.phone?.substr((user?.prefix.length ?? 0) + 1);

  const selectOptions =
    user?.prefix &&
    !phonePrefixes.includes(user?.prefix) &&
    !phonePrefixes.includes(`+${user?.prefix}`)
      ? [
          user?.prefix.startsWith('+') ? user?.prefix : `+${user?.prefix}`,
          ...phonePrefixes,
        ]
      : phonePrefixes;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      {...(isMobileSize && {
        wrapperStyles: {
          width: '100%',
          height: '100%',
          padding: 0,
          maxWidth: '100%',
          overflowY: 'auto',
        },
        overlayStyles: {
          overflow: 'hidden',
        },
      })}
    >
      <Header>
        <ModalTitle
          icon={isMobileSize ? undefined : SingleNeutral}
          text={t('profile-edit.title')}
          textColor={isMobileSize ? 'white' : undefined}
          iconPosition={isMobileSize ? undefined : 'left'}
          textAlign={isMobileSize ? 'center' : 'left'}
          margin="0"
        />
        {isMobileSize && (
          <IconContainer onClick={onClose}>
            <Icon
              icon={Close}
              width={16}
              height={16}
              color={themeContext.white}
            />
          </IconContainer>
        )}
      </Header>
      {userState !== UserState.VerifyPhone && (
        <FormProvider {...methods}>
          {updateUserProfileError && (
            <GlobalError
              title={t(
                updateUserProfileError?.message
                  ?.split(':')?.[0]
                  .toLowerCase() ?? ''
              )}
            />
          )}
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Section>
              <SectionTitle>
                {t('profile-edit.personal-data.section-title')}
              </SectionTitle>

              <FormRow>
                <FormColumn>
                  <InputOutsideTopLabel
                    name={EDIT_PROFILE_FORM_KEYS.NAME}
                    type={InputType.text}
                    label="profile-edit.input.label.name"
                    placeholder="profile-edit.input.placeholder.name"
                    rules={{}}
                    defaultValue={user?.name}
                  />
                </FormColumn>

                <FormColumn>
                  <InputOutsideTopLabel
                    name={EDIT_PROFILE_FORM_KEYS.SURNAME}
                    type={InputType.text}
                    label="profile-edit.input.label.surname"
                    placeholder="profile-edit.input.placeholder.surname"
                    rules={{}}
                    defaultValue={user?.surname}
                  />
                </FormColumn>
              </FormRow>

              <FormRow>
                <FormColumn>
                  <InputOutsideTopLabelWithSelect
                    type={InputType.number}
                    name="phone"
                    label="profile-edit.input.label.phone"
                    placeholder="profile-edit.placeholder.phone.label"
                    rules={{
                      valueAsNumber: true,
                    }}
                    selectWidth="87px"
                    hasSelect
                    defaultValue={phone}
                    selectOptions={selectOptions}
                    selectValue={`+${user?.prefix}`}
                    selectName={EDIT_PROFILE_FORM_KEYS.PHONE_PREFIX}
                  />
                </FormColumn>
              </FormRow>
            </Section>

            <Section>
              <SectionTitle>
                {t('profile-edit.login-data.section-title')}
              </SectionTitle>
              <FormRow>
                <FormColumn>
                  <InputOutsideTopLabel
                    isDisabled
                    type={InputType.text}
                    name={EDIT_PROFILE_FORM_KEYS.EMAIL}
                    label="profile-edit.input.label.email"
                    placeholder="profile-edit.input.placeholder.email"
                    rules={{}}
                    defaultValue={user?.email}
                  />
                </FormColumn>

                <FormColumn>
                  <InputPassOutsideTopLabel
                    type={InputType.password}
                    name={EDIT_PROFILE_FORM_KEYS.OLD_PASSWORD}
                    label="profile-edit.input.label.old-password"
                    placeholder="profile-edit.input.placeholder.old-password"
                    rules={{
                      minLength: {
                        value: 10,
                        message: 'error.password.did.not.conform.with.policy',
                      },
                    }}
                  />
                </FormColumn>
              </FormRow>

              <FormRow>
                <FormColumn>
                  <InputPassOutsideTopLabel
                    type={InputType.password}
                    name={EDIT_PROFILE_FORM_KEYS.PASSWORD}
                    label="profile-edit.input.label.password"
                    placeholder="profile-edit.input.placeholder.password"
                    rules={{
                      minLength: {
                        value: 10,
                        message: 'error.password.did.not.conform.with.policy',
                      },
                    }}
                    passwordMasterValue={methods.watch('password')}
                  />
                </FormColumn>

                <FormColumn>
                  <InputPassOutsideTopLabel
                    type={InputType.password}
                    name={EDIT_PROFILE_FORM_KEYS.CONFIRM_PASSWORD}
                    label="profile-edit.input.label.confirm-password"
                    placeholder="profile-edit.input.placeholder.confirm-password"
                    rules={{}}
                    passwordMasterValue={methods.watch('password')}
                    skipPattern
                  />
                </FormColumn>
              </FormRow>
            </Section>

            <Section>
              <SectionTitle>
                {t('profile-edit.notifications-data.section-title')}
              </SectionTitle>
              <SectionSubtitle>
                {t('profile-edit.notifications-data.section-subtitle')}
              </SectionSubtitle>
              <CheckboxesContainer>
                <Checkbox
                  isWithoutErrors
                  name="isNotifyGeneralNewsletter"
                  label={t(
                    'profile-edit.notifications-data.checkbox-label.is-notify-general-newsletter'
                  )}
                  rules={{
                    value: user?.notifications?.isNotifyGeneralNewsletter,
                  }}
                />
                <Checkbox
                  isWithoutErrors
                  name="isNotifyPriceChange"
                  label={t(
                    'profile-edit.notifications-data.checkbox-label.is-notify-price-change'
                  )}
                  rules={{ value: user?.notifications?.isNotifyPriceChange }}
                />
                <Checkbox
                  isWithoutErrors
                  name="isNotifyNewProperties"
                  rules={{ value: user?.notifications?.isNotifyNewProperties }}
                  label={t(
                    'profile-edit.notifications-data.checkbox-label.is-notify-new-properties'
                  )}
                />
                <Checkbox
                  isWithoutErrors
                  name="isNotifyPaymentOfInterestMonthly"
                  label={t(
                    'profile-edit.notifications-data.checkbox-label.is-notify-rate-comment'
                  )}
                  rules={{
                    value:
                      user?.notifications?.isNotifyPaymentOfInterestMonthly,
                  }}
                />
                <Checkbox
                  isWithoutErrors
                  name="isNotifyNewPropertiesSummary"
                  rules={{
                    value: user?.notifications?.isNotifyNewPropertiesSummary,
                  }}
                  label={t(
                    'profile-edit.notifications-data.checkbox-label.is-notify-new-properties-summary'
                  )}
                />
                <Checkbox
                  isWithoutErrors
                  name="isNotifyMarketPrice"
                  label={t(
                    'profile-edit.notifications-data.checkbox-label.is-notify-market-price'
                  )}
                  rules={{
                    value: user?.notifications?.isNotifyMarketPrice,
                  }}
                />
                <Checkbox
                  isWithoutErrors
                  name="isNotifyFinancingInterestRates"
                  label={t(
                    'profile-edit.notifications-data.checkbox-label.is-notify-financing-interest-rates'
                  )}
                  rules={{
                    value:
                      user?.notifications?.isNotifyFinancingInterestRates ??
                      true,
                  }}
                />
                <Checkbox
                  name="isNotifyEvaluationUpdates"
                  label={t(
                    'profile-edit.notifications-data.checkbox-label.is-notify-evaluation-updates'
                  )}
                  rules={{
                    value: user?.notifications?.isNotifyEvaluationUpdates,
                  }}
                />
              </CheckboxesContainer>
            </Section>

            <Section>
              <SectionTitle>
                {t('profile-edit.delete-account.section-title')}
              </SectionTitle>

              <SectionText>
                {t('profile-edit.delete-account.section-text')}
              </SectionText>
              <SectionLink data-tip="tooltip" data-for={'delete-link'}>
                {t('profile-edit.delete-account.link')}
              </SectionLink>
              <ReactTooltip
                ref={tooltipRef}
                place="top"
                event="click"
                id={'delete-link'}
                effect="solid"
                type="light"
                borderColor="#d9d9d9"
                border
                clickable
              >
                <>
                  <TooltipHeader>
                    {t('profile-edit.tooltip.title')}
                  </TooltipHeader>
                  <TooltipBack
                    onClick={() => {
                      if (tooltipRef.current) {
                        // react-tooltip doesn't hide tooltip when mouse on tooltip
                        // https://github.com/wwayne/react-tooltip/blob/master/src/index.js#L646
                        // checking tooltip ref in mouseOnTooltip function
                        // https://github.com/wwayne/react-tooltip/blob/master/src/index.js#L225
                        tooltipRef.current.tooltipRef = null;
                      }
                      ReactTooltip.hide();
                    }}
                  >
                    {t('profile-edit.tooltip.back')}
                  </TooltipBack>
                  <TooltipAccept onClick={onDeleteAccount}>
                    {t('profile-edit.tooltip.accept')}
                  </TooltipAccept>
                </>
              </ReactTooltip>
            </Section>

            <StyledModalFooter>
              {!isMobileSize && (
                <SecondaryButton
                  label={t('profile-edit.form.button.close')}
                  onClick={onClose}
                />
              )}

              <SubmitButton
                label={t('profile-edit.form.button.submit')}
                loader
                isLoading={isUserProfileUpdateLoading}
              />
            </StyledModalFooter>
          </Form>
        </FormProvider>
      )}
      {userState === UserState.VerifyPhone && (
        <>
          <Header>
            <FormTitle text={t('phone-verification.title')} />
          </Header>
          {confirmPhoneChangeError && (
            <GlobalError
              title={t(
                confirmPhoneChangeError?.message
                  ?.split(':')?.[0]
                  .toLowerCase() ?? ''
              )}
            />
          )}
          <PhoneVerification
            phoneNumber={
              user?.phone
                ?.toString()
                ?.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ') ?? ''
            }
            onVerifyPhone={onVerifyPhone}
            status={verificationStatus}
            // setStatus={setVerificationStatus}
            resendCode={onResendConfirmationCode}
            changePhone={onChangePhone}
          />
        </>
      )}
    </Modal>
  );
};

export { ProfileEditModal };
