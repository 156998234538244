import {
  CreateSearchProfileInput,
  PropertyCode,
  SearchProfileData,
} from '../../../generated';
import { Feature } from '../../../services/mapbox/interfaces';
import { isoCountriesGermany } from '../constants';
import { ISearchProfileForm } from '../form-search-profile/interface';
import { parseLocation } from './parse-location';
import { removeNonNumeric } from './number-format';

export enum PropertyName {
  Apartment = 'wohnung',
  House = 'haus',
  MultiFamilyHouse = 'mehrfamilienhaus',
  Plot = 'grundstück',
}

export const getPropertyNameByPropertyCode = (
  code: PropertyCode
): PropertyName | undefined => {
  let name;

  switch (code) {
    case PropertyCode.Apartment:
      name = PropertyName.Apartment;
      break;
    case PropertyCode.House:
      name = PropertyName.House;
      break;
    case PropertyCode.MultiFamilyHouse:
      name = PropertyName.MultiFamilyHouse;
      break;
    case PropertyCode.Plot:
      name = PropertyName.Plot;
      break;
    default:
      break;
  }

  return name;
};

export const getSearchProfileTitle = (
  mapboxCity: string,
  code: PropertyCode
) => {
  let title = mapboxCity;

  const propertyName = getPropertyNameByPropertyCode(code);
  if (propertyName) {
    const formatted = [
      propertyName[0].toUpperCase(),
      propertyName.split('').slice(1).join(''),
    ];
    title = `${formatted}, ${title}`;
  }

  return title;
};

export const parseSearchProfileInput = (
  formData: ISearchProfileForm,
  geoCodingResult: Feature,
  ownerId?: string
): CreateSearchProfileInput => {
  const {
    mapboxLocality,
    mapboxNeighborhood,
    mapboxPostCode,
    mapboxCity,
    mapboxCountry,
    mapboxPlaceName,
    longitude,
    latitude,
  } = parseLocation(geoCodingResult);

  const countryCode =
    Object.keys(isoCountriesGermany).find(
      (key: string) =>
        isoCountriesGermany[key].toLowerCase() === mapboxCountry.toLowerCase()
    ) ?? 'DE';

  const location = {
    coordinates: {
      longitude,
      latitude,
    },
    address: {
      postCode: mapboxPostCode,
      city: mapboxCity,
      locality: mapboxLocality,
      neighborhood: mapboxNeighborhood,
      placeName: mapboxPlaceName,
    },
  };

  const {
    code,

    radius: radiusRaw,
    purchasePriceMin: purchasePriceMinRaw,
    purchasePriceMax: purchasePriceMaxRaw,
    livingAreaMin: livingAreaMinRaw,
    livingAreaMax: livingAreaMaxRaw,
    landAreaMin: landAreaMinRaw,
    landAreaMax: landAreaMaxRaw,
    numberOfRoomsMin: numberOfRoomsMinRaw,
    numberOfRoomsMax: numberOfRoomsMaxRaw,
    numberOfBathroomsMin: numberOfBathroomsMinRaw,
    numberOfBathroomsMax: numberOfBathroomsMaxRaw,
  } = formData;

  let title;
  switch (code) {
    case PropertyCode.Apartment:
      title = `Wohnung, ${mapboxCity}`;
      break;
    case PropertyCode.House:
      title = `Haus, ${mapboxCity}`;
      break;
    case PropertyCode.MultiFamilyHouse:
      title = `Mehrfamilienhaus, ${mapboxCity}`;
      break;
    case PropertyCode.Plot:
      title = `Grundstück, ${mapboxCity}`;
      break;
    default:
      title = mapboxCity;
      break;
  }

  const radius = +radiusRaw;

  const purchasePriceMin = purchasePriceMinRaw
    ? Number(removeNonNumeric(purchasePriceMinRaw))
    : 1;
  const purchasePriceMax = Number(removeNonNumeric(purchasePriceMaxRaw));
  const livingAreaMin = +livingAreaMinRaw;
  const livingAreaMax = livingAreaMaxRaw ? +livingAreaMaxRaw : 800;
  const landAreaMin = +landAreaMinRaw;
  const landAreaMax = landAreaMaxRaw ? +landAreaMaxRaw : 5000;
  const numberOfRoomsMin = +numberOfRoomsMinRaw;
  const numberOfRoomsMax = numberOfRoomsMaxRaw ? +numberOfRoomsMaxRaw : 40;
  const numberOfBathroomsMin = +numberOfBathroomsMinRaw;
  const numberOfBathroomsMax = numberOfBathroomsMaxRaw
    ? +numberOfBathroomsMaxRaw
    : 40;

  let searchProfileData: SearchProfileData = {
    radius,
    propertyCode: code,
    location,
    purchasePriceMin,
    purchasePriceMax,
  };

  if (code !== PropertyCode.Plot) {
    searchProfileData = {
      ...searchProfileData,
      livingAreaMin,
      livingAreaMax,
    };
  }

  if (code === PropertyCode.Plot) {
    searchProfileData = {
      ...searchProfileData,
      landAreaMin,
      landAreaMax,
    };
  }

  if (code === PropertyCode.Apartment || code === PropertyCode.House) {
    searchProfileData = {
      ...searchProfileData,
      numberOfRoomsMin,
      numberOfRoomsMax,
      numberOfBathroomsMin,
      numberOfBathroomsMax,
    };
  }

  return {
    searchProfileData,
    countryCode,
    owner: ownerId || '',
    title,
  };
};
