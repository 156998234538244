import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  HeadTitle,
  SecondaryTitle,
  Text,
  Title,
  Content,
  Row,
  QrPlaceholder,
  Footer,
  SkipButton,
  NextButton,
} from './mfa-modal-styles';
import Icon from 'modules/common/components/ui/icon';

import { ReactComponent as Qr } from 'assets/streamline-light/phones-mobile-devices/qr-code/qr-code-scan.svg';
import { MfaCodeInput } from './mfa-code-input';
import { MfaCopyCode } from './mfa-copy-code';
import MfaContext from './mfa-context';
import { MfaSuccess } from './mfa-success';
import { useDispatch } from 'react-redux';
import { toggleIsMfaModalOpen } from 'modules/auth/redux/authSlice';
import { toDataURL as totpToDataURL } from 'qrcode';
import {
  useCognitoAppAssociateSecretTokenMutation,
  useCognitoAppVerifyTotpMutation,
  useGenerateSecretTokenMutation,
  useValidateTotpMutation,
} from 'generated';
import { useAppSelector, useDeviceDetector } from 'modules/common/hooks';

export const MfaApp = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const {
    setType,
    isSuccess,
    setIsSuccess,
    code,
    isFinalStep,
    setIsFinalStep,
    setBackupCodes,
  } = useContext(MfaContext);

  const [error, setError] = useState<boolean>();
  const [backupCodesHandled, setBackupCodesHandled] = useState<boolean>();

  const { isIos } = useDeviceDetector();
  const links = {
    googleAuth: isIos()
      ? 'https://apps.apple.com/us/app/google-authenticator/id388497605'
      : 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
    authy: isIos()
      ? 'https://apps.apple.com/us/app/twilio-authy/id494168017'
      : 'https://play.google.com/store/apps/details?id=com.authy.authy',
    microsoft: isIos()
      ? 'https://apps.apple.com/ru/app/microsoft-authenticator/id983156458'
      : 'https://play.google.com/store/apps/details?id=com.azure.authenticator',
  };

  const accessToken = useAppSelector((store) => store.auth.accessToken);
  const [generateSecret, generateSecretData] = useGenerateSecretTokenMutation();

  const [cognitoGenerateSecret, cognitoGenerateSecretData] =
    useCognitoAppAssociateSecretTokenMutation();

  console.log({ cognitoGenerateSecretData });

  const twoFaSecretFormatted =
    cognitoGenerateSecretData.data?.cognitoAppAssociateSecretToken.totpUrl &&
    new URL(
      cognitoGenerateSecretData.data?.cognitoAppAssociateSecretToken.totpUrl
    ).searchParams
      ?.get('secret')
      ?.match(/.{1,4}/g)
      ?.join(' - ');
  const [qrCodeSrc, setQrCodeSrc] = useState<string>();

  useEffect(() => {
    if (accessToken) {
      cognitoGenerateSecret({
        accessToken,
      });
    }
  }, []);
  useEffect(() => {
    if (
      cognitoGenerateSecretData.isSuccess &&
      cognitoGenerateSecretData.data?.cognitoAppAssociateSecretToken.totpUrl
    ) {
      totpToDataURL(
        cognitoGenerateSecretData.data.cognitoAppAssociateSecretToken.totpUrl,
        (err, data_url) => {
          console.log({ nextQrCodeSrc: data_url });

          setQrCodeSrc(data_url);
        }
      );
    }
  }, [cognitoGenerateSecretData]);

  const [validateTotp, validateTotpData] = useValidateTotpMutation();
  const [cognitoValidateTotp, cognitoValidateTotpData] =
    useCognitoAppVerifyTotpMutation();

  const handleSwitchToSms = useCallback(() => {
    setType?.('sms');
  }, [setType]);

  const handleNext = useCallback(() => {
    if (!code) return;

    if (!isSuccess && accessToken) {
      // validateTotp({ input: { userToken: code, withBackupCodes: true } });
      cognitoValidateTotp({
        accessToken,
        userCode: code,
      });
    }

    if (isSuccess) {
      setIsFinalStep?.(true);
    }
  }, [setIsSuccess, isSuccess, setError, code]);

  useEffect(() => {
    if (cognitoValidateTotpData.isLoading) {
      setError(false);
    }

    if (cognitoValidateTotpData.isError) {
      setError(true);
    }

    if (cognitoValidateTotpData.isSuccess && cognitoValidateTotpData.data) {
      const { isValid, backupCodes } =
        cognitoValidateTotpData.data.cognitoAppVerifyTotp;
      if (!(isValid && backupCodes)) {
        setError(true);
        return;
      }

      setError(false);
      setIsSuccess?.(true);
      setBackupCodes?.(backupCodes);
    }
  }, [cognitoValidateTotpData]);

  const handleClose = useCallback(() => {
    dispatch(toggleIsMfaModalOpen(false));
  }, [dispatch]);

  useEffect(() => {
    setError(false);
  }, [code]);

  return (
    <Content>
      {!isFinalStep && (
        <>
          <HeadTitle style={{ justifySelf: 'center', gap: 24 }}>
            <Icon icon={Qr} width={32} height={32} color={themeContext.blue} />
            <Title content={t('auth.mfa.app.title')} />
          </HeadTitle>
          {!isSuccess ? (
            <>
              <Row isCentered>
                <SecondaryTitle content={t('auth.mfa.app.scan-code')} />
              </Row>
              <QrPlaceholder>
                <img
                  src={
                    qrCodeSrc ||
                    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCI+PHJlY3Qgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIGZpbGw9IndoaXRlIi8+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjMwIiBoZWlnaHQ9IjMwIiBmaWxsPSJibGFjayIvPjxyZWN0IHg9IjcwIiB5PSIwIiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIGZpbGw9ImJsYWNrIi8+PHJlY3QgeD0iMCIgeT0iNzAiIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIgZmlsbD0iYmxhY2siLz48cmVjdCB4PSI3MCIgeT0iNzAiIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIgZmlsbD0iYmxhY2siLz48L3N2Zz4='
                  }
                  alt="Dummy QR code"
                  width={142}
                  height={142}
                />
              </QrPlaceholder>
              <Text
                content={t('auth.mfa.app.text', {
                  google_auth: `<a href="${links.googleAuth}" target="_blank">Google Authenticator</a>`,
                  authy: `<a href="${links.authy}" target="_blank">Authy</a>`,
                  microsoft: `<a href="${links.microsoft}" target="_blank">Microsoft Authenticator</a>`,
                })}
                allowHtml
                isCompact
              />
              {twoFaSecretFormatted && (
                <MfaCopyCode code={twoFaSecretFormatted} />
              )}
              <MfaCodeInput hasError={error} />
            </>
          ) : (
            <MfaSuccess onHandleCodes={setBackupCodesHandled} />
          )}

          <Footer>
            {!isSuccess ? (
              <>
                <SkipButton
                  style={{ padding: '12px 24px' }}
                  onClick={handleSwitchToSms}
                >
                  {t(`auth.mfa.app.verify-via-sms`)}
                </SkipButton>
                <NextButton
                  label={t(`auth.mfa.app.confirm-code`)}
                  onClick={handleNext}
                  isDisabled={code?.length !== 6 || !code || error}
                />
              </>
            ) : (
              <NextButton
                label={t(`auth.mfa.next`)}
                onClick={handleNext}
                isDisabled={!backupCodesHandled}
              />
            )}
          </Footer>
        </>
      )}

      {isFinalStep && (
        <>
          <HeadTitle style={{ justifySelf: 'center', gap: 24 }}>
            <Icon icon={Qr} width={32} height={32} color={themeContext.blue} />
            <Title content={t('auth.mfa.app.success-title')} />
          </HeadTitle>

          <Text content={t('auth.mfa.final-text')} />

          <Footer style={{ justifyContent: 'center' }}>
            <NextButton
              label={t(`auth.mfa.close`)}
              onClick={handleClose}
              shouldAlignCenter
            />
          </Footer>
        </>
      )}
    </Content>
  );
};
