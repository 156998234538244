import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import Property1 from 'assets/images/icons/search-profile-form/property-1.png';
import Property2 from 'assets/images/icons/search-profile-form/property-2.png';
import { Property, PropertyCode } from '../../../../../../generated';
import { useAppSelector } from '../../../../../common/hooks';
import PropertyCard from './property-card';
import { formatPrice } from 'modules/seller/utils/formatPrice';

const Container = styled.div<{
  isBigger?: boolean;
  isLighter?: boolean;
  isAuthFormStep?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px 0;
  flex: 1;
  height: ${(props) => (props.isBigger ? '357px;' : '291px;')}
  overflow: hidden;
  filter: blur(3px);
  ${(props) =>
    props.isLighter &&
    `
    filter: unset;
    height: ${props.isBigger ? '378px;' : '319px;'}
  `}
  ${(props) =>
    props.isAuthFormStep &&
    `
    height: ${props.isBigger ? '357px;' : '291px;'}
  `}
`;

const Blur = styled.div<{ isBigger?: boolean; isLighter?: boolean }>`
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  z-index: 1;
  left: 0;
  width: 100%;
  bottom: 0;
  height: ${(props) => (props.isBigger ? '385px;' : '319px;')}
    ${(props) =>
      props.isLighter &&
      `
    opacity: 1;
    background: unset;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    opacity: 1;
    bottom: 0;
    height: 64px;
  `};
`;

const DEFAULT_PROPERTIES = [
  {
    title: 'EIGENNUTZER & KAPITALANLEGER AUFGEPASST: Apartment mit kleiner...',
    src: Property1,
    description: '445.000 €, 5 Zimmer, ca. 119.96 m²',
  },
  {
    title: 'TRÈS CHIC - WOHNEN IM FRANZÖSISCHEN VIERTEL IN HAID...',
    src: Property2,
    description: '195.000 €, 3 Zimmer, ca. 95 m²',
  },
  {
    title: 'EIGENNUTZER & KAPITALANLEGER AUFGEPASST: Apartment mit kleiner...',
    src: Property1,
    description: '445.000 €, 5 Zimmer, ca. 119.96 m²',
  },
];

interface IProps {
  address?: string;
  isLighter?: boolean;
  isLoading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: any;
}

const SuggestedProperties = ({
  properties,
  address = '10243 Berlin',
  isLighter,
  isLoading,
}: IProps) => {
  const { watch } = useFormContext();
  const isAuthFormStep = useAppSelector(
    (state) => state.searchProfile.activeStep > 1
  );

  const userInput = useAppSelector((state) => state.searchProfile.userInput);

  const values = watch();

  const formData = isEmpty(values) ? userInput : values;

  const { code } = formData || {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getDescription = (property: any) => {
    const area = property.livingArea || property.landArea;
    if (!(property.price && area)) return null;

    const price = formatPrice(property.price, 'EUR');
    const numberOfRooms =
      [PropertyCode.Apartment, PropertyCode.House].includes(code) &&
      property.numberOfRooms
        ? `${property.numberOfRooms} Zimmer`
        : null;
    const livingArea = `ca. ${area} m²`;

    return [price, numberOfRooms, livingArea].filter(Boolean).join(', ');
  };

  return (
    <>
      <Container
        isLighter={isLighter || isLoading}
        isAuthFormStep={isAuthFormStep}
        isBigger={
          code === PropertyCode.MultiFamilyHouse || code === PropertyCode.Plot
        }
      >
        {(properties?.length > 0 ? properties : DEFAULT_PROPERTIES)
          .slice(0, 3)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((property: any, idx: number) => {
            return (
              <PropertyCard
                {...{
                  ...property,
                  image: property.image && property.image[0].name,
                }}
                description={
                  getDescription(property) ??
                  DEFAULT_PROPERTIES[idx].description
                }
                isLoading={isLoading}
                fallbackImg={DEFAULT_PROPERTIES[idx].src}
                location={address}
              />
            );
          })}
      </Container>
      {(!properties || properties?.length === 0 || isLoading) && (
        <Blur
          isLighter={isLighter}
          isBigger={
            code === PropertyCode.MultiFamilyHouse || code === PropertyCode.Plot
          }
        />
      )}
    </>
  );
};

export default SuggestedProperties;
