import {
  PurchaseIntentBusiness,
  PurchaseIntentCountry,
  PurchaseIntentLegalForm,
  PurchaseIntentSalutation,
  PurchaseIntentTitle,
} from '../../../../generated';

export const salutationOptions = [
  {
    value: PurchaseIntentSalutation.Mr,
    label: 'Herr',
  },
  {
    value: PurchaseIntentSalutation.Ms,
    label: 'Frau',
  },
];

export const titleOptions = [
  {
    value: PurchaseIntentTitle.Dr,
    label: 'Dr.',
  },
  {
    value: PurchaseIntentTitle.ProfDr,
    label: 'Prof. Dr.',
  },
  {
    value: PurchaseIntentTitle.NoTitle,
    label: 'ohne Titel',
  },
];

export const legalFormOptions = [
  {
    value: PurchaseIntentLegalForm.SoleTrade,
    label: 'Einzelunternehmer',
  },
  {
    value: PurchaseIntentLegalForm.CompanyUnderCivilLaw,
    label: 'Gesellschaft bürgerlichen Rechts (GbR)',
  },
  {
    value: PurchaseIntentLegalForm.OpenTradingCompany,
    label: 'Offene Handelsgesellschaft (OHG)',
  },
  {
    value: PurchaseIntentLegalForm.LimitedPartnership,
    label: 'Kommanditgesellschaft (KG)',
  },
  {
    value: PurchaseIntentLegalForm.PublicCompany,
    label: 'Aktiengesellschaft (AG)',
  },
  {
    value: PurchaseIntentLegalForm.CompanyWithLimitedLiability,
    label: 'Gesellschaft mit beschränkter Haftung (GmbH)',
  },
  {
    value: PurchaseIntentLegalForm.EntrepreneurialCompany,
    label: 'UG (haftungsbeschränkt)',
  },
];

export const businessOptions = [
  {
    value: PurchaseIntentBusiness.Trade,
    label: 'Handel',
  },
  {
    value: PurchaseIntentBusiness.Industry,
    label: 'Industrie',
  },
  {
    value: PurchaseIntentBusiness.Media,
    label: 'Medien',
  },
  {
    value: PurchaseIntentBusiness.Tourism,
    label: 'Tourismus',
  },
  {
    value: PurchaseIntentBusiness.RealEstate,
    label: 'Immobilien',
  },
  {
    value: PurchaseIntentBusiness.Health,
    label: 'Gesundheit',
  },
  {
    value: PurchaseIntentBusiness.Service,
    label: 'Dienstleistung',
  },
  {
    value: PurchaseIntentBusiness.FinancialEconomy,
    label: 'Finanzwirtschaft',
  },
];

export const countryOptions = [
  {
    value: PurchaseIntentCountry.Germany,
    label: 'Germany',
  },
  {
    value: PurchaseIntentCountry.Austria,
    label: 'Austria',
  },
  {
    value: PurchaseIntentCountry.Switzerland,
    label: 'Switzerland',
  },
  {
    value: PurchaseIntentCountry.Spain,
    label: 'Spain',
  },
  {
    value: PurchaseIntentCountry.France,
    label: 'France',
  },
  {
    value: PurchaseIntentCountry.Greece,
    label: 'Greece',
  },
  {
    value: PurchaseIntentCountry.Hungary,
    label: 'Hungary',
  },
  {
    value: PurchaseIntentCountry.Italy,
    label: 'Italy',
  },
  {
    value: PurchaseIntentCountry.Luxembourg,
    label: 'Luxembourg',
  },
  {
    value: PurchaseIntentCountry.Netherlands,
    label: 'Netherlands',
  },
  {
    value: PurchaseIntentCountry.Portugal,
    label: 'Portugal',
  },
];

export const phoneNumberPrefixesOptions = [
  '+49', // germany
  '+43', // austria
  '+41', // switzerland
  '+34', // spain
  '+39', // italy
  '+45', // denmark
  '+48', // poland
  '+420', // czech republic
  '+423', // liechtenstein
  '+352', // luxembourg
  '+33', // france
  '+32', // belgium
  '+31', // netherlands
  '+46', // sweden
  '+61', // australia
  '+1', // usa & canada
  '+86', // china
  '+81', // japan
  '+91', // india
  '+44', // uk
  '+55', // brazil
  '+7', // russia
  '+82', // south korea
  '+52', // mexico
  '+62', // indonesia
  '+966', // saudi arabia
  '+90', // turkey
  '+886', // taiwan
  '+66', // thailand
];
