import {
  FlatButton,
  SecondaryButton,
} from 'modules/common/components/ui/buttons';
import {
  Headline2,
  Headline4,
  ParagraphText,
} from 'modules/common/components/ui/typography';
import { device } from 'style/theme';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  align-items: center;
  @media ${device.tablet} {
    height: 100%;
  }
  opacity: 1;
  color: ${(props) => props.theme.blue};
`;

export const Content = styled.div`
  display: grid;
  gap: 24px;
  max-width: 852px;
  width: 100%;
  justify-self: center;
  padding: 0 24px 24px;
`;

export const Header = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Footer = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;

  @media ${device.tablet} {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;

export const Title = styled(Headline2)`
  text-align: center;

  @media ${device.tablet} {
    font-size: 18px;
    text-align: left;
  }
`;

export const Text = styled(ParagraphText)<{
  isCompact?: boolean;
  hasExpiredTimer?: boolean;
}>`
  ${({ isCompact }) => (isCompact ? 'max-width: 536px; margin: 0 auto;' : '')}
  a {
    color: ${({ theme }) => theme.ctaBlue};
  }
  ${({ hasExpiredTimer }) =>
    hasExpiredTimer
      ? `
    strong {
      color: #C5363B;
    }
  `
      : ''}
`;

export const SkipButton = styled.a`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  color: #597896;
  cursor: pointer;
  display: flex;
  background: none;
  text-transform: uppercase;
`;

export const NextButton = styled(FlatButton)<{ shouldAlignCenter?: boolean }>`
  text-transform: uppercase;
  margin-left: ${(props) => (props.shouldAlignCenter ? 'unset' : 'auto')};
`;

export const CopyButton = styled(SecondaryButton)`
  text-transform: uppercase;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const SelectOptions = styled.div`
  display: grid;
  gap: 32px;
`;

export const SelectOption = styled.div<{ isSelected?: boolean }>`
  display: grid;
  gap: 16px;
  padding: 24px;
  border-radius: 5px;
  border: 1px solid
    ${({ theme, isSelected }) => (isSelected ? '#A7B9FA' : theme.borderColor)};
  transition: all 0.3s;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.greyBackground : 'none'};
  cursor: pointer;
`;

export const HeadTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SecondaryTitle = styled(Headline4)`
  font-weight: 600;
`;

export const Row = styled.div<{ isCentered?: boolean }>`
  display: flex;
  ${({ isCentered }) => (isCentered ? 'justify-content: center;' : '')}
`;

export const QrPlaceholder = styled.div`
  justify-self: center;
  padding: 9px;
  background: #fff;
  box-shadow: 0 6px 17px rgba(0, 0, 0, 0.09);
`;

export const CodeInputWrapper = styled.div<{ hasError?: boolean }>`
  display: grid;
  gap: 16px;
  max-width: 446px;
  margin: 0 auto;

  ${({ hasError, theme }) =>
    hasError
      ? `

    color: #C5363B;

    input {
      color: #C5363B;
      border-color:  #E29B9D;
    }
  `
      : ''}
`;

export const CodeInputWrapperFields = styled.div`
  display: flex;
  gap: 32px;
  justify-self: center;

  //  Add extra gap between 3rd and 4th inputs
  > :nth-child(3) {
    margin-right: 8px;
  }
  > :nth-child(4) {
    margin-left: 8px;
  }

  @media ${device.tablet} {
    gap: 4px;

    > :nth-child(3) {
      margin-right: 4px;
    }
    > :nth-child(4) {
      margin-left: 4px;
    }
  }
`;

export const CodeInputField = styled.input`
  border: 2px solid ${({ theme }) => theme.greyBackground};
  width: 45px;
  height: 62px;
  line-height: 62px;
  font-size: 24px;
  font-family: Roboto;
  color: ${({ theme }) => theme.blue};
  border-radius: 5px;
  text-align: center;

  -moz-appearance: textfield;

  @media ${device.tablet} {
    width: 40px;
    height: 48px;
  }

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.greyBackground};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.greyBackground};
  }

  &::placeholder {
    color: ${({ theme }) => theme.greyBackground};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const BackupCodes = styled.ul`
  display: grid;
  justify-self: center;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 32px;
  row-gap: 16px;
  list-style-type: none;

  li {
    position: relative;
    font-family: Roboto;
    font-weight: 600;
    color: ${({ theme }) => theme.blue};
    padding-left: 12px;
    &:before {
      content: '';
      width: 4px;
      height: 4px;
      position: absolute;
      top: 0.5em;
      margin-top: -2px;
      left: 0;
      border-radius: 50%;
      background: ${({ theme }) => theme.blue};
    }
  }
`;

export const PhoneWrapper = styled.div`
  max-width: 290px;
  label {
    display: none;
  }

  .phone-select {
    border: 1px solid ${({ theme }) => theme.faqActiveHeader} !important;
  }
  .phone-input {
    border: 1px solid ${({ theme }) => theme.faqActiveHeader} !important;
    border-left: none !important;
  }
`;

export const CodeWrapper = styled.div<{ hasError?: boolean }>`
  width: 100%;
  label {
    display: none;
  }
  .code-input {
    border: 1px solid
      ${({ theme, hasError }) => (hasError ? '#C5363B' : theme.faqActiveHeader)} !important;
    border-radius: 5px;
    ${({ hasError }) =>
      hasError
        ? `
      color: #C5363B
    `
        : ''}
  }
`;

export const Trigger = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.ctaBlue};
`;

export const ExpiredText = styled.p`
  font-size: 14px;
  font-family: Roboto;
`;

export const ContainerAfterLogin = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 2;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const ContainerAfterLoginLeft = styled.div`
  width: 50%;
  background: linear-gradient(90.57deg, #002447 4.48%, #00366b 48.29%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
  padding: 64px 48px 48px;
  color: ${({ theme }) => theme.white};

  @media ${device.tablet} {
    width: 100%;
    padding: 24px 16px 16px;
  }
`;

export const ContainerAfterLoginRight = styled.div`
  width: 50%;
  padding: 64px 24px 48px;

  @media ${device.tablet} {
    width: 100%;
    padding: 24px 16px 16px;
  }
`;

export const ContentAfterLogin = styled.div`
  display: grid;
  gap: 16px;
`;

export const LeftTitle = styled(Headline2)`
  color: inherit;
  @media ${device.tablet} {
    font-size: 18px;
  }
`;

export const LeftSecondaryTitle = styled(Headline4)`
  color: inherit;
  font-weight: 600;
`;

export const SuccessTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.green};
  justify-content: space-between;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 5px;
`;

export const SuccessText = styled.p`
  font-size: 14px;
  font-family: Roboto;
`;
