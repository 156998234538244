import { createContext } from 'react';
import { MfaType } from './mfa-interfaces';

interface IContext {
  type: MfaType | undefined;
  setType: (newValue: MfaType) => void;
  appTokenCode?: string;
  setAppTokenCode?: (newValue: string) => void;
  code: string;
  setCode: (newValue: string) => void;
  isSuccess: boolean;
  setIsSuccess: (newValue: boolean) => void;
  backupCodes: string[];
  setBackupCodes: (newValue: string[]) => void;
  isFinalStep: boolean;
  setIsFinalStep: (newValue: boolean) => void;
  resendSmsCode: (resendTo?: string) => void;
  smsExpires: number;
  setSmsExpires: (newValue: number) => void;
}

const MfaContext = createContext<Partial<IContext>>({});

export default MfaContext;
