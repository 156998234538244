import { useContext, useEffect, useRef, useState } from 'react';
import {
  CodeInputWrapper,
  CodeInputWrapperFields,
  ExpiredText,
  Row,
  Text,
  Trigger,
} from './mfa-modal-styles';
import MfaContext from './mfa-context';
import { Trans, useTranslation } from 'react-i18next';
import { formatSecondsToMMSS } from './utils';
import MfaInputField from './mfa-input-field';

interface IProps {
  hasError?: boolean;
  setHasError?: (newValue: boolean) => void;
  resendTo?: string;
}

export const MfaCodeInput = ({ hasError, setHasError, resendTo }: IProps) => {
  const { t } = useTranslation();

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [values, setValues] = useState<string[]>(new Array(6).fill(''));

  const {
    setCode,
    type,
    smsExpires = 0,
    setSmsExpires,
    resendSmsCode,
  } = useContext(MfaContext);

  const handleChange = (index: number, newValue: string) => {
    // Update the value of the current input
    const newValues = [...values];
    newValues[index] = newValue;
    setValues(newValues);

    // Check if the current input has a value
    if (newValue && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (index: number) => {
    if (index === inputRefs.current.length) {
      setHasError?.(false);
    }

    // Focus the previous input if it exists
    if (index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    setCode?.(values.join(''));
  }, [values, setCode]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (smsExpires > 0) {
        setSmsExpires?.(smsExpires - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [smsExpires, setSmsExpires]);

  return (
    <CodeInputWrapper hasError={hasError}>
      <CodeInputWrapperFields>
        {new Array(6).fill(null).map((_, index) => (
          <MfaInputField
            key={index}
            index={index}
            value={values[index]}
            onChange={handleChange}
            onBackspace={handleBackspace}
            inputRef={(el) => {
              inputRefs.current[index] = el;
            }}
          />
        ))}
      </CodeInputWrapperFields>

      {hasError && type === 'app' && (
        <Text content={t('auth.mfa.app.code-error')} color={'currentColor'} />
      )}

      {hasError && type === 'sms' && (
        <ExpiredText>
          <Trans
            i18nKey="auth.mfa.sms.code-error"
            components={{
              strong: (
                <Trigger
                  onClick={() => {
                    setHasError?.(false);
                    resendSmsCode?.(resendTo);
                  }}
                />
              ),
            }}
          />
        </ExpiredText>
      )}

      {!hasError && type === 'sms' && (
        <>
          <Row>
            {smsExpires > 0 ? (
              <Text
                content={t('auth.mfa.sms.code-expiration', {
                  time: formatSecondsToMMSS(smsExpires),
                })}
                allowHtml
              />
            ) : (
              <ExpiredText>
                <Trans
                  i18nKey="auth.mfa.sms.code-expired-text"
                  components={{
                    strong: (
                      <Trigger
                        onClick={() => {
                          resendSmsCode?.(resendTo);
                        }}
                      />
                    ),
                  }}
                />
              </ExpiredText>
            )}
          </Row>
        </>
      )}
    </CodeInputWrapper>
  );
};
