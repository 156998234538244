import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMapSettings, IMapPin } from '../../../../map/interfaces';
import { Europe } from '../../../../map/constants/points-of-interest';
import { PropertyCode } from '../../../../../generated';
import { defaultMapSettings } from '../../../../map/constants';

interface InitialState {
  activeTab: number;
  isAppointmentSelectOpen: boolean;
  isOpenHeadAccordion: boolean;
  isGalleryOpen: boolean;
  isExposeOverlayOpen: boolean;
  isShadowmapOpen: boolean;
  isShadowmapExpanded: boolean;
  isShadowmapSettingsOpen: boolean;
  isFullScreenMapOpen: boolean;
  isPropertyPlanOpen: boolean;
  selectedPropertyId: string;
  externalPropertyId: string;
  selectedPropertyCode: PropertyCode | null;
  activeSlide: number;
  map: {
    viewport: Record<string, unknown>;
    markers: IMapPin[];
    settings: IMapSettings;
    viewportCheckpoint?: Record<string, unknown>;
    markersCheckpoint?: IMapPin[];
    settingsCheckpoint?: IMapSettings;
  };
}

const initialState: InitialState = {
  activeTab: 0,
  isAppointmentSelectOpen: false,
  isOpenHeadAccordion: true,
  isGalleryOpen: false,
  isFullScreenMapOpen: false,
  isPropertyPlanOpen: false,
  isExposeOverlayOpen: false,
  isShadowmapOpen: false,
  isShadowmapExpanded: false,
  isShadowmapSettingsOpen: false,
  selectedPropertyId: '',
  externalPropertyId: '',
  selectedPropertyCode: null,
  activeSlide: 0,
  map: {
    viewport: { ...Europe },
    settings: defaultMapSettings,
    markers: [],
  },
};

export const sellerExposeSlice = createSlice({
  name: 'sellerExposeDetails',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    toggleExposeOverlay: (state, action: PayloadAction<boolean>) => {
      state.isExposeOverlayOpen = action.payload;
    },
    setIsAppointmentSelectOpen: (state, action: PayloadAction<boolean>) => {
      state.isAppointmentSelectOpen = action.payload;
    },
    setIsOpenHeadAccordion: (state, action: PayloadAction<boolean>) => {
      state.isOpenHeadAccordion = action.payload;
    },
    setIsGalleryOpen: (state, action: PayloadAction<boolean>) => {
      state.isGalleryOpen = action.payload;
    },
    setIsFullScreenMapOpen: (state, action: PayloadAction<boolean>) => {
      state.isFullScreenMapOpen = action.payload;
    },
    setIsShadowMapOpen: (state, action: PayloadAction<boolean>) => {
      state.isShadowmapOpen = action.payload;
    },
    setIsShadowMapExpanded: (state, action: PayloadAction<boolean>) => {
      state.isShadowmapExpanded = action.payload;
    },
    setIsShadowMapSettingsOpen: (state, action: PayloadAction<boolean>) => {
      state.isShadowmapSettingsOpen = action.payload;
    },
    setIsPropertyPlanOpen: (state, action: PayloadAction<boolean>) => {
      state.isPropertyPlanOpen = action.payload;
    },
    setSelectedPropertyId: (state, action: PayloadAction<string>) => {
      state.selectedPropertyId = action.payload;
    },
    setExternalPropertyId: (state, action: PayloadAction<string>) => {
      state.externalPropertyId = action.payload;
    },
    setSelectedPropertyCode: (state, action: PayloadAction<PropertyCode>) => {
      state.selectedPropertyCode = action.payload;
    },
    setActiveSlide: (state, action: PayloadAction<number>) => {
      state.activeSlide = action.payload;
    },
    changeMapViewport(state, action) {
      state.map.viewportCheckpoint = state.map.viewport;
      state.map.viewport = { ...state.map.viewport, ...action.payload };
    },
    setMapSettings(state, action: PayloadAction<Partial<IMapSettings>>) {
      state.map.settingsCheckpoint = state.map.settings;
      state.map.settings = {
        ...state.map.settings,
        ...action.payload,
      };
    },
    removeAllMapMarkersExceptHome(state) {
      state.map.markers = state.map.markers.filter(
        (marker) => !marker.category
      );
    },
    addMapMarkers(state, action: PayloadAction<IMapPin[]>) {
      state.map.markers = [...state.map.markers, ...action.payload];
    },
    setMapMarkers(state, action: PayloadAction<IMapPin[]>) {
      state.map.markersCheckpoint = state.map.markers;
      state.map.markers = action.payload;
    },
    removeMapMarkers(state, action) {
      state.map.markers = state.map.markers.filter(
        (m) => m.category !== action.payload
      );
    },
  },
});

export const {
  setActiveTab,
  setIsAppointmentSelectOpen,
  setIsOpenHeadAccordion,
  setIsGalleryOpen,
  setActiveSlide,
  toggleExposeOverlay,
  setIsShadowMapOpen,
  setIsShadowMapExpanded,
  setIsShadowMapSettingsOpen,
  setSelectedPropertyId,
  setExternalPropertyId,
  setSelectedPropertyCode,
  changeMapViewport,
  setMapMarkers,
  addMapMarkers,
  removeAllMapMarkersExceptHome,
  setMapSettings,
  removeMapMarkers,
  setIsFullScreenMapOpen,
  setIsPropertyPlanOpen,
} = sellerExposeSlice.actions;

export const { reducer, name } = sellerExposeSlice;

export default sellerExposeSlice;
