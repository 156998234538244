import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { Coordinates } from '../../../generated';
import Icon from '../../common/components/ui/icon';
import { useAppSelector } from '../../common/hooks';
import { setIsShadowMapExpanded } from '../../search-profile/pages/expose-details-overlay/redux/exposeSlice';
import { setIsShadowMapExpanded as setIsShadowMapExpandedSeller } from '../../seller/pages/expose-details-overlay/redux/exposeSlice';
import {
  AddressBar,
  AddressBarText,
  CloseButton,
  MobileInfoBox,
  MobileInfoContentBox,
  MobileInfoOverlay,
  MobileInfoToggler,
  MobileInfoTogglerIcon,
  ShadowmapMobileHeader,
  ShadowmapMobileHeaderButton,
  ShadowmapMobileIframe,
  ShadowmapMobileOverlay,
} from './shadowmap-mobile-styles';
import { ReactComponent as CloseIcon } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { ReactComponent as Pin } from 'assets/streamline-light/maps-navigation/pins/pin.svg';

import ShadowmapInfo from '../../search-profile/pages/expose-details-overlay/components/tabs/shadowmap/shadowmap';

interface IProps {
  coordinates: Coordinates;
  address: string;
  urlType: 'propertyowner' | 'exposeoverlay';
  isSellerShadowmap?: boolean;
}

const ShadowmapMobile: FC<IProps> = ({
  coordinates,
  address,
  urlType,
  isSellerShadowmap,
}) => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  const dispatch = useDispatch();

  const [isShowInfo, setIsShowInfo] = useState<boolean>(false);

  const isShadowMapExpanded = useAppSelector(
    (state) =>
      state[isSellerShadowmap ? 'sellerExposeDetails' : 'exposeDetails']
        .isShadowmapExpanded
  );

  useEffect(() => {
    if (isShadowMapExpanded) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isShadowMapExpanded]);

  const src = `https://vonpoll-${urlType}.poweredby.shadowmap.org?zoom=15&azimuth=0&basemap=map&lat=${
    coordinates?.latitude || 0
  }}&lng=${coordinates?.longitude || 0}`;

  if (!isShadowMapExpanded) return null;

  return (
    <ShadowmapMobileOverlay>
      <ShadowmapMobileHeader>
        <ShadowmapMobileHeaderButton onClick={() => setIsShowInfo(true)}>
          {t('shadowmap.how-to.title').replace(':', '?')}
        </ShadowmapMobileHeaderButton>
        <CloseButton
          onClick={() => {
            if (isSellerShadowmap) {
              dispatch(setIsShadowMapExpandedSeller(false));
            } else {
              dispatch(setIsShadowMapExpanded(false));
            }
          }}
        >
          <Icon
            icon={CloseIcon}
            width={16}
            height={16}
            color={themeContext.white}
          />
        </CloseButton>
      </ShadowmapMobileHeader>
      <ShadowmapMobileIframe
        src={src}
        title="Shadowmap Von Poll Porperty Owner Integration"
      />
      <AddressBar>
        <Icon icon={Pin} width={16} height={16} color={themeContext.blue} />
        <AddressBarText>{address}</AddressBarText>
      </AddressBar>

      <MobileInfoOverlay
        $shown={isShowInfo}
        onClick={() => setIsShowInfo(false)}
      >
        <MobileInfoBox $shown={isShowInfo}>
          <MobileInfoToggler onClick={() => setIsShowInfo(false)}>
            <MobileInfoTogglerIcon />
          </MobileInfoToggler>
          <MobileInfoContentBox>
            <ShadowmapInfo isMobileInfo />
          </MobileInfoContentBox>
        </MobileInfoBox>
      </MobileInfoOverlay>
    </ShadowmapMobileOverlay>
  );
};

export default ShadowmapMobile;
