import styled from 'styled-components';
import { hexToRGB } from '../../../utils/hex-to-rgb';
import { MainButton } from '../buttons';
import { device } from '../../../../../style/theme';

interface IAnimationProps {
  $height: number;
  $padding: number;
  isSPMobile?: boolean;
}

export const Container = styled.div<IAnimationProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.9;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #e5e9ec;
  overflow: hidden;
  height: ${(props) => props.$height}px;
  min-height: ${(props) => props.$height}px;
  padding: ${(props) => props.$padding}px 12px ${(props) => props.$padding}px
    24px;
  ${(props) =>
    props.isSPMobile &&
    `
    padding: 8px 0;
    overflow: initial;
    margin-bottom: 8px;
    align-self: flex-start;

    &:before {
      content: '';
      width: 200vw;
      background: #e5e9ec;
      position: absolute;
      left: -50vw;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
  `}
`;

export const AddressContent = styled.div<{ isSPMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  flex-grow: 1;
  min-width: 10px;
  ${(props) => props.isSPMobile && `margin-left: 16px;`}
`;

export const AddressContainer = styled.div`
  display: flex;
`;

export const AddressInfoWrapper = styled.div<{ isSPMobile?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  & i {
    min-width: 24px;
  }
  ${(props) =>
    props.isSPMobile &&
    `
    & i {
      min-width: 16px;
      min-height: 16px;
      max-width: 16px;
      max-height: 16px;
    }
  `}
`;

export const Title = styled.h4`
  margin: 0 0 4px 0;
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  &&&& {
    color: ${(props) => hexToRGB(props.theme.blue, 0.5)};
  }
`;

export const Address = styled.p<{ $cursor?: boolean }>`
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.$cursor && 'cursor: default;'}
  &&&& {
    color: ${(props) => props.theme.blue};
  }
`;

export const StarsImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  //position: absolute;
  margin-right: -11px;
  top: 0;
  min-width: 203px;
  height: 100%;
  right: 0;
`;

export const StarsImage = styled.img`
  height: 100%;
`;

export const RadiusInfo = styled.div`
  margin: 0 8px;
  padding: 0 8px;
  border-radius: 10px;
  border: solid 1px #d9d9d9;
  background-color: #fff;
  > p {
    opacity: 0.5;
    font-family: Roboto, sans-serif;
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.5px;
    color: ${(props) => props.theme.blue};
  }
`;

export const NewPropertiesCountInfo = styled.div`
  padding: 0 8px;
  border-radius: 10px;
  background-color: #74a257;
  display: flex;
  align-items: center;
  > p {
    font-family: Roboto, sans-serif;
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    //line-height: 1.6;
    letter-spacing: 0.5px;
    color: ${(props) => props.theme.white};
  }
`;

export const FullScreenMapButton = styled(MainButton)`
  justify-content: center;
  padding: 21px;
  width: fit-content;
  border-radius: 0;
  position: absolute;
  top: 0;
  right: 0;
  i {
    margin: 0 !important;
  }
  display: none;

  @media ${device.tablet} {
    display: flex;
  }
`;

export const FullScreenMapButtonWithLabel = styled(FullScreenMapButton)`
  flex-direction: column;
  padding: 8px 6px;
`;

export const TooltipParagraph = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;
