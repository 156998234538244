import styled from 'styled-components';

interface IProps {
  content: string;
  allowHtml?: boolean;
  className?: string;
  color?: string;
  fontWeight?: string | number;
}

const Container = styled.p<{ color?: string; fontWeight?: string | number }>`
  margin: 0;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${({ theme, color }) => color || theme.blue};
`;

const ParagraphText = ({
  content,
  allowHtml,
  ...rest
}: IProps): JSX.Element => {
  return !allowHtml ? (
    <Container {...rest}>{content}</Container>
  ) : (
    <Container {...rest} dangerouslySetInnerHTML={{ __html: content }} />
  );
};

export { ParagraphText };
