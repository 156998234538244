import { useEffect } from 'react';
import {
  resetSPC,
  setErrors,
  setRedirect,
  toggleLoading,
  updateTemporarySearchProfileToClaim,
} from '../redux/searchProfileSlice';
import { siteMap } from '../../../../routes/site-map';
import { FormError } from '../../interface';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { useConvertSearchProfileMutation } from '../../../../services/graphql/enhanced';
import { toggleLoading as toggleLoadingAuth } from '../../../auth/redux/authSlice';
import { batch } from 'react-redux';

const useConvertSP = (): void => {
  const dispatch = useAppDispatch();
  const lngHPush = useLngHistoryPush();
  const isIframe = useAppSelector((state) => state.auth.isIframe);

  const selectedAddress = useAppSelector(
    (state) => state.searchProfile.selectedAddress
  );
  const owner = useAppSelector((state) => state.auth.user?._id);

  const { userInput, temporarySearchProfileToClaim, isRedirect } =
    useAppSelector((state) => ({
      userInput: state.searchProfile.userInput,
      temporarySearchProfileToClaim:
        state.searchProfile.temporarySearchProfileToClaim,
      isRedirect: state.searchProfile.isRedirect,
    }));

  const [convertSearchProfile] = useConvertSearchProfileMutation();

  useEffect(() => {
    const convertAsync = async () => {
      try {
        dispatch(toggleLoading(true));

        const { convertSearchProfile: searchProfile } =
          await convertSearchProfile({
            ownerId: owner as string,
            searchProfileId: temporarySearchProfileToClaim?._id,
          }).unwrap();

        if (!searchProfile?._id) return;

        dispatch(resetSPC());

        if (isRedirect) {
          if (isIframe) {
            window.open(
              `${window.location.origin}${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfile._id}`,
              '_blank',
              'noopener,noreferrer'
            );
            return;
          }

          lngHPush(
            `${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfile._id}`
          );
        }
      } catch (error) {
        dispatch(setErrors(error as FormError));
      } finally {
        batch(() => {
          dispatch(updateTemporarySearchProfileToClaim(null));
          dispatch(toggleLoadingAuth(false));
          dispatch(toggleLoading(false));
        });
      }
    };
    if (owner && temporarySearchProfileToClaim) {
      dispatch(setRedirect(false));
      convertAsync();
    }
  }, [
    dispatch,
    isRedirect,
    userInput,
    selectedAddress,
    owner,
    convertSearchProfile,
    lngHPush,
    temporarySearchProfileToClaim,
  ]);
};

export { useConvertSP };
