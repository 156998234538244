import { FC, useContext } from 'react';
import { batch, useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { useAppSelector } from '../../../../../common/hooks';

import { Background, Container } from './main-container-styles';
import { useIsMobileSize } from '../../../../../common/hooks/useIsMobileSize';
import {
  toggleExposeOverlay,
  setIsShadowMapExpanded,
  setIsShadowMapOpen,
} from '../../redux/exposeSlice';
import { CloseButton } from '../../../../../common/components/modal/close-button';

const MainContainer: FC = ({ children }) => {
  const isMobileSize = useIsMobileSize();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const isShadowmapSettingsOpen = useAppSelector(
    (state) => state.sellerExposeDetails.isShadowmapSettingsOpen
  );
  const isShadowmapOpen = useAppSelector(
    (state) => state.sellerExposeDetails.isShadowmapOpen
  );

  const closeModal = () => {
    batch(() => {
      // dispatch(setTabListNearTop(true));
      // dispatch(setShowMap(true));
      dispatch(toggleExposeOverlay(false));
      dispatch(setIsShadowMapExpanded(false));
      dispatch(setIsShadowMapOpen(false));
    });
  };
  return (
    <Background onClick={closeModal}>
      {isMobileSize && (
        <CloseButton
          handleClose={closeModal}
          position={'right'}
          color={themeContext.white}
        />
      )}
      <Container onClick={(e) => e.stopPropagation()}>
        {children}
        {!isMobileSize &&
          (isShadowmapOpen ? !isShadowmapSettingsOpen : true) && (
            <CloseButton
              handleClose={closeModal}
              position={'right'}
              color={themeContext.blue}
            />
          )}
      </Container>
    </Background>
  );
};

export { MainContainer };
