/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-useless-escape */
/* eslint-disable unicorn/better-regex */
export * from './debug';
export * from './max-mobile-size';

// export const EMAIL_REGEX = new RegExp(
//   /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@(([\d[]{1,3}(?:\.\d{1,3}){3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/
// );
export const EMAIL_REGEX = new RegExp(
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
);

export const PASSWORD_REGEX = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~=+`])\S{10,99}$/
);

export const PHONE_REGEX = new RegExp(/^\d{2,15}([- ]?\d{2,15})?$/);
