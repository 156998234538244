import { useContext, useEffect, useRef, useState } from 'react';
import {
  CodeInputWrapper,
  CodeInputWrapperFields,
  ExpiredText,
  Row,
  Text,
  Trigger,
} from './mfa-modal-styles';
import { Trans, useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { formatSecondsToMMSS } from './utils';
import MfaInputField from './mfa-input-field';
import MfaAfterLoginContext from './mfa-after-login-context';
import { useIsMobileSize } from 'modules/common/hooks/useIsMobileSize';

interface IProps {
  hasError?: boolean;
  setHasError?: (newValue: boolean) => void;
  isRequestTimeoutError?: boolean;
}

export const MfaCodeAfterLoginInput = ({
  hasError,
  setHasError,
  isRequestTimeoutError,
}: IProps) => {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [values, setValues] = useState<string[]>(new Array(6).fill(''));

  const {
    setCode,
    type,
    smsExpires = 0,
    setSmsExpires,
    resendSmsCode,
  } = useContext(MfaAfterLoginContext);

  const handleChange = (index: number, newValue: string) => {
    // Update the value of the current input
    const newValues = [...values];
    newValues[index] = newValue;
    setValues(newValues);

    // Check if the current input has a value
    if (newValue && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (index: number) => {
    if (index === inputRefs.current.length) {
      setHasError?.(false);
    }

    // Focus the previous input if it exists
    if (index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    setCode?.(values.join(''));
  }, [values, setCode]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (smsExpires > 0) {
        setSmsExpires?.(smsExpires - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [smsExpires, setSmsExpires]);

  return (
    <CodeInputWrapper hasError={hasError}>
      <CodeInputWrapperFields style={{ gap: isMobileSize ? 4 : 24 }}>
        {new Array(6).fill(null).map((_, index) => (
          <MfaInputField
            key={index}
            index={index}
            value={values[index]}
            onChange={handleChange}
            onBackspace={handleBackspace}
            inputRef={(el) => {
              inputRefs.current[index] = el;
            }}
          />
        ))}
      </CodeInputWrapperFields>

      {hasError && type === 'app' && (
        <Text
          content={
            isRequestTimeoutError
              ? t('auth.mfa.after-login.backup-code.timeout')
              : t('auth.mfa.after-login.app.form.error-code')
          }
          color={'currentColor'}
        />
      )}

      {type === 'sms' && (
        <>
          <Row>
            <Text
              content={t('auth.mfa.after-login.sms.form.code-expiration', {
                time: formatSecondsToMMSS(smsExpires),
              })}
              hasExpiredTimer={smsExpires <= 0}
              allowHtml
            />
          </Row>
          {smsExpires <= 0 && (
            <Row>
              <ExpiredText style={{ color: '#C5363B' }}>
                <Trans
                  i18nKey="auth.mfa.after-login.sms.form.code-expired-text"
                  components={{
                    strong: (
                      <Trigger
                        onClick={() => {
                          resendSmsCode?.();
                        }}
                      />
                    ),
                  }}
                />
              </ExpiredText>
            </Row>
          )}

          {hasError && (
            <ExpiredText>
              <Trans
                i18nKey="auth.mfa.sms.code-error"
                components={{
                  strong: (
                    <Trigger
                      onClick={() => {
                        setHasError?.(false);
                        resendSmsCode?.();
                      }}
                    />
                  ),
                }}
              />
            </ExpiredText>
          )}
        </>
      )}
    </CodeInputWrapper>
  );
};
