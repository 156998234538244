import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Coordinates } from '../../../../../generated';
import Icon from '../../../../common/components/ui/icon';
import {
  AddressBar,
  CloseButton,
  ShadowmapIframe,
  ShadowmapMainButton,
  ShadowmapOverlay,
  AddressBarButton,
  AddressBarItemsBox,
  AddressBarTitle,
  AddressBarText,
  ShadowmapMainButtonText,
  ShadowmapMainButtonIcon,
} from './property-shadowmap-styles';
import { ReactComponent as CloseIcon } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { ReactComponent as Pin } from 'assets/streamline-light/maps-navigation/pins/pin.svg';
import { ReactComponent as Sun } from 'assets/svg/sun.svg';

interface IProps {
  coordinates: Coordinates;
  address: string;
}

const PropertyShadowmap: FC<IProps> = ({ coordinates, address }) => {
  const [showShadowmapOpen, setShowShadowmapOpen] = useState<boolean>(false);
  const [isShadowmapSettingsOpen, setIsShadowmapSettingsOpen] =
    useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = showShadowmapOpen ? 'hidden' : 'auto';
  }, [showShadowmapOpen]);

  const settingsOpenHandler = useCallback(
    (event) => {
      if (event.data && event.data.type === 'shadowmap:settingsActive') {
        const { visible } = event.data;
        setIsShadowmapSettingsOpen(visible);
      }
    },
    [setIsShadowmapSettingsOpen]
  );

  useEffect(() => {
    window.addEventListener('message', settingsOpenHandler);

    return () => {
      window.removeEventListener('message', settingsOpenHandler);
    };
  }, []);

  const onRoute = () => {
    window.open(
      `https://maps.google.com/?q=${coordinates?.latitude || 0},${
        coordinates?.longitude || 0
      }`,
      '_blank'
    );
  };

  const src = `https://vonpoll-propertyowner.poweredby.shadowmap.org?zoom=15&azimuth=0&basemap=map&lat=${
    coordinates?.latitude || 0
  }}&lng=${coordinates?.longitude || 0}`;

  return (
    <>
      <ShadowmapMainButton onClick={() => setShowShadowmapOpen(true)}>
        <ShadowmapMainButtonIcon>
          <Icon icon={Sun} width={24} height={24} color={'#ffffff'} />
        </ShadowmapMainButtonIcon>
        <ShadowmapMainButtonText>
          {t('shadowmap.suntour')}
        </ShadowmapMainButtonText>
      </ShadowmapMainButton>

      {showShadowmapOpen && (
        <ShadowmapOverlay>
          {!isShadowmapSettingsOpen && (
            <CloseButton onClick={() => setShowShadowmapOpen(false)}>
              <Icon icon={CloseIcon} width={16} height={16} color={'#ffffff'} />
            </CloseButton>
          )}

          <ShadowmapIframe
            src={src}
            title="Shadowmap Von Poll Porperty Owner Integration"
          />

          <AddressBar>
            <AddressBarItemsBox>
              <Icon icon={Pin} width={24} height={24} color={'#00305E'} />
              <div>
                <AddressBarTitle>{t('your.address')}</AddressBarTitle>
                <AddressBarText>{address}</AddressBarText>
              </div>
            </AddressBarItemsBox>
            <AddressBarButton onClick={onRoute}>
              {t('route.button.text')}
            </AddressBarButton>
          </AddressBar>
        </ShadowmapOverlay>
      )}
    </>
  );
};

export default PropertyShadowmap;
