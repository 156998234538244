import { ReactComponent as Bin2 } from 'assets/streamline-light/interface-essential/delete/bin-2.svg';
import { ReactComponent as Pencil1 } from 'assets/streamline-light/interface-essential/edit/pencil-1.svg';
import { ReactComponent as OfficeFilePdf } from 'assets/streamline-light/work-office-companies/office-files/office-file-pdf.svg';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import { device } from 'style/theme';

import { useExportPdfMutation, UserStatus } from '../../../../generated';
import { useRemovePropertyMutation } from '../../../../services/graphql/enhanced';
import { Tooltip } from '../../../common/components/tooltip';
import { TooltipConfirmation } from '../../../common/components/tooltip/TooltipConfirmation';
import { IconButton } from '../../../common/components/ui/buttons';
import { PDFContainer } from '../../../pdf-creator/property-document/components/container';
import {
  removeTemporaryValuations,
  toggleIsEditValuationModalOpen,
} from '../../../forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { ShareAction } from './share-action/share-action';
import { useLngHistoryPush } from '../../../localization/lng-history-push';
import { siteMap } from '../../../../routes/site-map';

interface IProps {
  isLockedPage?: boolean;
  propertyId?: string;
  userStatus?: UserStatus | null;
  isDeleteDisabled?: boolean;
  isEditDisabled?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 24px;
  flex: 1;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  > div:nth-child(2) {
    > div {
      display: block;
    }
  }

  @media ${device.tablet} {
    gap: 8px;
    > *:not(.__react_component_tooltip) {
      width: 25%;
      margin: 0 !important;
      button {
        width: 100%;
        height: 40px;
      }
      [data-tip] {
        width: 100%;
        div {
          width: 100%;
        }
      }
    }
    *:not(.__react_component_tooltip) {
      max-width: none !important;
    }

    .tootlip-confirmation {
      left: 16px !important;
      ::before,
      ::after {
        left: 32px !important;
      }
    }
  }
`;

const TooltipHeader = styled.h4`
  font-family: 'Source Serif Pro', serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

const VariationHeaderActions = ({
  isLockedPage,
  propertyId,
  userStatus,
  isDeleteDisabled = false,
  isEditDisabled = false,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lngHPush = useLngHistoryPush();
  const theme = useContext(ThemeContext);
  const [exportPdf] = useExportPdfMutation();

  const [isPDFLoading, setPDFLoading] = useState(false);

  const openModal = () => {
    if (!isLockedPage) {
      dispatch(toggleIsEditValuationModalOpen(true));
    }
  };

  const [removeProperty, { isLoading }] = useRemovePropertyMutation();

  const handleDeleteProperty = async () => {
    if (!isLockedPage && propertyId) {
      await removeProperty({ id: propertyId }).unwrap();
      lngHPush(siteMap.OwnerLandingPage.path);
    } else {
      dispatch(removeTemporaryValuations());
    }
  };

  return (
    <Container>
      {userStatus === UserStatus.Owner && (
        <TooltipConfirmation
          headerText={
            isDeleteDisabled
              ? t('property-delete-disabled.tooltip.title')
              : t('property-delete.tooltip.title')
          }
          backText={
            isDeleteDisabled
              ? t('button.close')
              : t('property-delete.tooltip.back')
          }
          acceptText={
            isDeleteDisabled ? '' : t('property-delete.tooltip.accept')
          }
          id={'property-delete'}
          onDelete={isDeleteDisabled ? undefined : handleDeleteProperty}
        >
          <Tooltip
            content={
              <IconButton
                icon={Bin2}
                isLoading={isLoading}
                iconColor={isDeleteDisabled ? theme.ctaBlue : theme.white}
                buttonColor={isDeleteDisabled ? theme.blue : theme.ctaBlue}
                border={
                  isDeleteDisabled ? `1px solid ${theme.ctaBlue}` : 'none'
                }
                iconColorHover={isDeleteDisabled ? theme.ctaBlue : theme.white}
                buttonColorHover={
                  isDeleteDisabled ? theme.blue : theme.ctaBlueHover
                }
              />
            }
            id={'properties-tab.delete-property.tooltip'}
            maxWidth={32}
          >
            <TooltipHeader>
              {t('properties-tab.delete-property.tooltip')}
            </TooltipHeader>
          </Tooltip>
        </TooltipConfirmation>
      )}
      {!isLockedPage && (
        <>
          {(userStatus === UserStatus.Owner ||
            userStatus === UserStatus.Editor) &&
            isEditDisabled && (
              <TooltipConfirmation
                headerText={t('property-edit-disabled.tooltip.title')}
                backText={t('button.close')}
                acceptText={''}
                id={'property-edit'}
                onDelete={undefined}
                tabletWidth="100%"
              >
                <Tooltip
                  content={
                    <IconButton
                      tabletButtonWidth="100%"
                      icon={Pencil1}
                      onClick={isEditDisabled ? undefined : openModal}
                      iconColor={isEditDisabled ? theme.ctaBlue : theme.white}
                      buttonColor={isEditDisabled ? theme.blue : theme.ctaBlue}
                      border={
                        isEditDisabled ? `1px solid ${theme.ctaBlue}` : 'none'
                      }
                      iconColorHover={
                        isEditDisabled ? theme.ctaBlue : theme.white
                      }
                      buttonColorHover={
                        isEditDisabled ? theme.blue : theme.ctaBlueHover
                      }
                    />
                  }
                  id={'properties-tab.edit-property.tooltip'}
                  maxWidth={32}
                >
                  <TooltipHeader>
                    {t('properties-tab.edit-property.tooltip')}
                  </TooltipHeader>
                </Tooltip>
              </TooltipConfirmation>
            )}
          {(userStatus === UserStatus.Owner ||
            userStatus === UserStatus.Editor) &&
            !isEditDisabled && (
              <Tooltip
                content={<IconButton icon={Pencil1} onClick={openModal} />}
                id={'properties-tab.edit-property.tooltip'}
                maxWidth={32}
              >
                <TooltipHeader>
                  {t('properties-tab.edit-property.tooltip')}
                </TooltipHeader>
              </Tooltip>
            )}
          <Tooltip
            content={
              <IconButton
                icon={OfficeFilePdf}
                onClick={async () => {
                  setPDFLoading(true);
                  await exportPdf();
                }}
                isLoading={isPDFLoading}
              />
            }
            id={'properties-tab.pdf-property.tooltip'}
            maxWidth={32}
          >
            <TooltipHeader>
              {t('properties-tab.pdf-property.tooltip')}
            </TooltipHeader>
          </Tooltip>
          {userStatus === UserStatus.Owner && (
            <Tooltip
              content={<ShareAction />}
              id={'properties-tab.share-property.tooltip'}
              maxWidth={32}
            >
              <TooltipHeader>
                {t('properties-tab.share-property.tooltip')}
              </TooltipHeader>
            </Tooltip>
          )}
          {isPDFLoading && <PDFContainer setLoading={setPDFLoading} />}
        </>
      )}
    </Container>
  );
};

export { VariationHeaderActions };
