import { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  Text,
  Row,
  Footer,
  SkipButton,
  NextButton,
  ContentAfterLogin,
  LeftTitle,
  CodeWrapper,
  SuccessTitle,
  SuccessText,
  Trigger,
} from './mfa-modal-styles';
import Icon from 'modules/common/components/ui/icon';

import { ReactComponent as Check } from 'assets/streamline-light/interface-essential/form-validation/check-double-1.svg';

import { batch, useDispatch } from 'react-redux';
import {
  login,
  setAnonymousUser,
  setUserInput,
  setUsername,
  setUserState,
  toggleIsMfaAfterLoginModalOpen,
  toggleIsMfaModalOpen,
  toggleIsRegisterModalOpen,
} from 'modules/auth/redux/authSlice';
import MfaAfterLoginContext from './mfa-after-login-context';
import { FormProvider, useForm } from 'react-hook-form';
import { InputOutsideTopLabel } from 'modules/common/components/form';
import { LoginResponse, useApplyBackupCodeMutation, UserType } from 'generated';
import { useAppSelector } from 'modules/common/hooks';
import { client } from '../../../../../services/graphql/graphql';
import { useLngHistoryPush } from 'modules/localization/lng-history-push';
import {
  resetValuationWizard,
  toggleRegisterForm,
} from 'modules/forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { resetSPC } from 'modules/forms/form-search-profile/redux/searchProfileSlice';
import { siteMap } from 'routes/site-map';
import { useHistory } from 'react-router-dom';

export const MfaAfterLoginBackupCode = () => {
  const {
    location: { pathname },
  } = useHistory();
  const lngHPush = useLngHistoryPush();

  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const { type, setPage, onClose } = useContext(MfaAfterLoginContext);

  const methods = useForm<{
    code: string;
  }>();

  const code = methods.watch('code');

  const [error, setError] = useState<string | boolean>();
  const [success, setSuccess] = useState<boolean>();

  const handleSwitchToSupport = useCallback(() => {
    setPage?.('support');
  }, [setPage]);

  const mfaChallengeMeta = useAppSelector(
    (state) => state.auth.mfaChallengeMeta
  )!;

  const [applyBackupCode, applyBackupCodeData] = useApplyBackupCodeMutation();

  const handleNext = useCallback(() => {
    if (
      mfaChallengeMeta &&
      mfaChallengeMeta.session &&
      mfaChallengeMeta.userSignInInput
    ) {
      applyBackupCode({
        type: type === 'app' ? 'app' : 'sms',
        code,
        userSignInInput: mfaChallengeMeta.userSignInInput,
      });
    }
  }, [setError, setSuccess, code]);

  const handleClose = useCallback(() => {
    onClose?.();
    dispatch(
      toggleIsMfaAfterLoginModalOpen({
        isMfaAfterLoginModalOpen: false,
        mfaChallengeMeta: undefined,
        mfaChallengeModal: undefined,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (applyBackupCodeData.isLoading) {
      setError(false);
    }

    if (applyBackupCodeData.isError) {
      setError(
        applyBackupCodeData.error.message?.includes('timeout')
          ? 'timeout'
          : 'other'
      );
    }

    if (applyBackupCodeData.isSuccess && applyBackupCodeData.data) {
      setError(false);

      const tLoginResponse = applyBackupCodeData.data
        .applyBackupCode as LoginResponse;
      if (tLoginResponse?.jwt?.token) {
        client.setHeader(
          'Authorization',
          `Bearer ${tLoginResponse?.jwt?.token ?? ''}`
        );
        batch(() => {
          dispatch(login(tLoginResponse));
          dispatch(setUserState(tLoginResponse.user.userState));
          dispatch(setAnonymousUser(false));
          dispatch(resetValuationWizard());
          dispatch(resetSPC());
        });
        const user = tLoginResponse?.user;
        const isOverviewPage = pathname.split('/')?.[2]?.length === 0;
        if (isOverviewPage) {
          if (user?.userType?.includes(UserType.Seller)) {
            lngHPush(siteMap.SellerLandingPage.path);
          } else if (user?.userType?.includes(UserType.Owner)) {
            lngHPush(siteMap.OwnerLandingPage.path);
          } else if (user?.userType?.includes(UserType.Buyer)) {
            lngHPush(siteMap.BuyerLandingPage.path);
          } else if (user?.userType?.includes(UserType.Financier)) {
            lngHPush(siteMap.FinancingLandingPage.path);
          } else {
            lngHPush(siteMap.SellerLandingPage.path);
          }
        }
        setError(false);
        setSuccess(true);
        handleClose();
      } else if (tLoginResponse.user && !tLoginResponse.jwt) {
        batch(() => {
          dispatch(toggleRegisterForm(true));
          dispatch(toggleIsRegisterModalOpen(true));
          dispatch(setUserState(tLoginResponse.user.userState));
          dispatch(
            setUserInput({
              name: tLoginResponse.user.name,
              surname: tLoginResponse.user.surname,
              phonePrefix: '',
              phone: tLoginResponse.user.phone,
              email: tLoginResponse.user.email,
              password: '',
              confirmPassword: '',
              termsAndConditions: tLoginResponse.user.termsAndConditions,
            })
          );
          dispatch(setUsername(tLoginResponse.user.username));
        });
        setError(false);
        setSuccess(true);
        handleClose();
      } else {
        setSuccess(false);
        setError(true);
      }
    }
  }, [applyBackupCodeData]);

  console.log({ error });

  useEffect(() => {
    setError(false);
  }, [code]);

  return (
    <ContentAfterLogin style={{ color: themeContext.blue }}>
      <LeftTitle content={t(`auth.mfa.after-login.backup-code.title`)} />

      <Row>
        {success ? (
          <SuccessTitle>
            <Text
              fontWeight={600}
              content={t('auth.mfa.after-login.backup-code.success-message')}
              color={themeContext.green}
            />
            <Icon
              icon={Check}
              width={24}
              height={24}
              color={themeContext.green}
            />
          </SuccessTitle>
        ) : (
          <CodeWrapper hasError={Boolean(error)}>
            <FormProvider {...methods}>
              <InputOutsideTopLabel
                type="text"
                name="code"
                label=""
                noLabel
                placeholder="e.g. 4HJ8 - Z9LK - 3G5Q"
                rules={{}}
                inputClassName="code-input"
                isRedesign
              />
            </FormProvider>
          </CodeWrapper>
        )}
      </Row>

      {error && !success && (
        <>
          <Text
            color="#C5363B"
            content={
              error === 'timeout'
                ? t('auth.mfa.after-login.backup-code.timeout')
                : t('auth.mfa.after-login.backup-code.error-code')
            }
            hasExpiredTimer
          />
          <Text content={t('auth.mfa.after-login.backup-code.error-text')} />
        </>
      )}

      {/* {success && (
        <SuccessText>
          <Trans
            i18nKey="auth.mfa.after-login.backup-code.success-text"
            components={{
              strong: <Trigger />,
            }}
            values={{
              email: 'alekse***********@timify.com',
            }}
          />
        </SuccessText>
      )} */}

      <Footer>
        <SkipButton
          style={{ padding: '12px 24px' }}
          onClick={handleSwitchToSupport}
        >
          {t(`auth.mfa.after-login.backup-code.contact-support`)}
        </SkipButton>
        {!success ? (
          <NextButton
            label={t(`auth.mfa.after-login.app.form.confirm`)}
            onClick={handleNext}
            isDisabled={code?.length !== 8 || !code}
          />
        ) : (
          <NextButton label={t(`auth.mfa.close`)} onClick={handleClose} />
        )}
      </Footer>
    </ContentAfterLogin>
  );
};
