import { easeQuadOut } from 'd3-ease';
import { interpolate } from 'd3-interpolate';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animate } from 'react-move';
import { ThemeContext } from 'styled-components';
import { useDispatch } from 'react-redux';
import { isBoolean } from 'lodash';

import { ReactComponent as Pin } from 'assets/streamline-light/maps-navigation/pins/pin.svg';
import { ReactComponent as MapIcon } from 'assets/streamline-light/maps-navigation/maps/maps.svg';
import { ReactComponent as Sun } from 'assets/svg/sun.svg';
import { ReactComponent as TripPinsIcon } from 'assets/streamline-light/maps-navigation/trip/trip-pins.svg';
import starsImage from 'assets/images/icons/financing-form/info-container/stars.png';
import { useParams } from 'react-router-dom';

import { hexToRGB } from '../../../utils/hex-to-rgb';
import { Tooltip } from '../../tooltip';
import Icon from '../icon';
import { SecondaryButton } from '../buttons';
import { useAppSelector } from '../../../hooks';
import {
  setIsFullScreenMapOpen,
  setFinancingId,
  setIsShadowMapExpanded,
} from '../../../../search-profile/pages/expose-details-overlay/redux/exposeSlice';
import { setIsShadowMapExpanded as setIsShadowMapExpandedSeller } from '../../../../seller/pages/expose-details-overlay/redux/exposeSlice';

import { toggleIsEditSearchProfileModalOpen } from '../../../../forms/form-search-profile/redux/searchProfileSlice';
import {
  Container,
  AddressContainer,
  Address,
  AddressContent,
  AddressInfoWrapper,
  RadiusInfo,
  Title,
  StarsImageContainer,
  StarsImage,
  NewPropertiesCountInfo,
  FullScreenMapButton,
  FullScreenMapButtonWithLabel,
  TooltipParagraph,
} from './address-bar-styles';
import { useLngHistoryPush } from '../../../../localization/lng-history-push';
import { siteMap } from '../../../../../routes/site-map';
import { BlueLine } from '../../../../forms/form-financing/components/containers/financing-containers/financing-info-container/form-details/item-container/item-container-styles';
import { useCreateSP } from '../../../../forms/form-search-profile/hooks/useCreateSP';
import { CreateSearchProfileInput } from '../../../../../generated';
import { useGetFinancing } from '../../../../financing/hooks/useGetFinancing';
import { defaultSPInput } from '../../../../search-profile/utils/defaultSPInput';
import { financingPropertyTypeToPropertyCode } from '../../../../search-profile/utils/financingPropertyTypeToPropertyCode';
import { purchasePriceByFinancingVariant } from '../../../../search-profile/utils/purchasePriceByFinancingReason';
import { ReactComponent as CommonFileText } from '../../../../../assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { useUpdateFinancing } from '../../../../forms/form-financing/hooks/callback-hooks/useUpdateFinancing';
import { toggleFullScreenMap } from '../../../../property/redux/dynamicMapUtilsSlice';

interface IProps {
  show?: boolean;
  address?: string;
  title?: string;
  radiusInfo?: string;
  newPropertiesCount?: number;
  onRoute?: () => void;
  showEditSPButton?: boolean | null;
  showEditValuation?: boolean | null;
  showEditFinancing?: boolean | null;
  isPropertyPage?: boolean | null;
  code?: string;
  isSkeleton?: boolean;
  isStarsImage?: boolean;
  isPropertyMarketed?: boolean;
  isShowFullMapButton?: boolean;
  isSPMobile?: boolean;
  isShadowMap?: boolean;
  isSellerShadowmap?: boolean;
}

const AddressBar = ({
  show,
  address,
  title,
  radiusInfo,
  newPropertiesCount,
  onRoute,
  showEditSPButton,
  showEditValuation,
  showEditFinancing,
  code,
  isSkeleton,
  isStarsImage,
  isPropertyMarketed,
  isShowFullMapButton,
  isPropertyPage,
  isSPMobile,
  isShadowMap,
  isSellerShadowmap,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lngHPush = useLngHistoryPush();
  const themeContext = useContext(ThemeContext);
  const isFullScreenMapOpen = useAppSelector(
    (state) => state.exposeDetails.isFullScreenMapOpen
  );
  const isFinancingOpen = useAppSelector(
    (state) => state.financing.isFinancingOpen
  );
  const [isEditButtonDisabled, setIsEditButtonDisabled] =
    useState<boolean>(false);

  const { updateFinancing } = useUpdateFinancing();

  const { financingId } = useParams<{ financingId: string }>();
  const { propertyId } = useParams<{ propertyId?: string }>();
  const isShowFullMapButtonState = isBoolean(isShowFullMapButton)
    ? isShowFullMapButton
    : true;

  const { onCreateSearchProfile } = useCreateSP();
  const {
    financingVariant,
    financingData,
    searchProfileId,
    onlineExposeId,
    vonPollProperty,
  } = useGetFinancing({
    financingId,
  });

  const addressFormatted =
    address && address.length > 30
      ? [...address.split('').slice(0, 30), '...'].join('')
      : address;

  const userId = useAppSelector((state) => state.auth.user?._id);

  const onOpenFullScreenMap = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      if (isPropertyPage) {
        dispatch(toggleFullScreenMap(true));
      } else {
        dispatch(setIsFullScreenMapOpen(true));
      }
    },
    [dispatch, isPropertyPage]
  );

  const openShadowMap = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      if (isSellerShadowmap) {
        dispatch(setIsShadowMapExpandedSeller(true));
      } else {
        dispatch(setIsShadowMapExpanded(true));
      }
    },
    [dispatch, isSellerShadowmap]
  );

  const openModal = async () => {
    if (showEditSPButton) {
      dispatch(toggleIsEditSearchProfileModalOpen(true));
    }
    if (showEditValuation) {
      // dispatch(toggleIsEditValuationModalOpen(true));
      window.open(
        `${
          siteMap.SellingProcessPage.pathWithoutParams
        }/${propertyId}?openTab=${isPropertyMarketed ? '3' : '4'}`,
        '_blank'
      );
    }
    if (showEditFinancing && financingVariant) {
      if (searchProfileId) {
        lngHPush(
          `${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfileId}`
        );
        return;
      }
      setIsEditButtonDisabled(true);
      const { financingVariantData } = financingVariant;
      const searchProfileData = defaultSPInput(
        financingPropertyTypeToPropertyCode(financingVariantData?.propertyType),
        purchasePriceByFinancingVariant(financingVariant),
        financingVariantData.location
      );
      const input: CreateSearchProfileInput = {
        countryCode: financingData?.countryCode ?? '',
        owner: userId ?? '',
        searchProfileData,
        title: financingData?.title ?? '',
      };
      const searchProfile = await onCreateSearchProfile({
        spInput: input,
        ownerId: userId ?? '',
      });
      await updateFinancing({
        financingInput: {
          _id: financingId,
          searchProfileId: searchProfile.createSearchProfile._id,
        },
      });
      setIsEditButtonDisabled(false);

      lngHPush(
        `${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfile.createSearchProfile._id}`
      );
    }
  };

  const editButtonLabel = useMemo(() => {
    if (showEditFinancing) {
      return t(
        searchProfileId
          ? 'financing-portal-page.address-bar.button.open-search-profile'
          : 'financing-portal-page.address-bar.button.open-edit-modal'
      ).toUpperCase();
    }

    if (showEditValuation) {
      return isPropertyMarketed
        ? 'VERMARKTUNGSÜBERSICHT'
        : t('property-valuation.address-bar.button.open-edit-modal');
    }

    return t('search-profile.address-bar.button.open-edit-modal');
  }, [
    isPropertyMarketed,
    searchProfileId,
    showEditFinancing,
    showEditValuation,
    t,
  ]);

  // TODO translations

  return (
    <Animate
      show={show}
      start={{
        height: 0,
        padding: 0,
      }}
      enter={{
        height: [62],
        padding: [12],
        timing: { duration: 665, ease: easeQuadOut },
      }}
      update={{
        height: [62],
        padding: [12],
        timing: { duration: 665, ease: easeQuadOut },
      }}
      leave={{
        height: [0],
        padding: [0],
        timing: { duration: 330, ease: easeQuadOut },
      }}
      interpolation={(begValue, endValue) => interpolate(begValue, endValue)}
    >
      {({ height, padding }) => (
        <Container $height={height} $padding={padding} isSPMobile={isSPMobile}>
          <AddressInfoWrapper isSPMobile={isSPMobile}>
            <Icon icon={Pin} width={24} height={24} color={themeContext.blue} />
            <AddressContent isSPMobile={isSPMobile}>
              <Title>{title?.toUpperCase() || t('your.address')}</Title>
              <AddressContainer>
                {address && address.length > 30 ? (
                  <Tooltip
                    id={`address-bar-${address.length}`}
                    content={<Address $cursor>{addressFormatted}</Address>}
                  >
                    <TooltipParagraph>{address}</TooltipParagraph>
                  </Tooltip>
                ) : (
                  <Address>
                    {addressFormatted || (isSkeleton ? <BlueLine /> : '')}
                  </Address>
                )}
                {radiusInfo && (
                  <RadiusInfo>
                    <p>{radiusInfo}</p>
                  </RadiusInfo>
                )}
                {!!newPropertiesCount && (
                  <NewPropertiesCountInfo>
                    <p>{newPropertiesCount} NEU</p>
                  </NewPropertiesCountInfo>
                )}
              </AddressContainer>
            </AddressContent>
            {isStarsImage && !isSPMobile && (
              <StarsImageContainer>
                <StarsImage src={starsImage} alt="stars image" />
              </StarsImageContainer>
            )}
          </AddressInfoWrapper>

          {vonPollProperty &&
            searchProfileId &&
            onlineExposeId &&
            !isFinancingOpen && (
              <SecondaryButton
                label={t('search-profile.suggested-properties.expose-details')}
                onClick={() => {
                  dispatch(setFinancingId(financingId));
                  lngHPush(
                    `${siteMap.SearchProfilePage.pathWithoutParams}/${searchProfileId}/?online-expose=${onlineExposeId}&property-id=${vonPollProperty.propertyId}`
                  );
                }}
                icon={CommonFileText}
                iconStyle={{
                  padding: '3px',
                  margin: '0 8px 0 0',
                }}
                color={themeContext.blue}
                borderColor={themeContext.blue}
                backgroundColor={hexToRGB('#e5e9ec', 0.9)}
                style={{
                  padding: '12px 10px',
                  minWidth: 'fit-content',
                  height: '40px',
                  whiteSpace: 'nowrap',
                  marginRight:
                    code === 'multi_family_house' || code === 'plot'
                      ? '45px'
                      : '',
                }}
              />
            )}

          {(showEditSPButton ||
            showEditValuation ||
            (showEditFinancing && !onlineExposeId)) && (
            <SecondaryButton
              label={editButtonLabel}
              onClick={openModal}
              isDisabled={isEditButtonDisabled}
              color={themeContext.blue}
              borderColor={themeContext.blue}
              backgroundColor={hexToRGB('#e5e9ec', 0.9)}
              style={{
                padding: '12px 10px',
                minWidth: 'fit-content',
                height: '40px',
                whiteSpace: 'nowrap',
                marginRight:
                  code === 'multi_family_house' || code === 'plot'
                    ? '45px'
                    : '',
              }}
            />
          )}
          {!isSPMobile && (
            <>
              {!isFullScreenMapOpen &&
                isShowFullMapButtonState &&
                !isShadowMap && (
                  <FullScreenMapButton
                    iconWidth={20}
                    iconHeight={20}
                    label={''}
                    icon={MapIcon}
                    onClick={onOpenFullScreenMap}
                  />
                )}
              {isShadowMap && (
                <FullScreenMapButtonWithLabel
                  iconWidth={24}
                  iconHeight={24}
                  label={t('shadowmap.suntour')}
                  icon={Sun}
                  onClick={openShadowMap}
                />
              )}
              {isFullScreenMapOpen && (
                <FullScreenMapButton
                  iconWidth={20}
                  iconHeight={20}
                  label={''}
                  icon={TripPinsIcon}
                  onClick={onRoute}
                />
              )}
            </>
          )}
        </Container>
      )}
    </Animate>
  );
};

export { AddressBar };
