import styled from 'styled-components';
import { device } from '../../../../../../style/theme';
import { Headline1 } from '../../../../../common/components/ui/typography';
import {
  FlatButton,
  MainButton,
  SecondaryButton,
} from '../../../../../common/components/ui/buttons';
import { hexToRGB } from '../../../../../common/utils/hex-to-rgb';
import Icon from '../../../../../common/components/ui/icon';

export const PropertiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 1fr 1fr;
  grid-gap: 12px;
  margin-top: 32px;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    margin-top: 12px;
  }
`;

export const Header = styled(Headline1)`
  text-align: center;
  position: relative;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const ShowMoreButton = styled(FlatButton)`
  text-transform: uppercase;
  display: flex;
  max-width: 300px;
  align-self: center;
  margin-top: 24px;
  justify-content: center;

  @media ${device.tablet} {
    min-width: 250px;
  }

  @media ${device.tablet} {
    min-width: 100%;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    margin-top: 24px;
  }
`;

export const CardWrapper = styled.div<{
  isListView?: boolean;
  isDisabled?: boolean;
}>`
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  border-radius: 5px;
  overflow: hidden;
  border: solid 1px ${(props) => props.theme.ctaDisabledBackground};
  background-color: #fff;
  ${(props) =>
    !props.isListView
      ? `
    min-height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
  `
      : ''}/* .offer-price {
    ${(props) =>
    props.isListView
      ? `
      border-top: 1px solid ${props.theme.ctaDisabledBackground}
    `
      : `
          flex-grow: 2;
          display: flex;
          flex-direction: column;
          width: 100%;

          .content {
            flex-grow: 2;
            display: flex;
            flex-direction: column;
            width: 100%;

            .text {
              flex-grow: 2;
            }

            .form-container {
              @media ${device.mobile} {
                flex-direction: column;
                gap: 8px;
                align-items: stretch;
                .input-wrapper > div {
                  max-width: none;
                }
                button {
                  width: 100%;
                }
              }
            }
          }
        `}
  } */
`;

export const CardContainer = styled.div<{
  isListView?: boolean;
  isDisabled?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => (props.isListView ? 'row' : 'column')};
  width: 100%;
  overflow: hidden;
  max-height: ${(props) => (props.isListView ? 'unset' : 'unset')};
  min-width: ${(props) => (props.isListView ? '100%' : 'unset')};
  position: relative;
`;

export const ImageWrapper = styled.button<{ isListView?: boolean }>`
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;

  ${({ isListView }) =>
    !isListView
      ? `
    height: 0;
    padding-top: calc(147 / 306 * 100%);
  `
      : ''}
  position: relative;

  width: ${(props) => (props.isListView ? '300px' : 'unset')};
  overflow: ${(props) => (props.isListView ? 'hidden' : 'unset')};
`;

export const CardImage = styled.img<{ isListView?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: ${(props) => (props.isListView ? 'scale(1.4)' : 'unset')};
`;

export const CardContent = styled.div<{ isListView?: boolean }>`
  flex: 1;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
  border-right: solid 1px
    ${(props) =>
      props.isListView ? props.theme.ctaDisabledBackground : 'transparent'};
`;

export const CardTitle = styled.button`
  padding: 0;
  text-decoration: underline;
  border: none;
  text-align: left;
  background: transparent;
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  color: ${(props) => props.theme.blue};
  margin-bottom: 12px;
  cursor: pointer;
`;

export const Row = styled.div<{
  justifyContent?: string;
  margin?: string;
  alignItems?: string;
  padding?: string;
  isListView?: boolean;
}>`
  display: flex;
  align-self: stretch;
  .icon {
    min-width: 24px;
    min-height: 24px;
  }
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};

  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};

  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};

  border-right: 1px solid
    ${({ theme, isListView }) =>
      isListView ? theme.ctaDisabledBackground : 'transparent'};
`;

export const Tag = styled.button`
  position: absolute;
  bottom: 12px;
  right: 12px;
  padding: 2px 8px;
  border-radius: 5px;
  align-self: center;
  border: solid 1px ${(props) => hexToRGB(props.theme.blue, 0.15)};
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.blue};
  cursor: pointer;
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
`;

export const PriceContainer = styled.div`
  padding: 4px 8px;
  border-radius: 5px;
  background-color: #e5eaef;
  margin: 16px 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 24px;
`;

export const Price = styled.span`
  font-family: 'Source Serif Pro';
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
  margin-right: 12px;
`;

export const Location = styled.div<{ maxWidth?: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${({ maxWidth }) =>
    maxWidth
      ? `max-width: ${maxWidth}px;`
      : `
    max-width: 220px;
    @media ${device.laptop} {
      max-width: 150px;
    }
    @media ${device.tablet} {
      max-width: 240px;
    }
  `};
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};

  @media ${device.tablet} {
    white-space: normal;
  }
`;

export const SquareMeterPrice = styled(Price)`
  font-weight: 400;
  margin: 0;
  sup {
    font-size: 8px;
  }
`;

export const NewLabel = styled.div`
  z-index: 1;
  top: 12px;
  left: 12px;
  position: absolute;
  width: 40px;
  height: 22px;
  padding: 4px 8px;
  border-radius: 5px;
  background-color: #74a257;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  color: #fff;
`;

export const FavoriteButton = styled.button<{ isLiked?: boolean }>`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  padding: 0;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.isLiked &&
    `
      background-color: ${props.theme.blue};
      border-color: ${props.theme.blue};
  `}
`;

export const IconContainer = styled.div<{
  alignAtEnd?: boolean;
  disabled?: boolean;
}>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
  ${({ alignAtEnd }) =>
    alignAtEnd &&
    `
    > div {
      justify-content: flex-end
    }
  `}

  i {
    transition: none;
  }
`;

export const IconDigit = styled.div`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${(props) => props.theme.blue};
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  sup {
    font-size: 8px;
  }
`;

export const IconLabel = styled.div`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: ${(props) => hexToRGB(props.theme.blue, 0.6)};
`;

export const RequestExposeButton = styled(MainButton)<{
  isListView?: boolean;
  isMobileSize?: boolean;
  disabled?: boolean;
  isUnlocked?: boolean;
}>`
  white-space: nowrap;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  height: 40px;
  padding: 12px;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
  justify-content: center;
  width: ${(props) =>
    props.isMobileSize ? '100%' : props.isListView ? '40px' : 'fit-content'};
  ${(props) =>
    props.isUnlocked &&
    `
    background-color: ${props.theme.white};
    border: 1px solid #00305E;
    color: #00305E;
    svg, path {
      stroke: #00305E !important;
    }
  `};

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? props.theme.ctaBlue
        : props.isUnlocked
        ? hexToRGB(props.theme.ctaDisabledBackground, 0.2)
        : props.theme.ctaBlueHover};
  }

  @media ${device.tablet} {
    height: 32px;
  }
`;

export const ExposeButtonsBox = styled.div`
  display: flex;
  gap: 10px;

  @media ${device.tablet} {
    flex-direction: column;
    gap: 16px;
  }
`;

export const ActionButton = styled(SecondaryButton)<{
  width?: number;
  margin?: string;
}>`
  padding: 12px;
  height: 40px;
  justify-content: center;
  width: ${(props) => (props.width ? `${props.width}px` : 'fit-content')};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  border-radius: 5px;
  border-width: 1px;

  @media ${device.tablet} {
    height: 32px;
  }
`;

export const ActionButtonTooltipBox = styled.div`
  width: 100%;

  .tooltip-inner-container {
    width: 100%;
  }
`;

export const LocationIcon = styled(Icon)`
  display: flex;
  align-items: center;
  width: initial;
  margin-right: 8px;
  &&& > svg {
    max-height: 16px;
  }
`;

export const LoaderContainer = styled.div`
  height: 300px;
  position: relative;
  width: 100%;

  &&&& {
    > div {
      position: absolute;
    }
  }
`;

export const PropertyDetailsBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
  font-family: 'Roboto';
  font-size: 14px;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.blue};
`;

export const PropertyDetailsBoxKey = styled.span`
  font-weight: 400;
  width: 155px;
`;

export const PropertyDetailsBoxValue = styled.span`
  font-weight: 600;
  sup {
    font-size: 8px;
  }
`;
