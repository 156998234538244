import { useEffect, memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Icon from '../../../../../../common/components/ui/icon';
import { useIsMobileSize } from '../../../../../../common/hooks/useIsMobileSize';
import {
  setIsShadowMapExpanded,
  setIsShadowMapOpen,
} from '../../../redux/exposeSlice';
import {
  BlueIcon,
  Container,
  Description,
  GrayIcon,
  GuideImage,
  Hr,
  IconImageBox,
  IconImages,
  IconImageTitle,
  ImagesBox,
  ImgBox,
  ImgTitle,
  List,
  ListTitle,
  NestedListTitle,
  Title,
  ShowShadowmapButton,
} from './shadowmap-styles';
import MouseMoveImage from 'assets/mouse-move.png';
import MoseMoveRightImage from 'assets/mouse-move-right.png';
import MouseZoomImage from 'assets/mouse-zoom.png';
import SuntourGuide from 'assets/suntour-guide.png';
import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg';
import { ReactComponent as CompasIcon } from 'assets/svg/compas.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/svg/minus.svg';

interface IProps {
  isMobileInfo?: boolean;
}

const Shadowmap: FC<IProps> = ({ isMobileInfo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();

  const openShadowMap = () => {
    dispatch(setIsShadowMapExpanded(true));
  };

  useEffect(() => {
    if (!isMobileInfo) {
      dispatch(setIsShadowMapOpen(true));
    }

    return () => {
      if (!isMobileInfo) {
        dispatch(setIsShadowMapOpen(false));
      }
    };
  }, [dispatch, isMobileInfo]);

  return (
    <Container>
      <Title>{t('shadowmap.how-to.title')}</Title>
      <Description>{t('shadowmap.how-to.description')}</Description>

      <ListTitle>{t('shadowmap.map-navigation.title')}</ListTitle>
      <List>
        <li>{t('shadowmap.map-navigation.description1')}</li>
        <li>{t('shadowmap.map-navigation.description2')}</li>
        <li>{t('shadowmap.map-navigation.description3')}</li>
      </List>

      <ImagesBox>
        <ImgBox>
          <img
            width={82}
            height={46}
            src={MouseMoveImage}
            alt={t('shadowmap.map-navigation.image-title1')}
          />
          <ImgTitle>{t('shadowmap.map-navigation.image-title1')}</ImgTitle>
        </ImgBox>
        <ImgBox>
          <img
            width={82}
            height={46}
            src={MoseMoveRightImage}
            alt={t('shadowmap.map-navigation.image-title2')}
          />
          <ImgTitle>{t('shadowmap.map-navigation.image-title2')}</ImgTitle>
        </ImgBox>
        <ImgBox>
          <img
            width={32}
            height={46}
            src={MouseZoomImage}
            alt={t('shadowmap.map-navigation.image-title3')}
          />
          <ImgTitle>{t('shadowmap.map-navigation.image-title3')}</ImgTitle>
        </ImgBox>
      </ImagesBox>

      <Hr />

      <ListTitle>{t('shadowmap.place-time.title')}</ListTitle>
      <List>
        <li
          dangerouslySetInnerHTML={{
            __html: t('shadowmap.place-time.description1'),
          }}
        />
        <li
          dangerouslySetInnerHTML={{
            __html: t('shadowmap.place-time.description2'),
          }}
        />
        <li
          dangerouslySetInnerHTML={{
            __html: t('shadowmap.place-time.description3'),
          }}
        />
        <li
          dangerouslySetInnerHTML={{
            __html: t('shadowmap.place-time.description4'),
          }}
        />
        <li
          dangerouslySetInnerHTML={{
            __html: t('shadowmap.place-time.description5'),
          }}
        />
      </List>

      <GuideImage src={SuntourGuide} alt={'sun tour guide'} />

      <Hr />

      <ListTitle>{t('shadowmap.aditional-functions.title')}</ListTitle>
      <List>
        <li
          dangerouslySetInnerHTML={{
            __html: t('shadowmap.aditional-functions.description1'),
          }}
        />
        <li
          dangerouslySetInnerHTML={{
            __html: t('shadowmap.aditional-functions.description2'),
          }}
        />
        <li
          dangerouslySetInnerHTML={{
            __html: t('shadowmap.aditional-functions.description3'),
          }}
        />
        <li
          dangerouslySetInnerHTML={{
            __html: t('shadowmap.aditional-functions.description4'),
          }}
        />
      </List>

      <IconImages>
        <IconImageBox>
          <BlueIcon>
            <Icon
              icon={SettingsIcon}
              width={16}
              height={16}
              color={'#ffffff'}
            />
          </BlueIcon>
          <IconImageTitle>
            {t('shadowmap.aditional-functions.image-title1')}
          </IconImageTitle>
        </IconImageBox>

        <IconImageBox>
          <GrayIcon>
            <Icon icon={CompasIcon} width={28} height={28} color={'#00305E'} />
          </GrayIcon>
          <IconImageTitle>
            {t('shadowmap.aditional-functions.image-title2')}
          </IconImageTitle>
        </IconImageBox>

        <IconImageBox>
          <GrayIcon>
            <Icon icon={PlusIcon} width={16} height={16} color={'#00305E'} />
          </GrayIcon>
          <IconImageTitle>
            {t('shadowmap.aditional-functions.image-title3')}
          </IconImageTitle>
        </IconImageBox>

        <IconImageBox>
          <GrayIcon>
            <Icon icon={MinusIcon} width={16} height={16} color={'#00305E'} />
          </GrayIcon>
          <IconImageTitle>
            {t('shadowmap.aditional-functions.image-title4')}
          </IconImageTitle>
        </IconImageBox>
      </IconImages>

      <Hr />

      <ListTitle>{t('shadowmap.settings.title')}</ListTitle>
      <List>
        <li
          dangerouslySetInnerHTML={{
            __html: t('shadowmap.settings.description'),
          }}
        />
        <li>
          <NestedListTitle>
            {t('shadowmap.settings.general.title')}
          </NestedListTitle>
          <List>
            <li
              dangerouslySetInnerHTML={{
                __html: t('shadowmap.settings.general.description1'),
              }}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: t('shadowmap.settings.general.description2'),
              }}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: t('shadowmap.settings.general.description3'),
              }}
            />
          </List>
        </li>
      </List>

      {isMobileSize && !isMobileInfo && (
        <ShowShadowmapButton
          label={t('shadowmap.suntour')}
          onClick={openShadowMap}
        />
      )}
    </Container>
  );
};

export default memo(Shadowmap);
