import { Modal } from 'modules/common/components/modal';
import {
  HeadTitle,
  Text,
  ContainerAfterLogin,
  ContainerAfterLoginLeft,
  ContainerAfterLoginRight,
  LeftTitle,
  LeftSecondaryTitle,
  ContentAfterLogin,
} from './mfa-modal-styles';
import { useTranslation } from 'react-i18next';
import Icon from 'modules/common/components/ui/icon';
import { ThemeContext } from 'styled-components';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ReactComponent as Qr } from 'assets/streamline-light/phones-mobile-devices/qr-code/qr-code-scan.svg';
import { ReactComponent as Sms } from 'assets/streamline-light/messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-typing.svg';
import { batch, useDispatch } from 'react-redux';
import {
  toggleIsMfaAfterLoginModalOpen,
  toggleMfaChallengeMeta,
} from 'modules/auth/redux/authSlice';
import { useAppSelector } from 'modules/common/hooks';
import { MfaAfterLoginPage, MfaType } from './mfa-interfaces';
import MfaAfterLoginContext from './mfa-after-login-context';
import { MfaAfterLoginApp } from './mfa-after-login-app';
import { MfaAfterLoginSms } from './mfa-after-login-sms';
import { MfaAfterLoginBackupCode } from './mfa-after-login-backup-code';
import { MfaAfterLoginSupport } from './mfa-after-login-support';
import {
  AuthChallengeFieldsFragment,
  useSignInMutation,
  useSmsRequestTotpPhoneMutation,
} from 'generated';

const MfaAfterLoginModal = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const [page, setPage] = useState<MfaAfterLoginPage>('code');
  const [code, setCode] = useState<string>();
  const [smsExpires, setSmsExpires] = useState<number>();

  const [smsSentTo, setSmsSentTo] = useState<string>('');

  const onClose = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      batch(() => {
        setPage('code');
        dispatch(
          toggleIsMfaAfterLoginModalOpen({ isMfaAfterLoginModalOpen: false })
        );
      });
    },
    [dispatch]
  );

  const mfaChallengeMeta = useAppSelector(
    (state) => state.auth.mfaChallengeMeta
  );

  const [signIn, signInData] = useSignInMutation();

  const resendSmsCode = () => {
    if (mfaChallengeMeta?.userSignInInput) {
      signIn({ input: mfaChallengeMeta.userSignInInput });
    }
  };

  useEffect(() => {
    if (signInData.isLoading) {
      return;
    }

    if (signInData.isSuccess && signInData.data.signIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const loginResponse = signInData.data.signIn as any;

      if (loginResponse.challengeName) {
        const { challengeParameters, session } =
          loginResponse as AuthChallengeFieldsFragment;

        dispatch(
          toggleMfaChallengeMeta({
            srpId: challengeParameters.USER_ID_FOR_SRP,
            session,
          })
        );
      }
      setSmsExpires(60);
    }
  }, [signInData]);

  const type = useAppSelector((state) => state.auth.mfaChallengeModal);
  const isOpen = Boolean(type);

  // Can't access user personal data without active session
  // const [queryTwoFaPhone, queryTwoFaPhoneData] =
  //   useSmsRequestTotpPhoneMutation();

  // useEffect(() => {
  //   if (type === 'sms') queryTwoFaPhone();
  // }, [type]);

  // useEffect(() => {
  //   if (
  //     queryTwoFaPhoneData.isSuccess &&
  //     queryTwoFaPhoneData.data?.smsRequestTotpPhone.phone
  //   ) {
  //     setSmsSentTo(queryTwoFaPhoneData.data.smsRequestTotpPhone.phone);
  //     setSmsExpires(60);
  //   }
  // }, [queryTwoFaPhoneData]);

  useEffect(() => {
    setSmsExpires(60);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      wrapperStyles={{
        padding: 0,
        width: '90vw',
        maxWidth: '960px',
        minHeight: '500px',
        maxHeight: '90vh',
        overflowY: 'auto',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
      onClose={onClose}
    >
      <MfaAfterLoginContext.Provider
        value={{
          page,
          setPage,
          type,
          code,
          setCode,
          smsExpires,
          setSmsExpires,
          resendSmsCode,
          onClose,
        }}
      >
        <ContainerAfterLogin>
          <ContainerAfterLoginLeft>
            <ContentAfterLogin>
              <LeftTitle content={t(`auth.mfa.after-login.${type}.title`)} />
              <HeadTitle style={{ gap: 8 }}>
                <Icon
                  icon={type === 'app' ? Qr : Sms}
                  width={24}
                  height={24}
                  color={themeContext.white}
                />
                <LeftSecondaryTitle
                  content={t(`auth.mfa.after-login.${type}.subtitle`)}
                />
              </HeadTitle>
              <Text
                color={'inherit'}
                content={t(`auth.mfa.after-login.${type}.text`)}
              />
            </ContentAfterLogin>
          </ContainerAfterLoginLeft>
          <ContainerAfterLoginRight>
            {page === 'code' && type === 'app' && <MfaAfterLoginApp />}
            {page === 'code' && type === 'sms' && (
              <MfaAfterLoginSms smsSentTo={smsSentTo} />
            )}
            {page === 'backup-code' && <MfaAfterLoginBackupCode />}
            {page === 'support' && <MfaAfterLoginSupport />}
          </ContainerAfterLoginRight>
        </ContainerAfterLogin>
      </MfaAfterLoginContext.Provider>
    </Modal>
  );
};

export { MfaAfterLoginModal };
