import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  Footer,
  SkipButton,
  NextButton,
  ContentAfterLogin,
  LeftTitle,
} from './mfa-modal-styles';
import { batch, useDispatch } from 'react-redux';
import {
  login,
  setAnonymousUser,
  setUserInput,
  setUsername,
  setUserState,
  toggleIsMfaAfterLoginModalOpen,
  toggleIsMfaModalOpen,
  toggleIsRegisterModalOpen,
} from 'modules/auth/redux/authSlice';
import { client } from '../../../../../services/graphql/graphql';
import MfaAfterLoginContext from './mfa-after-login-context';
import { MfaCodeAfterLoginInput } from './mfa-code-after-login-input';
import {
  LoginResponse,
  useCognitoRespondToAuthChallengeMutation,
  UserType,
  useValidateTotpMutation,
} from 'generated';
import { useAppSelector } from 'modules/common/hooks';
import {
  resetValuationWizard,
  toggleRegisterForm,
} from 'modules/forms/form-property-valuation-wizard/redux/valuationWizardSlice';
import { resetSPC } from 'modules/forms/form-search-profile/redux/searchProfileSlice';
import { useHistory } from 'react-router-dom';
import { siteMap } from 'routes/site-map';
import { useLngHistoryPush } from 'modules/localization/lng-history-push';

export const MfaAfterLoginApp = () => {
  const {
    location: { pathname },
  } = useHistory();
  const lngHPush = useLngHistoryPush();

  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const mfaChallengeMeta = useAppSelector(
    (state) => state.auth.mfaChallengeMeta
  );

  const { setPage, code } = useContext(MfaAfterLoginContext);

  const [error, setError] = useState<string | boolean>();

  const handleSwitchToBackupCodes = useCallback(() => {
    setPage?.('backup-code');
  }, [setPage]);

  const [cognitoRespondToAuthChallenge, cognitoRespondToAuthChallengeData] =
    useCognitoRespondToAuthChallengeMutation();

  const handleNext = useCallback(() => {
    if (
      code &&
      mfaChallengeMeta &&
      mfaChallengeMeta.session &&
      mfaChallengeMeta.srpId &&
      mfaChallengeMeta.userSignInInput.userAuthenticationKey
    ) {
      cognitoRespondToAuthChallenge({
        type: 'app',
        code,
        session: mfaChallengeMeta.session,
        srpId: mfaChallengeMeta.srpId,
        email: mfaChallengeMeta.userSignInInput.userAuthenticationKey,
      });
    }
  }, [code]);

  const handleClose = useCallback(() => {
    setPage?.('code');
    dispatch(toggleIsMfaModalOpen(false));
    dispatch(
      toggleIsMfaAfterLoginModalOpen({
        isMfaAfterLoginModalOpen: false,
        mfaChallengeMeta: undefined,
        mfaChallengeModal: undefined,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (cognitoRespondToAuthChallengeData.isLoading) return;

    if (
      cognitoRespondToAuthChallengeData.isSuccess &&
      cognitoRespondToAuthChallengeData.data
    ) {
      setError(false);

      const tLoginResponse = cognitoRespondToAuthChallengeData.data
        .cognitoRespondToAuthChallenge as LoginResponse;
      if (tLoginResponse?.jwt?.token) {
        client.setHeader(
          'Authorization',
          `Bearer ${tLoginResponse?.jwt?.token ?? ''}`
        );
        batch(() => {
          dispatch(login(tLoginResponse));
          dispatch(setUserState(tLoginResponse.user.userState));
          dispatch(setAnonymousUser(false));
          dispatch(resetValuationWizard());
          dispatch(resetSPC());
        });
        const user = tLoginResponse?.user;
        const isOverviewPage = pathname.split('/')?.[2]?.length === 0;
        if (isOverviewPage) {
          if (user?.userType?.includes(UserType.Seller)) {
            lngHPush(siteMap.SellerLandingPage.path);
          } else if (user?.userType?.includes(UserType.Owner)) {
            lngHPush(siteMap.OwnerLandingPage.path);
          } else if (user?.userType?.includes(UserType.Buyer)) {
            lngHPush(siteMap.BuyerLandingPage.path);
          } else if (user?.userType?.includes(UserType.Financier)) {
            lngHPush(siteMap.FinancingLandingPage.path);
          } else {
            lngHPush(siteMap.SellerLandingPage.path);
          }
        }
        handleClose();
      } else if (tLoginResponse.user && !tLoginResponse.jwt) {
        batch(() => {
          dispatch(toggleRegisterForm(true));
          dispatch(toggleIsRegisterModalOpen(true));
          dispatch(setUserState(tLoginResponse.user.userState));
          dispatch(
            setUserInput({
              name: tLoginResponse.user.name,
              surname: tLoginResponse.user.surname,
              phonePrefix: '',
              phone: tLoginResponse.user.phone,
              email: tLoginResponse.user.email,
              password: '',
              confirmPassword: '',
              termsAndConditions: tLoginResponse.user.termsAndConditions,
            })
          );
          dispatch(setUsername(tLoginResponse.user.username));
        });
        handleClose();
      }
    } else if (
      (!cognitoRespondToAuthChallengeData.isUninitialized &&
        !cognitoRespondToAuthChallengeData.data) ||
      cognitoRespondToAuthChallengeData.error
    ) {
      setError(
        cognitoRespondToAuthChallengeData.error.message?.includes('timeout') ||
          cognitoRespondToAuthChallengeData.error.message?.includes(
            'too.many.invalid.credentials'
          )
          ? 'timeout'
          : 'other'
      );
    }
  }, [cognitoRespondToAuthChallengeData]);

  useEffect(() => {
    setError(false);
  }, [code]);

  return (
    <ContentAfterLogin style={{ color: themeContext.blue }}>
      <LeftTitle content={t(`auth.mfa.after-login.app.form.title`)} />

      <MfaCodeAfterLoginInput
        hasError={Boolean(error)}
        setHasError={setError}
        isRequestTimeoutError={error === 'timeout'}
      />
      <Footer>
        <SkipButton
          style={{ padding: '12px 24px' }}
          onClick={handleSwitchToBackupCodes}
        >
          {t(`auth.mfa.after-login.app.form.backup-codes`)}
        </SkipButton>
        <NextButton
          label={t(`auth.mfa.after-login.app.form.confirm`)}
          onClick={handleNext}
          isDisabled={code?.length !== 6 || !code || Boolean(error)}
        />
      </Footer>
    </ContentAfterLogin>
  );
};
