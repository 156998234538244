import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature } from '../../../../services/mapbox/interfaces';
import { FormError } from '../../interface';
import { ISearchProfileForm } from '../interface';
import { CreateTemporarySearchProfileMutation } from 'generated';
import { IMapPin, IMapSettings } from 'modules/map/interfaces';
import { Europe } from 'modules/map/constants/points-of-interest';
import { defaultMapSettings } from 'modules/map/constants';

interface IState {
  isSPCOpen: boolean;
  isRegisterFormOpen: boolean;
  isEditSearchProfileModalOpen: boolean;
  isNotificationModalOpen: boolean;
  isLoginFormOpen: boolean;
  isLoading: boolean;
  activeStep: number;
  errors?: FormError;
  userInput?: Partial<ISearchProfileForm>;
  isRedirect?: boolean;
  selectedAddress?: Feature;
  selectedCountryCode?: string;
  relatedCountry: string;
  regionalBrokerId?: string;
  preventCleanupOnInit?: boolean;
  temporarySearchProfileToClaim:
    | CreateTemporarySearchProfileMutation['createTemporarySearchProfile']
    | null;
  isSuggestedOutdated: boolean;
  map: {
    viewport: Record<string, unknown>;
    settings: IMapSettings;
    viewportCheckpoint?: Record<string, unknown>;
    markersCheckpoint?: IMapPin[];
    settingsCheckpoint?: IMapSettings;
  };
}

const initialState: IState = {
  isSPCOpen: false,
  isEditSearchProfileModalOpen: false,
  isNotificationModalOpen: false,
  isRegisterFormOpen: false,
  isLoginFormOpen: false,
  isLoading: false,
  relatedCountry: 'germany',
  activeStep: 0,
  isRedirect: false,
  temporarySearchProfileToClaim: null,
  isSuggestedOutdated: false,
  map: {
    viewport: { ...Europe },
    settings: defaultMapSettings,
  },
};

export const searchProfileSlice = createSlice({
  name: 'searchProfile',
  initialState,
  reducers: {
    openSPCAction(
      state,
      action?: PayloadAction<
        { firstStepFormData: IState['userInput'] } | undefined
      >
    ) {
      if (action?.payload?.firstStepFormData) {
        state.userInput = action.payload.firstStepFormData;
        state.preventCleanupOnInit = true;
      }

      state.isSPCOpen = true;
    },
    closeSPCAction(state) {
      state.isSPCOpen = false;
      state.isRegisterFormOpen = false;
      state.isLoginFormOpen = false;
      state.activeStep = 0;
      state.selectedCountryCode = '';
    },
    resetSPC(
      state,
      action?: PayloadAction<
        { resetTemporarySearchProfile?: boolean } | undefined
      >
    ) {
      let nextTempSP = state.temporarySearchProfileToClaim;
      if (
        action &&
        action.payload &&
        !action.payload.resetTemporarySearchProfile
      ) {
        nextTempSP = state.temporarySearchProfileToClaim;
      }
      return { ...initialState, temporarySearchProfileToClaim: nextTempSP };
    },
    nextStepAction(state) {
      state.activeStep += 1;
    },
    prevStepAction(state) {
      state.activeStep -= 1;
    },
    updateFormStateAction(
      state,
      action: PayloadAction<Partial<ISearchProfileForm> | undefined>
    ) {
      state.userInput = {
        ...state.userInput,
        ...action.payload,
      };
    },
    setSelectedAddressAction(state, action: PayloadAction<Feature>) {
      state.selectedAddress = action.payload;
    },
    setRedirect(state, action: PayloadAction<boolean>) {
      state.isRedirect = action.payload;
    },
    setSelectedCountryCode(state, action: PayloadAction<string>) {
      state.selectedCountryCode = action.payload;
    },
    toggleIsEditSearchProfileModalOpen(state, action: PayloadAction<boolean>) {
      state.isEditSearchProfileModalOpen = action.payload;
    },
    toggleIsNotificationModalOpen(state, action: PayloadAction<boolean>) {
      state.isNotificationModalOpen = action.payload;
    },
    togglePreventCleanupOnInit(
      state,
      action: PayloadAction<IState['preventCleanupOnInit']>
    ) {
      state.preventCleanupOnInit = action.payload;
    },
    toggleRegisterForm(state, action: PayloadAction<boolean>) {
      state.isRegisterFormOpen = action.payload;
    },
    toggleLoginForm(state, action: PayloadAction<boolean>) {
      state.isLoginFormOpen = action.payload;
    },
    toggleLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setErrors(state, action: PayloadAction<FormError>) {
      state.errors = action.payload;
    },
    clearErrors(state) {
      delete state.errors;
    },
    changeMapViewport(state, action) {
      state.map.viewportCheckpoint = state.map.viewport;
      state.map.viewport = { ...state.map.viewport, ...action.payload };
    },
    setMapSettings(state, action: PayloadAction<Partial<IMapSettings>>) {
      state.map.settingsCheckpoint = state.map.settings;
      state.map.settings = {
        ...state.map.settings,
        ...action.payload,
      };
    },
    updateTemporarySearchProfileToClaim(
      state,
      action: PayloadAction<IState['temporarySearchProfileToClaim']>
    ) {
      state.temporarySearchProfileToClaim = action.payload;
    },
    updateIsSuggestedOutdated(
      state,
      action: PayloadAction<IState['isSuggestedOutdated']>
    ) {
      state.isSuggestedOutdated = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openSPCAction,
  closeSPCAction,
  nextStepAction,
  prevStepAction,
  updateFormStateAction,
  setRedirect,
  setSelectedAddressAction,
  setSelectedCountryCode,
  toggleLoading,
  togglePreventCleanupOnInit,
  toggleIsNotificationModalOpen,
  toggleIsEditSearchProfileModalOpen,
  toggleLoginForm,
  toggleRegisterForm,
  setErrors,
  clearErrors,
  resetSPC,
  setMapSettings,
  changeMapViewport,
  updateTemporarySearchProfileToClaim,
  updateIsSuggestedOutdated,
} = searchProfileSlice.actions;

export default searchProfileSlice.reducer;
