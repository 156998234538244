import { SubmitHandler } from 'react-hook-form';
import { batch, useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { ILoginForm } from '../../../auth/interfaces';
import {
  login,
  setAnonymousUser,
  setUserInput,
  setUserState,
  toggleIsLoginModalOpen,
  toggleIsMfaAfterLoginModalOpen,
  toggleIsRegisterModalOpen,
  toggleLoading,
} from '../../../auth/redux/authSlice';
import {
  AuthChallengeFieldsFragment,
  LoginResponse,
  SignInMutation,
  useSignInMutation,
} from '../../../../generated';
import { client } from '../../../../services/graphql/graphql';
import { ISearchProfileForm } from '../interface';
import {
  resetSPC,
  toggleLoginForm,
  toggleRegisterForm,
} from '../redux/searchProfileSlice';
import { useAppSelector } from '../../../common/hooks';

type TProps = {
  setErrors: (state: null | unknown) => void;
  onSuccess?: (formData: ISearchProfileForm, ownerId: string) => Promise<void>;
};

type TReturn = {
  onLoginSubmit: (formData: ILoginForm) => void;
};

const useLoginSP = ({ setErrors, onSuccess }: TProps): TReturn => {
  const dispatch = useDispatch();

  const [loginData, setLoginData] = useState<
    ILoginForm & { requestAccessToken: boolean }
  >();
  const [inputPassword, setInputPassword] = useState('');

  const userInput = useAppSelector((state) => state.searchProfile.userInput);
  const regionalBrokerId = useAppSelector(
    (state) => state.searchProfile.regionalBrokerId
  );

  // Mutations
  const [signIn, { data: signInData }] = useSignInMutation();

  const onLoginSubmit: SubmitHandler<ILoginForm> = useCallback(
    async (formData) => {
      const { userAuthenticationKey, password } = formData;
      setInputPassword(password);

      if (userAuthenticationKey && password) {
        try {
          dispatch(toggleLoading(true));
          setLoginData({ ...formData, requestAccessToken: true });

          await signIn({
            input: {
              userAuthenticationKey,
              password,
              requestAccessToken: true,
              regionalBrokerId,
            },
          }).unwrap();
        } catch (error: unknown) {
          setErrors(error);
        } finally {
          dispatch(toggleLoading(false));
        }
      }
    },
    [dispatch, regionalBrokerId, setErrors, signIn]
  );

  useEffect(() => {
    if (signInData) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const loginResponse = signInData.signIn as any;
      if (Boolean(loginResponse) && !loginResponse.challengeName) {
        const tLoginResponse = signInData.signIn as LoginResponse;
        if (tLoginResponse?.jwt?.token) {
          client.setHeader(
            'Authorization',
            `Bearer ${tLoginResponse?.jwt?.token ?? ''}`
          );

          batch(() => {
            dispatch(setAnonymousUser(false));
            dispatch(login(tLoginResponse));
            dispatch(setUserState(tLoginResponse.user.userState));
            if (onSuccess) {
              onSuccess(
                userInput as ISearchProfileForm,
                tLoginResponse.user._id
              );
            }
          });
        } else if (tLoginResponse.user && !tLoginResponse.jwt) {
          batch(() => {
            const {
              user: {
                userState,
                name,
                surname,
                phone,
                email,
                termsAndConditions,
              },
            } = tLoginResponse;
            dispatch(toggleRegisterForm(true));
            dispatch(toggleLoginForm(false));
            dispatch(toggleIsRegisterModalOpen(true));
            dispatch(setUserState(userState));
            dispatch(
              setUserInput({
                name,
                surname,
                phonePrefix: '',
                phone,
                email,
                password: inputPassword,
                confirmPassword: inputPassword,
                termsAndConditions,
              })
            );
          });
        }
      } else {
        if (!loginData) return;

        const { challengeName, challengeParameters, session } =
          loginResponse as AuthChallengeFieldsFragment;

        switch (challengeName) {
          case 'SOFTWARE_TOKEN_MFA':
            dispatch(
              toggleIsMfaAfterLoginModalOpen({
                isMfaAfterLoginModalOpen: true,
                mfaChallengeMeta: {
                  srpId: challengeParameters.USER_ID_FOR_SRP,
                  session,
                  userSignInInput: loginData,
                },
                mfaChallengeModal: 'app',
              })
            );
            break;
          case 'SMS_MFA':
            dispatch(
              toggleIsMfaAfterLoginModalOpen({
                isMfaAfterLoginModalOpen: true,
                mfaChallengeMeta: {
                  srpId: challengeParameters.USER_ID_FOR_SRP,
                  session,
                  userSignInInput: loginData,
                },
                mfaChallengeModal: 'sms',
              })
            );
            break;
          default:
            break;
        }
      }
      dispatch(resetSPC({ resetTemporarySearchProfile: false }));
    }
  }, [dispatch, inputPassword, onSuccess, signInData, userInput]);

  return { onLoginSubmit };
};

export { useLoginSP };
