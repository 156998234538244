import { Modal } from 'modules/common/components/modal';
import { Container, Header, SkipButton } from './mfa-modal-styles';
import { useTranslation } from 'react-i18next';
import Icon from 'modules/common/components/ui/icon';
import { ThemeContext } from 'styled-components';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ReactComponent as Close } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { batch, useDispatch } from 'react-redux';
import { toggleIsMfaModalOpen } from 'modules/auth/redux/authSlice';
import { useAppSelector } from 'modules/common/hooks';
import { MfaType } from './mfa-interfaces';
import { MfaSelect } from './mfa-select';
import { MfaApp } from './mfa-app';
import MfaContext from './mfa-context';
import { MfaSms } from './mfa-sms';
import {
  useLazyCognitoIsTwoFaActiveQuery,
  useSmsRequestTotpMutation,
} from 'generated';

const MfaModal = () => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const [type, setType] = useState<MfaType>();
  const [code, setCode] = useState<string>();
  const [appTokenCode, setAppTokenCode] = useState<string>();
  const [isSuccess, setIsSuccess] = useState<boolean>();
  const [backupCodes, setBackupCodes] = useState<string[]>();
  const [isFinalStep, setIsFinalStep] = useState<boolean>();
  const [smsExpires, setSmsExpires] = useState<number>();

  const [smsRequestTotp, smsRequestTotpData] = useSmsRequestTotpMutation();

  const resendSmsCode = useCallback(
    (resendTo?: string) => {
      if (!resendTo) return;

      smsRequestTotp({
        phone: resendTo,
      });
      setSmsExpires(60);
    },
    [setSmsExpires]
  );

  const onClose = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      batch(() => {
        dispatch(toggleIsMfaModalOpen(false));
      });
    },
    [dispatch]
  );

  const isOpen = useAppSelector((state) => state.auth.isMfaModalOpen);

  useEffect(() => {
    setCode('');
    setAppTokenCode('');
    setIsSuccess(false);
    setBackupCodes([]);
    setIsFinalStep(false);
  }, [type]);

  const accessToken = useAppSelector((store) => store.auth.accessToken);
  const [triggerIsTwoFaActive, isTwoFaActiveData] =
    useLazyCognitoIsTwoFaActiveQuery();

  useEffect(() => {
    if (
      accessToken &&
      isTwoFaActiveData.isUninitialized &&
      window.sessionStorage.getItem('two-fa-modal-did-open') !== '1'
    ) {
      triggerIsTwoFaActive({
        accessToken,
      });
    }
  }, [accessToken]);

  useEffect(() => {
    if (
      isTwoFaActiveData.isSuccess &&
      isTwoFaActiveData.data.cognitoIsTwoFaActive.shouldShowModal &&
      window.sessionStorage.getItem('two-fa-modal-did-open') !== '1'
    ) {
      dispatch(toggleIsMfaModalOpen(true));
      window.sessionStorage.setItem('two-fa-modal-did-open', '1');
    }
  }, [isTwoFaActiveData]);

  return (
    <Modal
      isOpen={isOpen}
      wrapperStyles={{
        padding: 0,
        width: '90vw',
        maxWidth: '90vw',
        minHeight: '740px',
        maxHeight: '90vh',
        overflowY: 'auto',
        display: 'flex',
        alignItems: 'flex-start',
      }}
      onClose={onClose}
    >
      <MfaContext.Provider
        value={{
          type,
          setType,
          code,
          setCode,
          appTokenCode,
          setAppTokenCode,
          isSuccess,
          setIsSuccess,
          backupCodes,
          setBackupCodes,
          isFinalStep,
          setIsFinalStep,
          smsExpires,
          setSmsExpires,
          resendSmsCode,
        }}
      >
        <Container style={{ ...(isFinalStep && { alignSelf: 'center' }) }}>
          {!isFinalStep && (
            <Header>
              {!type && (
                <SkipButton onClick={onClose}>{t(`auth.mfa.skip`)}</SkipButton>
              )}
              <Icon
                icon={Close}
                width={18}
                height={18}
                color={themeContext.blue}
                style={{ cursor: 'pointer', marginLeft: 'auto' }}
                onClick={onClose}
              />
            </Header>
          )}
          {!type && <MfaSelect />}
          {type === 'app' && <MfaApp />}
          {type === 'sms' && <MfaSms />}
        </Container>
      </MfaContext.Provider>
    </Modal>
  );
};

export { MfaModal };
