import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoginResponse,
  User,
  UserSignInInput,
  UserState,
} from '../../../generated';
import { IIframeType, IRegisterForm, IUserInput } from '../interfaces';

type HeaderAuthOpen = 'user' | 'star' | '';

interface IStateInterface {
  isRegisterModalOpen: boolean;
  headerAuthOpen: HeaderAuthOpen;
  headerMobileAuthOpen: HeaderAuthOpen;
  isLoginModalOpen: boolean;
  isCreatePasswordModalOpen: boolean;
  isForgottenPasswordOpen: boolean;
  isConfirmForgotPasswordModalOpen: boolean;
  isConfirmForgotPasswordSuccesModalOpen: boolean;
  isConfirmEmailSuccessModalOpen: boolean;
  confirmedEmail?: string;
  isConfirmAccountModalOpen: boolean;
  isEditProfileModalOpen: boolean;
  isForgotPasswordSuccessModalOpen: boolean;
  isResendVerificationAccountModalOpen: boolean;
  resendVerificationAccountEmail?: string;
  isLoading: boolean;
  isModalOpacity: boolean;
  iframeType?: IIframeType | null;
  isIframe?: boolean;
  isWidgetOnCloseModalOpen: boolean;
  marketingParameters?: string;
  arrowPosition: {
    top: number;
    left: number;
  };

  isAnonymousUser: boolean;
  userState: UserState;
  changePasswordTimeout?: number;

  errors?: unknown;
  userInput?: IUserInput;
  user?: User;
  accessToken?: string;
  mfaChallengeModal?: 'app' | 'sms';
  mfaChallengeMeta?: {
    srpId: string;
    session: string;
    userSignInInput: UserSignInInput;
  };
  refreshToken?: string;
  exp?: number;
  iat?: number;
  isConfirmedGuest?: string;

  expDevUser?: string;

  isBrokerView: boolean;
  isMfaModalOpen: boolean;
  isMfaAfterLoginModalOpen: boolean;
}

const initialState: IStateInterface = {
  isRegisterModalOpen: false,
  headerAuthOpen: '',
  headerMobileAuthOpen: '',
  isLoginModalOpen: false,
  isCreatePasswordModalOpen: false,
  isForgottenPasswordOpen: false,
  isConfirmForgotPasswordModalOpen: false,
  isConfirmForgotPasswordSuccesModalOpen: false,
  isConfirmEmailSuccessModalOpen: false,
  isEditProfileModalOpen: false,
  isForgotPasswordSuccessModalOpen: false,
  isConfirmAccountModalOpen: false,
  isWidgetOnCloseModalOpen: false,
  isResendVerificationAccountModalOpen: false,
  isLoading: false,
  isModalOpacity: false,
  arrowPosition: { top: 0, left: 0 },

  isAnonymousUser: true,
  userInput: undefined,
  userState: UserState.Unauthenticated,
  isBrokerView: false,
  isMfaModalOpen: false,
  isMfaAfterLoginModalOpen: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    toggleIsRegisterModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isRegisterModalOpen = action.payload;
    },
    toggleIsLoginModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isLoginModalOpen = action.payload;
    },
    toggleHeaderAuthOpen: (state, action: PayloadAction<HeaderAuthOpen>) => {
      state.headerAuthOpen = action.payload;
    },
    toggleHeaderMobileAuthOpen: (
      state,
      action: PayloadAction<HeaderAuthOpen>
    ) => {
      state.headerMobileAuthOpen = action.payload;
    },
    toggleIsCreatePasswordModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCreatePasswordModalOpen = action.payload;
    },
    toggleIsForgottenPasswordOpen: (state, action: PayloadAction<boolean>) => {
      state.isForgottenPasswordOpen = action.payload;
    },
    toggleIsConfirmForgotPasswordModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConfirmForgotPasswordModalOpen = action.payload;
    },
    toggleIsConfirmForgotPassworSuccessdModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConfirmForgotPasswordSuccesModalOpen = action.payload;
    },
    toggleIsForgotPasswordSuccessModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isForgotPasswordSuccessModalOpen = action.payload;
    },
    toggleIsWidgetOnCloseModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isWidgetOnCloseModalOpen = action.payload;
    },
    toggleIsResendVerificationAccountModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isResendVerificationAccountModalOpen = action.payload;
    },
    setResendVerificationAccountEmail: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.resendVerificationAccountEmail = action.payload;
    },
    setUserInput: (state, action: PayloadAction<IRegisterForm>) => {
      state.userInput = action.payload;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.userInput = {
        ...state.userInput,
        username: action.payload,
      };
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userInput = {
        ...state.userInput,
        _id: action.payload,
      };
    },
    removeUserInput: (state) => {
      delete state.userInput;
    },
    setUserState: (state, action: PayloadAction<UserState>) => {
      state.userState = action.payload;
    },
    setAnonymousUser: (state, action: PayloadAction<boolean>) => {
      state.isAnonymousUser = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    login: (state, action: PayloadAction<LoginResponse>) => {
      const { jwt, user } = action.payload;
      state.accessToken = jwt?.token;
      state.refreshToken = jwt?.refreshToken;
      state.exp = jwt?.exp;
      state.iat = jwt?.iat;
      state.user = user;
    },
    logout: ({ isIframe, iframeType, expDevUser }) => {
      return {
        ...initialState,
        expDevUser,
        isIframe,
        iframeType,
      };
    },
    clearTempPassword: (state) => {
      delete state.user?.temporaryPassword;
    },
    setConfirmedGuest: (state, action: PayloadAction<string>) => {
      state.isConfirmedGuest = action.payload;
    },
    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    toggleModalOpacity: (state, action: PayloadAction<boolean>) => {
      state.isModalOpacity = action.payload;
    },
    setErrors: (state, action: PayloadAction<unknown>) => {
      state.errors = action.payload;
    },
    clearErrors: (state) => {
      delete state.errors;
    },
    toggleIsConfirmEmailSuccessdModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConfirmEmailSuccessModalOpen = action.payload;
    },
    setConfirmedEmail: (state, action: PayloadAction<string | undefined>) => {
      state.confirmedEmail = action.payload;
    },
    setChangePasswordTimeout: (state, action: PayloadAction<number>) => {
      state.changePasswordTimeout = action.payload;
    },
    clearChangePasswordTimeout: (state) => {
      delete state.changePasswordTimeout;
    },
    toggleisEditProfileModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditProfileModalOpen = action.payload;
    },
    toggleIsConfirmAccountModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConfirmAccountModalOpen = action.payload;
    },
    toggleIframe(state, action: PayloadAction<IIframeType | null>) {
      state.iframeType = action.payload;
      state.isIframe = !!action.payload;
    },
    setMarketingParameters(state, action: PayloadAction<string>) {
      state.marketingParameters = action.payload;
    },
    setArrowPosition(
      state,
      action: PayloadAction<{ top: number; left: number }>
    ) {
      state.arrowPosition = action.payload;
    },
    setExpDevUser(state, action: PayloadAction<string>) {
      state.expDevUser = action.payload;
    },
    setBrokerView: (state, action: PayloadAction<boolean>) => {
      state.isBrokerView = action.payload;
    },
    toggleIsMfaModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isMfaModalOpen = action.payload;
    },
    toggleIsMfaAfterLoginModalOpen: (
      state,
      action: PayloadAction<{
        isMfaAfterLoginModalOpen: IStateInterface['isMfaAfterLoginModalOpen'];
        mfaChallengeMeta?: IStateInterface['mfaChallengeMeta'];
        mfaChallengeModal?: IStateInterface['mfaChallengeModal'];
      }>
    ) => {
      state.isMfaAfterLoginModalOpen = action.payload.isMfaAfterLoginModalOpen;
      state.mfaChallengeMeta = action.payload.mfaChallengeMeta;
      state.mfaChallengeModal = action.payload.mfaChallengeModal;
    },
    toggleMfaChallengeMeta: (
      state,
      action: PayloadAction<Partial<IStateInterface['mfaChallengeMeta']>>
    ) => {
      if (!(state.mfaChallengeMeta && action.payload)) return;

      state.mfaChallengeMeta = { ...state.mfaChallengeMeta, ...action.payload };
    },
  },
});

export const {
  toggleIsRegisterModalOpen,
  toggleHeaderAuthOpen,
  toggleHeaderMobileAuthOpen,
  toggleIsLoginModalOpen,
  toggleIsCreatePasswordModalOpen,
  toggleIsForgottenPasswordOpen,
  toggleIsConfirmForgotPasswordModalOpen,
  toggleIsConfirmForgotPassworSuccessdModalOpen,
  setUserInput,
  setUsername,
  setUserId,
  removeUserInput,
  setUserState,
  setAnonymousUser,
  setUser,
  login,
  logout,
  clearTempPassword,
  setConfirmedGuest,
  toggleLoading,
  toggleModalOpacity,
  setErrors,
  clearErrors,
  toggleIsConfirmEmailSuccessdModalOpen,
  setConfirmedEmail,
  setChangePasswordTimeout,
  clearChangePasswordTimeout,
  toggleisEditProfileModalOpen,
  toggleIsWidgetOnCloseModalOpen,
  toggleIsForgotPasswordSuccessModalOpen,
  toggleIsResendVerificationAccountModalOpen,
  setResendVerificationAccountEmail,
  toggleIframe,
  setMarketingParameters,
  setArrowPosition,
  setExpDevUser,
  setBrokerView,
  toggleIsConfirmAccountModalOpen,
  toggleIsMfaModalOpen,
  toggleIsMfaAfterLoginModalOpen,
  toggleMfaChallengeMeta,
} = authSlice.actions;

const persistConfig = {
  key: `vonpoll.${authSlice.name}`,
  version: 1,
  storage,
  whitelist: ['accessToken', 'exp', 'iat', 'expDevUser'],
};

const auth = persistReducer(persistConfig, authSlice.reducer);

export default auth;
