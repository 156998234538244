import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Icon from '../../../common/components/ui/icon';
import {
  AddressBar,
  AddressBarText,
  CloseButton,
  MobileInfoBox,
  MobileInfoContentBox,
  MobileInfoOverlay,
  MobileInfoToggler,
  MobileInfoTogglerIcon,
  ShadowmapMobileHeader,
  ShadowmapMobileHeaderButton,
  ShadowmapMobileIframe,
  ShadowmapMobileOverlay,
} from './shadowmap-page-styles';
import ShadowmapInfo from '../expose-details-overlay/components/tabs/shadowmap/shadowmap';
import { ReactComponent as CloseIcon } from 'assets/streamline-light/interface-essential/form-validation/close.svg';
import { ReactComponent as Pin } from 'assets/streamline-light/maps-navigation/pins/pin.svg';

const ShadowmapPage: FC = () => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  const urlParams = new URLSearchParams(window.location.search);

  const [isShowInfo, setIsShowInfo] = useState<boolean>(false);

  const latitude = urlParams.get('lat') || '';
  const longitude = urlParams.get('lon') || '';
  const address = urlParams.get('a') || '';

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const src = `https://vonpoll-exposeoverlay.poweredby.shadowmap.org?zoom=15&azimuth=0&basemap=map&lat=${latitude}&lng=${longitude}`;

  return (
    <ShadowmapMobileOverlay>
      <ShadowmapMobileHeader>
        <ShadowmapMobileHeaderButton onClick={() => setIsShowInfo(true)}>
          {t('shadowmap.how-to.title').replace(':', '?')}
        </ShadowmapMobileHeaderButton>
        <CloseButton
          onClick={() => {
            window.close();
          }}
        >
          <Icon
            icon={CloseIcon}
            width={16}
            height={16}
            color={themeContext.white}
          />
        </CloseButton>
      </ShadowmapMobileHeader>
      <ShadowmapMobileIframe
        src={src}
        title="Shadowmap Von Poll Buyer Seller Expose Overlay Integration"
      />
      <AddressBar>
        <Icon icon={Pin} width={16} height={16} color={themeContext.blue} />
        <AddressBarText>{address}</AddressBarText>
      </AddressBar>

      <MobileInfoOverlay
        $shown={isShowInfo}
        onClick={() => setIsShowInfo(false)}
      >
        <MobileInfoBox $shown={isShowInfo}>
          <MobileInfoToggler onClick={() => setIsShowInfo(false)}>
            <MobileInfoTogglerIcon />
          </MobileInfoToggler>
          <MobileInfoContentBox>
            <ShadowmapInfo isMobileInfo />
          </MobileInfoContentBox>
        </MobileInfoBox>
      </MobileInfoOverlay>
    </ShadowmapMobileOverlay>
  );
};

export { ShadowmapPage };
