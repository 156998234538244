import styled from 'styled-components';
import { MainButton } from '../../../../../../common/components/ui/buttons';

export const Container = styled.div`
  padding: 16px 0 0;
`;

export const Hr = styled.div`
  width: 100%;
  position: relative;
  height: 1px;
  background: #d9e0e7;
  margin: 32px 0;

  &:after {
    content: '';
    width: 20px;
    height: 1px;
    background: #d9e0e7;
    position: absolute;
    top: 0;
    right: -20px;
  }

  &:before {
    content: '';
    width: 20px;
    height: 1px;
    background: #d9e0e7;
    position: absolute;
    top: 0;
    left: -20px;
  }
`;

export const Title = styled.h2`
  font-family: Source Serif Pro, sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #00305e;
  margin: 0 0 24px 0;
`;

export const ListTitle = styled.h3`
  font-family: Source Serif Pro, sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #00305e;
  margin: 0 0 12px 0;
`;

export const Description = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #00305e;
  margin: 0 0 32px 0;
  max-width: 528px;
`;

export const List = styled.ul`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #00305e;
  margin-left: 20px;
  list-style-type: disc;

  li {
    margin-bottom: 12px;
  }
`;

export const NestedListTitle = styled.h5`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #00305e;
  margin-bottom: 12px;
`;

export const ImagesBox = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 48px;
`;

export const ImgBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImgTitle = styled.h5`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #00305e;
  margin-top: 8px;
`;

export const GuideImage = styled.img`
  width: 100%;
  margin-top: 48px;
`;

export const IconImages = styled.div`
  height: 96px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 48px;

  & > div:nth-child(3) {
    margin-top: 4px;
  }
`;

export const IconImageBox = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const IconImageTitle = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #00305e;
`;

export const BlueIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background: #4e73f5;
`;

export const GrayIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #e5e9ec;
  //box-shadow: 0px 8px 30px 0px #00000017;
`;

export const ShowShadowmapButton = styled(MainButton)`
  max-height: 40px;
  padding: 12px;
  text-transform: uppercase;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
`;
